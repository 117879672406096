/* eslint-disable react-hooks/exhaustive-deps */
import { Theme } from "../utils/theme";
import { memo, useEffect } from "preact/compat";
import VerticalCenter from "../components/common/verticalCenter";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { STB } from "../hooks/apis/stb";
import focus from "../utils/focus";

const TIEM_TO_RELOAD = 15000;

const CheckoutSuccess = () => {
    const texts = useSelector((state) => state.ui.texts);
    const styleSuccessText = {
        bold: Theme ? Theme.heading.bold : true,
        italic: Theme ? Theme.heading.italic : false,
        underline: Theme ? Theme.heading.underline : false,
        fontFamily: Theme ? Theme.heading.name : "Lato",
        fontSize: Theme ? `${Theme.heading.size}vw` : "50px",
        color: Theme ? Theme.colors.defaultForeground : "black",
    };

    useEffect(() => {
        focus.value.replace("checkoutsuccess");
        setTimeout(() => {
            STB.reload();
        }, TIEM_TO_RELOAD);
    }, []);

    const onkeydown = (e) => {
        // block all keys wait to reload
        e.stopPropagation();
        return;
    };

    return (
        <div
            className="w-full h-full"
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "2vw",
                margin: "auto",
            }}
        >
            <div
                id="checkoutsuccess"
                onKeyDown={(e) => {
                    onkeydown(e);
                }}
                style={{
                    margin: "auto",
                    padding: "3vw",
                    backgroundColor: Theme.focusIsLight(Theme.colors.defaultForeground)
                        ? "rgba(87,101,117,0.5)"
                        : "rgba(255,255,255,0.5)",
                    borderRadius: "0.5vw",
                }}
            >
                <VerticalCenter text={ReactHtmlParser(texts["checkout-success-text"])} cStyle={styleSuccessText} />
            </div>
        </div>
    );
};

export default memo(CheckoutSuccess);
