import React from "react";
import { eventHandler } from "../../utils/eventHandler";
import { KEYS } from "../../utils/keys";
import { useSelector } from "react-redux";

const ARROWS = [KEYS.left, KEYS.right, KEYS.up, KEYS.down];

const Limbo = ({ id, global, noBlocker = false, fullBlock = false }) => {
    const menu = useSelector((state) => state.ui.menu);

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        if (!keyData) {
            return;
        }
        let preventDefault = true;
        if ([KEYS.back, KEYS.home, KEYS.enter].indexOf(keyData.value) > -1 || noBlocker) {
            preventDefault = false;
        }
        if (menu?.menuKeys && ARROWS.indexOf(keyData.value) > -1 && menu.menuKeys.indexOf(keyData.value) > -1) {
            preventDefault = false;
        }
        // whith fullBlock only propagates HOME key
        if (fullBlock && [KEYS.home].indexOf(keyData.value) === -1) {
            preventDefault = true;
        }
        if (preventDefault) {
            e.stopPropagation();
        }
    };
    return (
        <div
            onKeyDown={(e) => {
                onkeydown(e);
            }}
            id={id ? id : global ? "noFocus" : "limbo"}
            className="navigable  "
            tabIndex={1}
        ></div>
    );
};

export default Limbo;
