import memize from "memize";
import { vendureQuery } from "../../../hooks/query";

type QueryResult = {
    data: {
        pickupLocations: {            
                id: string;
                translations: {
                    name: string;
                    languageCode: string;
                }[];            
        }[];
    };
};

export default memize(
    (channel: string): Promise<QueryResult> =>
        new Promise((resolve, reject) =>
            vendureQuery({
                apiType: "shop",
                headers: { Authorization: `Bearer ${sessionStorage.getItem("stayToken")}`, "vendure-token": channel  },
                cache: true,
                body: `
                {
                    pickupLocations{
                      id
                      translations{
                        name
                        languageCode
                      }
                    }
                  }`,
                onResponse: (data: QueryResult) => {resolve(data)},
                onError: (e) => reject(e),
            }),
        ),
);
