// import { writeLog } from "../../../utils/utils";

export const Media = {
    init() {
        this.VIDEOPANE = document.createElement("div");
        this.VIDEOPANE.style.position = "fixed";
        this.VIDEOPANE.style.top = "0";
        this.VIDEOPANE.style.left = "0";
        this.VIDEOPANE.style.backgroundColor = "rgb(0, 0, 0)";
        this.VIDEOPANE.style.zIndex = "51";
        this.VIDEOPANE.style.visibility = "hidden";
        this.VIDEOPANE.id = "regularVideoPane";
        document.body.appendChild(this.VIDEOPANE);
    },

    setVideoSize(x, y, width, height) {
        this.VIDEOPANE.style.visibility = "";
        this.VIDEOPANE.style.marginTop = `${y}vh`;
        this.VIDEOPANE.style.marginLeft = `${x}vw`;
        this.VIDEOPANE.style.width = `${width}vw`;
        this.VIDEOPANE.style.height = `${height}vh`;
        let rect = this.VIDEOPANE.getBoundingClientRect();
        let showPIG = !(x == 0 && y == 0 && width == 100 && height == 100);
        // eslint-disable-next-line no-undef
        Android.setVideoSize(showPIG, rect.width, rect.height, rect.x, rect.y);
    },

    getAudioList(onSuccess) {
        try {
            // eslint-disable-next-line no-undef
            let trackInfo = JSON.parse(Android.getAudioTracks());
            if (trackInfo && trackInfo.audio) {
                onSuccess(trackInfo.audio.tracks);
            }
        } catch (error) {
            onSuccess();
        }
    },

    getSubtitleList(onSuccess) {
        try {
            // eslint-disable-next-line no-undef
            let trackInfo = JSON.parse(Android.getSubtitleTracks());
            if (trackInfo && trackInfo.subtitle) {
                onSuccess(trackInfo.subtitle.tracks);
            }
        } catch (error) {
            onSuccess();
        }
    },

    changeAudio(ind) {
        // eslint-disable-next-line no-undef
        Android.setAudioPID(ind);
    },

    changeSubtitle(ind) {
        // eslint-disable-next-line no-undef
        Android.setSubtitlesPID(ind);
    },

    disableSubtitles() {
        // eslint-disable-next-line no-undef
        Android.disableSubtitles();
    },

    _playChannel(ip, port) {
        // eslint-disable-next-line no-undef
        Android.playChannel(ip, parseInt(port));
    },

    _playHLSVideo(url, loop) {
        // eslint-disable-next-line no-undef
        Android.playVideo(url, loop);
    },
    _replayHLSVideo(url, loop) {
        // eslint-disable-next-line no-undef
        Android.playVideo(url, loop);
    },

    _stop() {
        this.playingMedia = false;
        this.VIDEOPANE.style.visibility = "hidden";
        // eslint-disable-next-line no-undef
        Android.stop();
    },
};
