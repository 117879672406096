/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import Query from "../../hooks/query";
import { Texts } from "../../utils/texts";
import focus from "../../utils/focus";
import { memo } from "preact/compat";
import Button from "../common/button";
import { setPopUp, navigateTo, displayErrorPopUp } from "../../actions/uiActions";
import VerticalList from "../common/verticalList";
import { parseBillData, addCurrencySimbol } from "../../utils/invocie";
import ReactHtmlParser from "react-html-parser";
import Loading from "../loading";
import { inPreview } from "../../utils/utils";

const Checkout = () => {
    const dispatch = useDispatch();

    const ITEM_TYPE = "checkout";

    //Store data
    const texts = useSelector((state) => state.ui.texts);
    const { room } = useSelector((state) => state.status.sessionData);
    const [guestBill, setGuestBill] = useState(false);
    const [guestBillItems, setGuestBillItems] = useState(false);
    const [error, setError] = useState(false);

    const getBill = () => {
        Query({
            query: `{
				bill(roomId: ${room.id}){
					currencySymbol
					currencyPosition
					guestPmsId
					roomPmsNumber
					totalAmount
					items{
						date
						description
						price
						quantity
					}
				}
			}`,
            onResponse(res) {
                if (res?.data?.bill) {
                    setGuestBill(res.data.bill);
                } else if (res?.errors.length > 0) {
                    setError(true);
                    focus.value.replace("btn-exit");
                } else {
                    focus.value.replace("btn-exit");
                }
            },
        });
    };
    const callCheckout = () => {
        if (inPreview()) {
            dispatch(displayErrorPopUp({ text: texts["preview-msg"], timeout: 3000 }));
            return;
        }
        Query({
            query: `mutation{
				expressCheckout( 
					totalbalance: ${parseFloat(guestBill.totalAmount)} 
					guestPmsID: "${guestBill.pmsId}" 
					roomPmsNumber: "${guestBill.pmsRoomNumber}")
				{
					msg
					code
				}}`,
            onResponse(res) {
                dispatch(setPopUp(null));
                dispatch(
                    navigateTo({
                        layout: "/checkoutsuccess",
                    }),
                );
            },
        });
    };

    useEffect(() => {
        getBill();
    }, []);

    //listeners
    useEffect(() => {
        // set focus on button if there is no gestBillItems
        if (guestBill) {
            setGuestBillItems(parseBillData(guestBill));
            if (guestBill.items.lenght === 0) {
                focus.value.replace("btn-checkout");
            }
        }
    }, [guestBill]);

    return (
        <>
            <div className="text-left min-h-1/4">
                {guestBill ? (
                    <>
                        {guestBillItems.length > 0 ? (
                            <div className="text-1xl mb-4">{texts["checkout-continue-text"]}</div>
                        ) : null}
                        <div
                            className="text-1xl"
                            style={{ width: "50vw", wordBreak: "break-word", whiteSpace: "pre-line" }}
                        >
                            {ReactHtmlParser(texts["express-checkout-text"])}
                        </div>
                        {guestBillItems.length > 0 ? (
                            <>
                                <div className="text-right text-xl font-500 mb-4 mt-8">
                                    {Texts.capitalize(texts["total"])}:{" "}
                                    {addCurrencySimbol(
                                        guestBill ? guestBill.totalAmount : 0,
                                        guestBill.currencySymbol,
                                        guestBill.currencyPosition,
                                    )}
                                </div>
                                <VerticalList
                                    insideModal={true}
                                    data={{
                                        list: guestBillItems,

                                        itemType: ITEM_TYPE,
                                        itemCustomClass: "w-full",
                                        itemCustomStyle: {
                                            marginBottom: "0.7vh",
                                            backgroundColor: "transparent",
                                        },
                                        customClass: "pr-1",
                                        itemRounded: true,
                                        maxHeight: "45vh",
                                    }}
                                />
                            </>
                        ) : null}
                        <div className="table mx-auto items-center pt-7 relative w-full">
                            <Button
                                insideModal={true}
                                data={{
                                    id: "checkout",
                                    name: Texts.capitalize(texts["check-out"]),
                                    border: true,
                                    customClass: " py-2 px-4 text-lg text-center rounded float-left",
                                    customStyle: {
                                        width: "100%",
                                    },
                                    onClick() {
                                        // CHECKOUT ACTION
                                        callCheckout();
                                    },
                                }}
                            />
                        </div>
                    </>
                ) : error ? (
                    <div className={"text-center"} style={{ paddingTop: "8rem" }}>
                        {texts["vendure-error-connection"]}
                    </div>
                ) : (
                    <div style={{ width: "50vw", paddingTop: "8rem" }}>
                        <Loading />
                    </div>
                )}
            </div>
        </>
    );
};

export default memo(Checkout);
