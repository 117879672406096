import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import Button from "../common/button";
import Calculator from "../common/calculator";
import Modal from "../common/modal";
import focus from "../../utils/focus";
import { Texts } from "../../utils/texts";
import { setNumpadCode } from "../../actions/eventsActions";
import order from "../sales/api/order";
import { clearCart } from "../sales/signals/carrito";
import storage from "../../utils/storage";
import { displayErrorPopUp, setPopUp } from "../../actions/uiActions";
import Loading from "../loading";
import { getVendureTranslation, inPreview, limitDecimals, printPrice } from "../../utils/utils";
import HorizontalList from "../common/horizontalList";
import { TIME } from "../../utils/constants";
import { useLocation } from "wouter";
import api from "../sales/api";

/**
 * @param a {Date}
 * @param b {Date}
 * @return {1 | -1}
 */
const absoluteDateComparator = (a, b) => {
    /**
     * @param a {[Date, Date]}
     * @param b {[Date, Date]}
     * @return {1 | -1}
     */
    if (a[0] < b[0]) {
        return -1;
    } else if (a[0] === b[0]) {
        return a[1] < b[1] ? -1 : 1;
    } else {
        return a[1] < b[1] ? -1 : 1;
    }
};
/**
 * @param currentTime {Date}
 * @return {function(): (1 | -1)}
 */
const relativeDateComparator = (currentTime) => {
    /**
     * @param a {[Date, Date]}
     * @param b {[Date, Date]}
     * @return {1 | -1}
     */
    return (a, b) => {
        if (a[0] < currentTime && b[0] < currentTime) {
            return -1;
        }
        return 1;
    };
};
const OrderConfirm = () => {
    const params = useLocation();
    const dispatch = useDispatch();
    const CURRENCY_CODE = sessionStorage.getItem("currencyCode");

    const DELIVERY_TIME = { asap: "asap", schedule: "schedule" };
    const [error, setError] = useState("");
    const [orderSuccess, setSuccess] = useState(false);
    const [step, setStep] = useState(1);
    const [deliveryMethod, setDeliveryMethod] = useState(null);
    const [deliveryTime, setDeliveryTime] = useState(DELIVERY_TIME.asap);
    const [deliveryTimeOptions, setDeliveryTimeOptions] = useState(DELIVERY_TIME.asap);
    const [deliveryResponse, setDeliveryResponse] = useState(null);
    const [shopData, setShopData] = useState(null);

    const hasRoomserviceOption = shopData?.shipping_config?.delivery.enabled ?? false;
    const hasPickupOption = (shopData?.shipping_config?.pickup?.enabled && shopData?.pickup_location ) || false;

    const numpadCode = useSelector((state) => state.events.numpadCode);
    const texts = useSelector((state) => state.ui.texts);
    const locationData = useSelector((state) => state.status.locationData);
    const channelToken = storage.get("orderToken");

    const parentalCode = sessionStorage.getItem("parentalCode");

    const deliveryMethodData = deliveryResponse?.find((method) => method.code === deliveryMethod);

    useEffect(() => {
        getDeliveryMethods();
    }, []);

    useEffect(() => {
        if (numpadCode === (parentalCode || locationData.roomInfo.number)) {
            if (inPreview()) {
                dispatch(displayErrorPopUp({ text: texts["preview-msg"], timeout: 3000 }));
                return;
            }
            const shippingMethodCode = deliveryMethod?.startsWith("delivery") ? "delivery" : "pickup";
            const deliveryLocation =
                shippingMethodCode === "pickup"
                    ? shopData.pickup_location?.id
                    : locationData.roomInfo.name;

            order({
                channelToken: channelToken,
                shippingMethod: shippingMethodCode,
                schedule: deliveryTime != DELIVERY_TIME.asap ? deliveryTime : null,
                deliveryLocation,
            }).then((data) => {
                if (!data || data.errors) {
                    setError(Texts.translate(texts, "order-error"));
                } else {
                    clearCart({ token: channelToken });
                    setSuccess(true);
                    setError("");
                    dispatch(setNumpadCode(null));
                }
            });
        } else if (numpadCode) {
            setError(Texts.translate(texts, parentalCode ? "wrong parental code" : "Wrong room number") + "!");
        }
    }, [numpadCode]);

    useEffect(() => {
        focus.value.current === `btn-${DELIVERY_TIME.schedule}` && setDeliveryTimeOptions(DELIVERY_TIME.schedule);
        focus.value.current === `btn-${DELIVERY_TIME.asap}` && setDeliveryTimeOptions(DELIVERY_TIME.asap);
    }, [focus.value.current]);

    const getDeliveryMethods = () =>
        Promise.all([api.shippingMethods(), api.pickupLocations(channelToken), api.shopByToken(params[0].split("/")[2])]).then(
            ([shippingMethods, pickupLocations, shop]) => {          
                console.log(pickupLocations)      
                console.log(pickupLocations?.data?.pickupLocations?.[0])      
                setDeliveryResponse(shippingMethods?.data?.shippingMethods?.items);
                setShopData({
                    ...shop?.customFields,
                    pricesIncludeTax: shop?.pricesIncludeTax,
                    shipping_config: shop?.customFields?.shipping_config
                        ? shop?.customFields?.shipping_config
                        : undefined,
                    pickup_location: pickupLocations?.data?.pickupLocations?.[0]
                });
            },
        );
       
    /**
     *
     * @return {[number, number][]}
     */
    const getSortedAvailableTimeSlots = () => {
        /**
         * @type {{startTime: string, endTime: string}[]|null}
         */
        const times = shopData?.delivery_schedule ? JSON.parse(shopData.delivery_schedule) : null;
        let currentTime = new Date();
        shopData?.delayed_delivery_threshold &&
            currentTime.setMinutes(currentTime.getMinutes() + shopData?.delayed_delivery_threshold);
        //Prepare slots each 30 minutes
        if (!times) {
            const currentMinute = currentTime.getMinutes();
            if (currentMinute < 30) {
                // We can order on the second half of the current hour
                currentTime.setMinutes(0);
            } else {
                // We can order at next hour o'clock
                // currentTime = new Date(currentTime.getTime() + TIME.ONE_HOUR_IN_MS); // Uncomment this line to exclude the ongoing delivery slot
                currentTime.setMinutes(30); //Subtract whatever minutes currentTime has
            }
            return Array.from({ length: 48 }).map((_, i) => [
                currentTime.getTime() + (i * TIME.ONE_HOUR_IN_MS) / 2,
                currentTime.getTime() + ((i + 1) * TIME.ONE_HOUR_IN_MS) / 2,
            ]);
        } else {
            let timeSlots = [];
            times.forEach((time) => {
                const startTime = time.startTime.split(":");
                const endTime = time.endTime.split(":");
                const startDate = new Date();
                const endDate = new Date();
                startDate.setHours(startTime[0]);
                startDate.setMinutes(startTime[1]);
                endDate.setHours(endTime[0]);
                endDate.setMinutes(endTime[1]);
                while (startDate.getTime() < endDate.getTime()) {
                    let slotEnd = startDate.getTime() + TIME.ONE_HOUR_IN_MS / 2;
                    if (slotEnd > endDate.getTime() || endDate.getTime() - slotEnd < TIME.ONE_HOUR_IN_MS / 4) {
                        slotEnd = endDate.getTime();
                    }
                    timeSlots.push([
                        startDate.getTime() + (startDate < currentTime ? 86400000 : 0),
                        slotEnd + (startDate < currentTime ? 86400000 : 0),
                    ]);
                    startDate.setTime(slotEnd);
                }
            });

            const orderedSlots = timeSlots.sort(absoluteDateComparator);
            return orderedSlots;
        }
    };

    const printDeliveryTimes = () => {
        const times = getSortedAvailableTimeSlots();
        return (
            <div className={"relative"}>
                <HorizontalList
                    insideModal={true}
                    data={{
                        id: "channel_HL",
                        noFocusOnLoad: true,
                        timeSlots: true,
                        list: times
                            .map(
                                ([start, end]) =>
                                    `${new Date(start).getHours().toString(10).padStart(2, "0")}:${new Date(start)
                                        .getMinutes()
                                        .toString(10)
                                        .padStart(2, "0")} - ${new Date(end)
                                        .getHours()
                                        .toString(10)
                                        .padStart(2, "0")}:${new Date(end).getMinutes().toString(10).padStart(2, "0")}`,
                            )
                            .map((x, i) => {
                                return { id: JSON.stringify(times[i]), name: x };
                            }),
                        paginated: true,
                        itemsPerPage: 5,
                        customClass: "p-4",
                        itemType: "deliveryTime",
                        itemCustomStyle: {
                            marginBottom: "0.5vh",
                            padding: "o.5vw",
                            backgroundColor: "transparent",
                        },
                        itemRounded: true,
                        onClick: (value) => {
                            setDeliveryTime(JSON.parse(value));
                            setStep(4);
                        },
                    }}
                />
            </div>
        );
    };

    const printNextDeliveryTime = (showASAP) => {
        const availableDeliverySlots = getSortedAvailableTimeSlots();
        const nextDeliverySlot = availableDeliverySlots[0];
        const currentTime = new Date().getTime();
        const isIncludedInCurrentSlot =
            currentTime > availableDeliverySlots?.[0]?.[0] && currentTime < availableDeliverySlots?.[0]?.[1];
        return (
            <>
                <div>
                    {!isIncludedInCurrentSlot && nextDeliverySlot
                        ? texts["next-delivery-time"].replace(
                              "{{time}}",
                              `${new Date(nextDeliverySlot[0]).getHours().toString(10).padStart(2, "0")}:${new Date(
                                  nextDeliverySlot[0],
                              )
                                  .getMinutes()
                                  .toString(10)
                                  .padStart(2, "0")}`,
                          )
                        : showASAP
                        ? texts["asap-desc"]
                        : null}
                </div>
            </>
        );
    };

    const getDeliveryPrice = (method) => {
        return (
            (shopData.shipping_config[method || deliveryMethod]?.price.base *
                (!shopData.pricesIncludeTax
                    ? 1
                    : 1 + shopData.shipping_config[method || deliveryMethod].price.taxRate / 100)) /
            100
        );
    };

    const composeOrderTotalSubtitle = () => {
        let deliveryCost,
            totalCost = 0;
        let itemsTotal = parseFloat(sessionStorage.getItem("orderTotal"));

        totalCost = limitDecimals(itemsTotal + parseFloat(getDeliveryPrice()));

        return (
            <>
                {Texts.translate(texts, "order-total-sum").replace("{{total}}", printPrice(totalCost, CURRENCY_CODE))}{" "}
                <span style={{ textTransform: "lowercase" }}>
                    ({texts[`Tax ${shopData?.pricesIncludeTax ? "included" : "excluded"}`]})
                </span>
                <div className={"bold pt-4"}>
                    {Texts.translate(texts, parentalCode ? "add-parental-code" : "Add your room number")}
                </div>
            </>
        );
    };

    return step === 1 ? (
        <Modal
            title={Texts.translate(texts, "Choose delivery method")}
            firstFocus={hasRoomserviceOption ? "btn-room-service" : "btn-pickup"}
            backBtn={true}
            height={"54vh"}
            width={"37vw"}
            body={
                !deliveryResponse ? (
                    <Loading inModal={true} />
                ) : (
                    <>
                        {hasRoomserviceOption ? (
                            <div className="pt-8">
                                <Button
                                    insideModal={true}
                                    data={{
                                        id: "room-service",
                                        name: texts["Room service"],
                                        customClass: "text-center py-2 w-full text-2xl",
                                        border: true,
                                        onClick: () => {
                                            setDeliveryMethod("delivery");
                                            setStep(2);
                                        },
                                    }}
                                />
                                {focus.value.current === "btn-room-service" &&
                                shopData.shipping_config.delivery.price.base !== 0 ? (
                                    <div className="py-2 mt-4 mb-2 text-center text-xl ">
                                        {texts["delivery-cost"].replace(
                                            "{{price}}",
                                            printPrice(getDeliveryPrice("delivery"), CURRENCY_CODE),
                                        )}
                                        <div>
                                            ({texts[`Tax ${shopData?.pricesIncludeTax ? "included" : "excluded"}`]})
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        {hasPickupOption ? (
                            <div className="pt-4">
                                <Button
                                    insideModal={true}
                                    data={{
                                        id: "pickup",
                                        name: texts["Pick up at Reception"].replace(
                                            "{{place}}",
                                            getVendureTranslation(shopData.pickup_location.translations),
                                        ),
                                        customClass: "text-center py-2 w-full text-2xl",
                                        border: true,
                                        onClick: () => {
                                            setDeliveryMethod("pickup");
                                            setStep(2);
                                        },
                                    }}
                                />
                                {focus.value.current === "btn-pickup" && shopData.shipping_config.pickup.price.base ? (
                                    <div className="py-2 mt-4 mb-2 text-center text-xl ">
                                        {texts["delivery-cost"].replace(
                                            "{{price}}",
                                            printPrice(getDeliveryPrice("pickup"), CURRENCY_CODE),
                                        )}

                                        <div>
                                            ({texts[`Tax ${shopData?.pricesIncludeTax ? "included" : "excluded"}`]})
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        {!hasPickupOption && !hasRoomserviceOption ? (
                            <div>
                                <Loading text={"Error getting delivery options"} inModal={true} />
                            </div>
                        ) : null}
                    </>
                )
            }
        />
    ) : step === 2 ? (
        <Modal
            title={Texts.translate(texts, "Delivery method")}
            height={"54vh"}
            width={"37vw"}
            firstFocus={"btn-continue"}
            backBtn={true}
            preventCloseOnBack={true}
            backAction={() => setStep(1)}
            body={
                <>
                    <div style={{ height: "25vh" }}>
                        <div className="pt-4">
                            {ReactHtmlParser(
                                Texts.translate(
                                    texts,
                                    deliveryMethod == "delivery" ? "room-service-desc" : "pick-up-reception-dec",
                                ).replace("{{place}}", getVendureTranslation(shopData.pickup_location?.translations)),
                            )}
                        </div>

                        {shopData.shipping_config[deliveryMethod]?.price?.base ? (
                            <div className="py-2 mt-8">
                                {texts["attend-service-cost"].replace(
                                    "{{price}}",
                                    printPrice(getDeliveryPrice(), CURRENCY_CODE),
                                )}

                                <span style={{ textTransform: "lowercase" }}>
                                    {" "}
                                    ({texts[`Tax ${shopData?.pricesIncludeTax ? "included" : "excluded"}`]}).
                                </span>
                            </div>
                        ) : null}
                    </div>
                    <div className="">
                        <Button
                            insideModal={true}
                            data={{
                                id: "continue",
                                name: Texts.capitalize(texts["continue"]),
                                customClass: "text-center py-2 w-full text-2xl",
                                border: true,
                                onClick: () => {
                                    setStep(3);
                                },
                            }}
                        />
                    </div>
                </>
            }
        />
    ) : step === 3 && shopData ? (
        shopData?.allow_scheduled_delivery ? (
            <Modal
                title={Texts.translate(texts, "Choose delivery time")}
                width={"70vw"}
                backBtn={true}
                cancelBtn={true}
                preventCloseOnBack={true}
                backAction={() => setStep(2)}
                firstFocus={"btn-asap"}
                body={
                    <>
                        <div className="py-8 mx-auto table " style={{ width: "80%" }}>
                            <Button
                                insideModal={true}
                                data={{
                                    id: "asap",
                                    active: deliveryTimeOptions === DELIVERY_TIME.asap,
                                    name: texts["As soon as possible"],
                                    customClass: "text-center py-2 float-left text-2xl",
                                    customStyle: { width: "48%" },
                                    border: true,
                                    onClick: () => {
                                        setStep(4);
                                    },
                                }}
                            />
                            <Button
                                insideModal={true}
                                data={{
                                    id: "schedule",
                                    active: deliveryTimeOptions === DELIVERY_TIME.schedule,
                                    name: texts["Schedule your delivery time"],
                                    customClass: "text-center py-2  float-left text-2xl",
                                    customStyle: { width: "48%", marginLeft: "4%" },
                                    border: true,
                                }}
                            />
                        </div>
                        <div className={"table w-full my-8 text-center text-2xl"} style={{ height: "5vh" }}>
                            {deliveryTimeOptions === DELIVERY_TIME.asap
                                ? printNextDeliveryTime(true)
                                : deliveryTimeOptions === DELIVERY_TIME.schedule
                                ? texts["Select a delivery hour"]
                                : ""}
                        </div>
                        <div style={{ height: "20vh" }}>
                            {deliveryTimeOptions === DELIVERY_TIME.schedule ? printDeliveryTimes() : null}
                        </div>
                    </>
                }
            />
        ) : (
            <Modal
                title={Texts.translate(texts, "Delivery time")}
                height={"54vh"}
                width={"37vw"}
                backBtn={true}
                cancelBtn={true}
                preventCloseOnBack={true}
                backAction={() => setStep(2)}
                firstFocus={"btn-continue"}
                body={
                    <>
                        <div style={{ height: "25vh" }}>
                            <div className={"pt-4"}>{texts["asap-desc"]}</div>
                            <div className={"mt-8"}>{printNextDeliveryTime()}</div>
                        </div>
                        <div className="">
                            <Button
                                insideModal={true}
                                data={{
                                    id: "continue",
                                    name: Texts.capitalize(texts["continue"]),
                                    customClass: "text-center py-2  w-full text-2xl",
                                    border: true,
                                    onClick: () => {
                                        setStep(4);
                                    },
                                }}
                            />
                        </div>
                    </>
                }
            />
        )
    ) : step === 4 && orderSuccess ? (
        <Modal
            title={Texts.translate(texts, "Order confirmation")}
            firstFocus={"btn-close"}
            body={
                <>
                    <div className="p-4 text-center">{ReactHtmlParser(Texts.translate(texts, "order-success"))}</div>
                    <div className="pt-8">
                        <Button
                            insideModal={true}
                            data={{
                                id: "close",
                                name: Texts.capitalize(texts["close"]),
                                customClass: "text-center py-2 w-full text-2xl",
                                border: true,
                                onClick: () => {
                                    dispatch(setPopUp(null));
                                    history.go(-parseInt(sessionStorage.getItem("stepsBack") || 4));
                                },
                            }}
                        />
                    </div>
                </>
            }
        />
    ) : step === 4 ? (
        <Modal
            title={Texts.translate(texts, "Finalise order")}
            subtitle={composeOrderTotalSubtitle()}
            titleClass={"pt-6"}
            backBtn={true}
            cancelBtn={true}
            preventCloseOnBack={true}
            backAction={() => setStep(3)}
            body={
                <Calculator
                    code={parentalCode || locationData.roomInfo.number}
                    encrypt={parentalCode}
                    confirmText={texts["Finalize"]}
                    confirmIcon="room"
                    error={error}
                    setError={setError}
                />
            }
        />
    ) : null;
};

export default OrderConfirm;
