import { STB } from "../hooks/apis/stb";
import { PERMISSION, STATE, TV_MODELS, ZAFIRO_MODES } from "./constants";
import { getChannelData } from "./utils";

export const SESSION = {
    networkStatus: STATE.ONLINE,
    permissions: {},
    tvMode: ZAFIRO_MODES.FULL_INTERFACE,
    isTVOnCheckout: function () {
        return this.tvMode === ZAFIRO_MODES.TV;
    },
    composePermissions: function (permissions) {
        permissions.forEach((permissionsKey) => {
            this.permissions[permissionsKey] = true;
        });
    },
    hasPermission: function (permissionSeek) {
        return this?.permissions?.[permissionSeek] ?? false;
    },
    checkConnection: function () {
        if (!this.hasPermission(PERMISSION.HIGH_AVAILABILITY) && this.networkStatus === STATE.OFFLINE) {
            STB.gotoOfflineMode();
        }
    },
    ensureHAGrid: function (texts) {
        if (localStorage.getItem("haGridEnsuredDate")) {
            // remove localstorage flags when time HA grid ensured is more than
            const dateHaGridEnsured = new Date(localStorage.getItem("haGridEnsuredDate"));
            const twoWeeks = 1000 * 60 * 60 * 24 * 7;
            if (new Date().getTime() - dateHaGridEnsured.getTime() > twoWeeks) {
                localStorage.removeItem("haGridEnsured");
                localStorage.removeItem("haGridEnsuredDate");
            }
        }
        if (this.hasPermission(PERMISSION.HIGH_AVAILABILITY) && !localStorage.getItem("haGridEnsured")) {
            getChannelData(
                function () {
                    localStorage.setItem("haGridEnsured", true);
                    localStorage.setItem("haGridEnsuredDate", new Date());
                    if ([TV_MODELS.PHILIPS, TV_MODELS.PHILIPS_NO_CC].includes(STB.model)) {
                        STB.gotoOfflineMode();
                    } else if (STB.model === TV_MODELS.LG) {
                        STB.reload();
                    }
                },
                { texts: texts },
            );
        }
    },
    saveDataForHA: function (params, texts) {
        Object.keys(params).forEach((key) => {
            STB.saveDataForHA(key, params[key]);
        });
        this.ensureHAGrid(texts);
    },
};
