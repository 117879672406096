export const EVENTS = {
    type: {
        MEDIA: "media_event",
        CONNECTION: "connection_event",
        NAVIGATION: "navigation_event",
        GLOBAL: "global_event",
        POPUP: "popup_event",
    },
    MEDIA: {
        TYPE: "media_event",
        STATUS_END_OF_STREAM: 2,
        STOPPED: 3,
        REPLAY: 4,
    },
    GLOBAL: {
        CHECK_SERVER_INFO: 1,
        CHECK_STATUS_VIA_MQTT: 2,
    },
    CONNECTION: {
        TYPE: "connection_event",
        ERROR: "connectionError",
        TIMEOUT: "connectionTimeout",
    },
    POPUP: {
        TYPE: "popup_event",
        ZAFIRO: "zafiroMenu",
        NOT_AVAILABLE: "notAvailable"
    },
} as const;
