import { useEffect } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { setPopUp } from "../../actions/uiActions";
import { Texts } from "../../utils/texts";
import { Theme } from "../../utils/theme";
import focus from "../../utils/focus";
import { cleanWhiteChars } from "../../utils/utils";
import Button from "./button";

const Modal = ({
    title,
    subtitle,
    body,
    style,
    backBtn,
    cancelBtn,
    backBtnClass,
    backAction,
    backText,
    firstFocus,
    focusOnBack,
    customClass,
    titleClass,
    preventCloseOnBack,
    width,
    height,
}) => {
    const dispatch = useDispatch();

    const texts = useSelector((state) => state.ui.texts);

    const noPadding = style && style.noPadding;

    ////////////////////////
    //Listeners
    ///////////////////////
    useEffect(() => {
        if (firstFocus) {
            focus.value.replace(cleanWhiteChars(firstFocus));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, firstFocus]);

    return (
        <div
            className={`fixed overlay z-900 top-0 left-0 ${customClass}`}
            //Fixed style doesn't work in Philips 4014 if you don't set the width/height in pixels, 100% doesn't work
            style={{
                width: `${document.body.offsetWidth || window.innerWidth}px`,
                height: `${document.body.offsetHeight || window.innerHeight}px`,
                visibility: "visible",
            }}
        >
            <div id="pop-up-zone" className="table h-full mx-auto" style={{ width: width || "32rem" }}>
                <div className="table-cell vertical-middle">
                    <div
                        className={`${
                            Theme.light ? "bg-white text-gray-800" : "bg-gray-800 text-gray-100"
                        }   inline-block rounded h-auto ${noPadding ? "" : "p-8"}`}
                        style={{ width: width || "32rem", height: height || "auto" }}
                    >
                        <div className={"relative"}>
                            {backBtn ? (
                                <Button
                                    insideModal={true}
                                    data={{
                                        id: "exit",
                                        icon: "tv-back-remote",
                                        iconSize: "text-2xl",
                                        name: Texts.capitalize(texts[backText || "back"]),
                                        customClass: `py-2 px-2 w-auto text-2xl mb-4 ${noPadding ? "m-4" : ""} ${
                                            backBtnClass ? backBtnClass : ""
                                        }`,
                                        onClick() {
                                            if (!preventCloseOnBack) {
                                                dispatch(setPopUp(null));
                                            }
                                            if (typeof backAction !== "undefined") {
                                                backAction();
                                            }
                                        },
                                    }}
                                />
                            ) : null}
                            {cancelBtn ? (
                                <Button
                                    insideModal={true}
                                    data={{
                                        id: "cancel",
                                        name: Texts.capitalize(texts["cancel"]) || "Cancel",
                                        customClass: `py-2 px-2 w-auto text-2xl absolute mb-4 right-0 top-0 ${
                                            noPadding ? "m-4" : ""
                                        } ${backBtnClass ? backBtnClass : ""}`,
                                        onClick() {
                                            dispatch(setPopUp(null));
                                        },
                                    }}
                                />
                            ) : null}
                        </div>
                        <div className="w-full">
                            {title ? (
                                <div
                                    className={`w-full text-center pb-6 bold text-3xl ${titleClass} ${
                                        noPadding ? "pt-6" : ""
                                    }`}
                                >
                                    {title}
                                </div>
                            ) : null}
                            {subtitle ? <div className={`w-full text-center pb-6 text-2xl`}>{subtitle}</div> : null}
                            {body}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
