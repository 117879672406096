/* eslint-disable react-hooks/exhaustive-deps */

import { memo, useEffect } from "preact/compat";
import { printCenteredMessage } from "../utils/utils";
import Limbo from "../components/screen/limbo";
import { eventHandler } from "../utils/eventHandler";
import { KEYS } from "../utils/keys";
import focus from "../utils/focus";
import { STB } from "../hooks/apis/stb";

const NoLanding = () => {
    useEffect(() => {
        focus.value.replace("limbo");
        setTimeout(() => {
            STB.reload();
        }, 60000);
    }, []);

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        if ([KEYS.home, KEYS.enter].indexOf(keyData.value) == -1) {
            e.stopPropagation();
        }
    };
    return (
        <div
            onKeyDown={(e) => {
                onkeydown(e);
            }}
        >
            <Limbo />
            {printCenteredMessage(
                "No content available. Please use ZAFIRO Manager to define it",
                null,
                "black",
                "white",
            )}
        </div>
    );
};

export default memo(NoLanding);
