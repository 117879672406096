/* eslint-disable no-undef */
import { prepareDeviceKeyMap } from "../../../../utils/utils";
import { keyMap } from "./keyMap";
import { samsung } from "./samsung";

export const STB = {
    model: "Samsung-Orsay",
    needCanvas: true,
    ref: ``,
    init() {
        this.keyMap = prepareDeviceKeyMap(keyMap);
        samsung.init();
        this.registerKeys();
        this.ref = `${this.model}-${this.getMac()}`;

        //get model and Firmware
        localStorage.setItem("tvModel", samsung.getModel());
        localStorage.setItem("firmwareVersion", samsung.getFirmware());
    },
    getMac(onSuccess) {
        const m = siNetwork.GetHWaddr();
        this.mac = `${m.charAt(0) + m.charAt(1)}:${m.charAt(2)}${m.charAt(3)}:${m.charAt(4)}${m.charAt(5)}:${m.charAt(
            6
        )}${m.charAt(7)}:${m.charAt(8)}${m.charAt(9)}:${m.charAt(10)}${m.charAt(11)}`;
        this.ref = `${this.model}-${this.mac}`;
        if (onSuccess) {
            onSuccess();
        }
    },
    registerKeys() {
        //this is to receive key events from a usb keyboards
        samsung.enableKeyboard();

        //console.debug("samsung register all keys" + JSON.stringify(samsung.api.tvKey) );
        samsung.api.plugin.registAllKey(); //register all keys including Power Key

        samsung.api.plugin.unregistKey(76); //Power button
        samsung.api.plugin.unregistKey(261); // Smart Hub
        //samsung.api.plugin.unregistKey(1118); // home
        //Volume and mute keys
        samsung.api.plugin.unregistKey(7);
        samsung.api.plugin.unregistKey(11);
        samsung.api.plugin.unregistKey(27);
        samsung.api.plugin.unregistKey(222);

        siAppCommon.UnregisterKey(7);

        siAppCommon.UnregisterKey(11);
        siAppCommon.UnregisterKey(27);
        siAppCommon.UnregisterKey(222);

        siAppCommon.RegisterKey(20); //Green key, PARECE QUE EL REGISTER ALL NO REGISTRA ESTA TECLA

        siAppCommon.RegisterKey(samsung.api.tvKey.KEY_MENU);
        // siAppCommon.RegisterKey(samsung.api.tvKey.KEY_SOURCE);
        siAppCommon.RegisterKey(1118); // portal key
        siAppCommon.RegisterKey(47); //alarm

        //teletext key
        samsung.api.plugin.unregistKey(650);
        //source key
        //siAppCommon.UnregisterKey(222);
    },
    powerOn() {
        samsung.setPowerOn();
    },
    powerOff() {
        samsung.setPowerOff();
    },
    updatePowerState() {
        let status = samsung.getPowerState();
        localStorage.setItem("powerState", status == 1 ? "online" : "standby");
    },
    setVolume(volume) {
        samsung.setVolume(volume);
    },
};
