import React, { useEffect } from "react";
import { figmapx2vh, figmapx2vw, setFocusOnGlobalLimbo } from "../utils/utils";
import ReactHtmlParser from "react-html-parser";

const NoContent = ({
    text = "<h3 style='margin-top: 0'>Coming soon!</h3>We are curently working on this content",
    style = { color: "rgba(46, 56, 67, 1)", backgroundColor: "rgba(184, 206, 215, 0.7)" },
}) => {
    useEffect(() => {
        setFocusOnGlobalLimbo();
    }, []);

    // device not installed with network-error
    return (
        <div className="fixed w-full h-full z-100">
            <div className="table w-full h-full">
                <div
                    className={`table-cell vertical-middle w-full h-full text-center white text-5xl`}
                    style={{ background: "radial-gradient(rgba(55, 61, 66,0.8), transparent)" }}
                >
                    <div
                        className={"text-4xl mx-auto table"}
                        style={{
                            width: `${figmapx2vw(1458)}vw`,
                            height: `${figmapx2vh(288)}vh`,
                            color: style.color,
                            backgroundColor: style.backgroundColor,
                        }}
                    >
                        <div id="noContent_text" className={"w-full my-auto table-cell vertical-middle"}>
                            {ReactHtmlParser(text)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NoContent;
