import localeCode from "locale-code";
import { Media } from "../hooks/apis/media";
import { STB } from "../hooks/apis/stb";
import Query from "../hooks/query";
import { CHANNELS, HOME, MESSAGES_STYLES, PATHS, PERMISSION, TV_MODELS } from "./constants";
import { KEYS } from "./keys";
import { Texts } from "./texts";
import type { Jsonifiable } from "type-fest";
import focus from "./focus";
import { SESSION } from "./session";
const Countries = require("./../lang/es/country.json");

if (typeof localStorage !== "undefined" && localStorage.getItem("logEnabled")) {
    console.log = function (lvl: string | number) {
        //@ts-ignore
        writeLog(STB.mac + " - " + lvl);
    };
    console.error = function (msg: string) {
        //@ts-ignore
        writeLog(STB.mac + " - " + msg);
    };
}

// type DeviceType = "PC" | "Philips" | "LG" | "Samsung-Tizen" | "Samsung-Orsay" | "STB" | "GoogleTV";
type DeviceType = (typeof TV_MODELS)[keyof typeof TV_MODELS];

export const checkDeviceType = (): DeviceType => {
    let model: DeviceType = TV_MODELS.PC;
    const userAgent = typeof navigator !== "undefined" ? navigator.userAgent.toLocaleLowerCase() : "";
    console.log("userAgent", userAgent)
    if (userAgent) {
        switch (true) {
            case userAgent.indexOf("philips") > -1:
                model = TV_MODELS.PHILIPS;
                break;
            case userAgent.indexOf("lge") > -1:
                model = TV_MODELS.LG;
                break;
            case userAgent.indexOf("tizen") > -1:
                model = TV_MODELS.TIZEN;
                break;
            case userAgent.indexOf("smarttv") > -1:
                model = TV_MODELS.ORSAY;
                break;
            case userAgent.indexOf("estb") > -1:
                model = TV_MODELS.STB;
                break;
            case userAgent.indexOf("chromecast") > -1:
            case userAgent.indexOf("mitv-afkr0") > -1:
                model = TV_MODELS.GOOGLE_TV;
                break;
            default:
                break;
        }
    }
    return model;
};

export const cleanWhiteChars = (text: string): string => {
    return text.replace ? text.replace(/\s/g, "") : text;
};

export const replaceAll = (text: string, busca: string, reemplaza: string): string => {
    if (!text) {
        return text;
    }
    while (text.toString().indexOf(busca) !== -1) text = text.toString().replace(busca, reemplaza);
    return text;
};

export const padZero = (str: string | number, len: number = 2): string => {
    while (String(str).length < len) {
        str = `0${str}`;
    }
    return String(str);
};

export const cloneObject = <T extends Jsonifiable>(object: T): T => {
    return JSON.parse(JSON.stringify(object));
};

export const isJsonString = (str: string): boolean => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const cleanLog = () => {
    const logDiv = document.querySelector("#log");
    if (logDiv) {
        logDiv.innerHTML = "";
    }
};

export const inHomePage = (): boolean => {
    return (
        window.location.pathname.length === 1 ||
        window.location.pathname === "/index.html" ||
        window.location.pathname === "/"
    );
};

export const focusOnLimbo = (): boolean => {
    return focus.value.current === "limbo" || focus.value.current === "noFocus";
};

export const setFocusOnGlobalLimbo = () => {
    focus.value.replace("noFocus");
};

export const getCurrentLayout = (): string => {
    const path = window.location.pathname;
    if (path.length === 1 || path === "/index.html" || path === "/") {
        return HOME;
    }
    return path.split("/")?.[1] ?? "undefined";
};

export const getChannelData = (onSuccess?: (channels: any | null) => void, extraInfo?: any) => {
    let channels = sessionStorage.getItem("channels");
    if (channels) {
        if (onSuccess) {
            onSuccess(JSON.parse(channels));
        }
        return;
    }

    if (SESSION.hasPermission(PERMISSION.NEW_CHANNELS)) {
        Query({
            query: `{
                channels{
                    tuneGroups{
                        name
                        tunes{
                            id
                  channelName
                  countryRef
                  isRadio
                  languageRef
                  logoRef
                  tuneInfo
                  type          
                }
            }
        }
    }`,
            onResponse(res) {
                if (res?.data?.channels?.tuneGroups) {
                    const channels = composeNewChannelGroups(res.data.channels.tuneGroups, extraInfo, false);
                    sessionStorage.setItem("channels", JSON.stringify(channels));

                    if (!SESSION.isTVOnCheckout() && SESSION.hasPermission(PERMISSION.HIGH_AVAILABILITY)) {
                        const channelsHA = composeNewChannelGroups(res.data.channels.tuneGroups, extraInfo, true);
                        STB.saveDataForHA("grid", JSON.parse(channelsHA.channels).categories);
                    }

                    if (onSuccess) {
                        onSuccess(channels);
                    }
                } else if (res && (!res.data || (res.data && !res.data.channels))) {
                    if (onSuccess) {
                        onSuccess(null);
                    }
                } else if (res?.error) {
                    console.log("HAY ERRORES");
                }
            },
        });
    } else {
        Query({
            query: `{
            oldChannels{
                channels
                cloudURL
                projectRef
            }
        }`,
            onResponse(res) {
                if (res?.data?.oldChannels?.channels) {
                    const channels = composeChannelGroups(res.data.oldChannels, extraInfo);
                    sessionStorage.setItem("channels", JSON.stringify(channels));
                    if (!SESSION.isTVOnCheckout() && SESSION.hasPermission(PERMISSION.HIGH_AVAILABILITY)) {
                        STB.saveDataForHA("grid", JSON.parse(channels.channels).categories);
                    }

                    if (onSuccess) {
                        onSuccess(channels);
                    }
                } else if (res && (!res.data || (res.data && !res.data.oldChannels))) {
                    if (onSuccess) {
                        onSuccess(null);
                    }
                } else if (res?.error) {
                    console.log("HAY ERRORES");
                }
            },
        });
    }
};

const hasTuneEnabled = (tuneInfo: any, seek: string): { hasTune: boolean; tuneKey: string | null } => {
    let hasTune = false;
    let tuneKey = null;
    if (!tuneInfo) {
        return { hasTune, tuneKey };
    }
    for (const [key] of Object.entries(tuneInfo).filter((_tune: any) => _tune[1]?.isEnabled || _tune[1]?.IsEnabled)) {
        if (key.indexOf(seek) > -1) {
            hasTune = true;
            tuneKey = key as string;
        }
    }
    return { hasTune, tuneKey };
};
const convertTuneToOldChannelInfo = (tune: {
    id: string;
    tuneInfo: string;
    channelName: string;
    isRadio: boolean;
    languageRef: string;
    countryRef: string;
    logoRef: string;
    udp: { ip: string; port: number };
    hls: { url: string };
}) => {
    const tuneInfo = JSON.parse(tune.tuneInfo);

    // INFO: orden de sintonización en HA: 1- DVB, 2- UDP, 3- HLS
    const _tuneAux = {
        id: tune.id,
        name: tune.channelName,
        isradio: tune.isRadio,
        languageCode: tune.languageRef,
        countryCode: tune.countryRef,
        logofile: tune.logoRef,
        logoURL: completeDasURL(tune.logoRef, { width: "120" }),
        type: null,
        num: 0,
        hasTune: false,
    };

    // INFO: orden de sintonización en tv-app: 1- UDP, 2- HLS, 3- DVB
    if (hasTuneEnabled(tuneInfo, "udp").hasTune) {
        // IS UDP
        return { ..._tuneAux, ip: tuneInfo?.udp?.ip, port: tuneInfo?.udp?.port, type: "UDP", hasTune: true };
    } else if (hasTuneEnabled(tuneInfo, "hls").hasTune) {
        // IS HLS
        return { ..._tuneAux, hlsurl: tuneInfo?.hls?.url, type: "HLS", hasTune: true };
    } else if (hasTuneEnabled(tuneInfo, STB.model).hasTune) {
        // IS DVB
        const _hasTuneByModel = hasTuneEnabled(tuneInfo, STB.model);
        // TODO: faltan los parametros para los canales ATSC {minor, major}
        return {
            ..._tuneAux,
            frequency: tuneInfo[_hasTuneByModel.tuneKey || 0]?.Frequency,
            isc: tuneInfo[_hasTuneByModel.tuneKey || 0]?.Isc,
            satelliteId: tuneInfo[_hasTuneByModel.tuneKey || 0]?.SatelliteID,
            type: _hasTuneByModel.tuneKey?.split("-")[0],
            hasTune: true,
        };
    }
    return _tuneAux;
};

const convertTuneToHAChannelInfo = (tune: {
    id: string;
    tuneInfo: string;
    channelName: string;
    isRadio: boolean;
    languageRef: string;
    countryRef: string;
    logoRef: string;
    udp: { ip: string; port: number };
    hls: { url: string };
}) => {
    const tuneInfo = JSON.parse(tune.tuneInfo);

    const _tuneAux = {
        id: tune.id,
        name: tune.channelName,
        isradio: tune.isRadio,
        languageCode: tune.languageRef,
        countryCode: tune.countryRef,
        logofile: tune.logoRef,
        logoURL: completeDasURL(tune.logoRef, { width: "120" }),
        type: null,
        num: 0,
        hasTune: false,
    };

    // INFO: orden de sintonización en HA: 1- DVB, 2- UDP, 3- HLS
    if (hasTuneEnabled(tuneInfo, STB.model).hasTune) {
        // IS DVB
        const _hasTuneByModel = hasTuneEnabled(tuneInfo, STB.model);
        // TODO: faltan los parametros para los canales ATSC {minor, major}
        return {
            ..._tuneAux,
            frequency: tuneInfo[_hasTuneByModel.tuneKey || 0]?.Frequency,
            isc: tuneInfo[_hasTuneByModel.tuneKey || 0]?.Isc,
            satelliteId: tuneInfo[_hasTuneByModel.tuneKey || 0]?.SatelliteID,
            type: _hasTuneByModel.tuneKey?.split("-")[0],
            hasTune: true,
        };
    } else if (hasTuneEnabled(tuneInfo, "udp").hasTune) {
        // IS UDP
        return { ..._tuneAux, ip: tuneInfo?.udp?.ip, port: tuneInfo?.udp?.port, type: "UDP", hasTune: true };
    } else if (hasTuneEnabled(tuneInfo, "hls").hasTune) {
        // IS HLS
        return { ..._tuneAux, hlsurl: tuneInfo?.hls?.url, type: "HLS", hasTune: true };
    }
    return _tuneAux;
};

const composeNewChannelGroups = (tuneGroups: any[], extraInfo: any, forHA: boolean) => {
    const langCode = localStorage.getItem("lang") || extraInfo?.projectLanguageCode;
    let categories = [],
        allChannels: any[] = [],
        allGroups: any[] = [],
        groupsByCountry: any[] = [],
        groupsByLanguage: any[] = [],
        channelNum: number = 0;

    tuneGroups.forEach((tuneGroup, index) => {
        let groupChannels: any[] = [],
            channelsByCountry: any[] = [],
            channelsByLanguage: any[] = [];
        tuneGroup.tunes.map((tune: any) => {
            channelNum++;

            let channel = forHA ? convertTuneToHAChannelInfo(tune) : convertTuneToOldChannelInfo(tune);
            if (!channel.hasTune) {
                return;
            }
            channel.num = channelNum;
            groupChannels.push(channel);
            if (allChannels.filter((ch) => ch.id === channel.id).length === 0) {
                allChannels.push(channel);
            }
            if (
                extraInfo?.guestCountry &&
                channel.countryCode === extraInfo.guestCountry &&
                channelsByCountry.filter((ch) => ch.id === channel.id).length === 0
            ) {
                channelsByCountry.push(channel);
            }
            if (
                channel.languageCode === langCode &&
                channelsByLanguage.filter((ch) => ch.id === channel.id).length === 0
            ) {
                channelsByLanguage.push(channel);
            }
        });

        if (groupChannels.length > 0) {
            channelsByCountry.length > 0 &&
                groupsByCountry.push({
                    id: index + 1,
                    name: tuneGroup.name,
                    items: channelsByCountry,
                });
            channelsByLanguage.length > 0 &&
                groupsByLanguage.push({
                    id: index + 1,
                    name: tuneGroup.name,
                    items: channelsByLanguage,
                });

            allGroups.push({
                id: index + 1,
                name: tuneGroup.name,
                items: groupChannels /*.sort((a) => {
                    if (a.languageCode === localStorage.getItem("lang") ) {
                        return -1;
                    }else{
                        return 0;
                    }})*/,
            });
        }
    });

    groupsByCountry.length > 0 &&
        categories.unshift({
            id: CHANNELS.FILTERS.COUNTRY,
            name: Texts.capitalize(extraInfo?.texts["From-country"]).replace(
                "{{data}}",
                localeCode.getCountryName(composeLocaleCode(extraInfo?.guestCountry)),
            ),
            items: groupsByCountry,
        });

    groupsByLanguage.length > 0 &&
        categories.unshift({
            id: CHANNELS.FILTERS.LANGUAGE,
            name: localeCode.getLanguageNativeName(composeLocaleCode(langCode)),
            items: groupsByLanguage,
        });
    allGroups.length > 0 &&
        categories.unshift({
            id: CHANNELS.FILTERS.ALL,
            name: extraInfo?.texts["all groups"],
            items: allGroups,
        });

    categories.unshift({
        id: CHANNELS.FILTERS.ALL_CHANNELS,
        name: Texts.capitalize(extraInfo ? extraInfo?.texts["all channels"] : "All channels"),
        items: allChannels,
    });
    const channelsData = { channels: JSON.stringify({ categories: categories }) };
    return channelsData;
};

const composeChannelGroups = (channelsData: any, extraInfo: any) => {
    let channelsGroups = JSON.parse(channelsData.channels);
    let categories = [],
        allChannels: any[] = [],
        allGroups: any[] = [],
        groupsByCountry: any[] = [],
        groupsByLanguage: any[] = [],
        channelNum: number = 0;

    channelsGroups.categories.map((category: any, index: number) => {
        let groupChannels: any[] = [],
            channelsByCountry: any[] = [],
            channelsByLanguage: any[] = [];

        category.items.map((channel: any) => {
            channelNum++;
            channel.num = channelNum;
            groupChannels.push(channel);
            if (allChannels.filter((ch) => ch.id === channel.id).length === 0) {
                allChannels.push(channel);
            }
            if (
                extraInfo?.guestCountry &&
                channel.countryCode === extraInfo.guestCountry &&
                channelsByCountry.filter((ch) => ch.id === channel.id).length === 0
            ) {
                channelsByCountry.push(channel);
            }
            if (
                channel.languageCode === localStorage.getItem("lang") &&
                channelsByLanguage.filter((ch) => ch.id === channel.id).length === 0
            ) {
                channelsByLanguage.push(channel);
            }
        });

        if (groupChannels.length > 0) {
            channelsByCountry.length > 0 &&
                groupsByCountry.push({
                    id: index + 1,
                    name: category.name,
                    items: channelsByCountry,
                });
            channelsByLanguage.length > 0 &&
                groupsByLanguage.push({
                    id: index + 1,
                    name: category.name,
                    items: channelsByLanguage,
                });

            allGroups.push({
                id: index + 1,
                name: category.name,
                items: groupChannels /*.sort((a) => {
                    if (a.languageCode === localStorage.getItem("lang") ) {
                        return -1;
                    }else{
                        return 0;
                    }}),*/,
            });
        }
    });

    groupsByCountry.length > 0 &&
        categories.unshift({
            id: CHANNELS.FILTERS.COUNTRY,
            name: Texts.capitalize(extraInfo.texts["From-country"]).replace(
                "{{data}}",
                localeCode.getCountryName(composeLocaleCode(extraInfo.guestCountry)),
            ),
            items: groupsByCountry,
        });

    groupsByLanguage.length > 0 &&
        categories.unshift({
            id: CHANNELS.FILTERS.LANGUAGE,
            name: localeCode.getLanguageNativeName(composeLocaleCode(localStorage.getItem("lang") as string)),
            items: groupsByLanguage,
        });

    allGroups.length > 0 &&
        categories.unshift({
            id: CHANNELS.FILTERS.ALL,
            name: extraInfo.texts["all groups"],
            items: allGroups,
        });

    categories.unshift({
        id: CHANNELS.FILTERS.ALL_CHANNELS,
        name: Texts.capitalize(extraInfo ? extraInfo.texts["all channels"] : "All channels"),
        items: allChannels,
    });

    return {
        channels: JSON.stringify({ categories: categories }),
        cloudURL: channelsData.cloudURL,
        projectRef: channelsData.projectRef,
    };
};

export const getLanguageNameFromIso = (iso: string) => {
    switch (iso) {
        case "qaa":
        case "xx":
        case "un":
            return "Unknown";
        default:
            break;
    }
    if (iso == "es") {
        return localeCode.getLanguageNativeName(composeLocaleCode(iso)) || iso;
    }
    return iso ? localeCode.getLanguageName(composeLocaleCode(iso)) || iso : null;
};

export const getCountryNameFromIso = (iso: string) => {
    if (localStorage.getItem("lang") === "es") {
        return Countries[iso?.toLowerCase()];
    }
    return iso ? localeCode.getCountryName(composeLocaleCode(iso)) || iso : null;
};
export const serverRequest = (text: string) => {
    // Creating Our XMLHttpRequest object
    let xhr = new XMLHttpRequest();

    // Making our connection
    // let url = `http://10.11.50.9?${text}${new Date()}`;
    let url = `http://10.11.1.1?${text}${new Date()}`;
    xhr.open("GET", url, true);

    // function execute after request is successful
    xhr.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
            console.log(this.responseText);
        }
    };
    // Sending our request
    xhr.send();
};
export const writeLog = (text: string, error?: boolean) => {
    if (!localStorage.getItem("logEnabled")) {
        return null;
    }
    const logDiv = document.querySelector("#log");
    if (logDiv) {
        if (error) {
            text = `<div style="background-color:red; padding:5px; color:white">${text}</div>`;
        }
        logDiv.innerHTML = `${logDiv.innerHTML}<br>${text}`;
    }
    //Timeout to clean log after x seconds
    //@ts-ignore
    clearTimeout(STB.logTimeout);
    //@ts-ignore
    STB.logTimeout = setTimeout(() => {
        const logDiv = document.querySelector("#log");
        if (logDiv) {
            logDiv.innerHTML = "";
        }
    }, 5000);
};

export const completeDasURL = (assetRef: string, options: { width: string }): string => {
    let params = [];
    params.push(`lang=${localStorage.getItem("lang") || "en"}`);
    options?.width && params.push(`w=${options?.width === "full" ? document.body.offsetWidth : options?.width}`);
    let concatChar = "?";
    if (assetRef?.indexOf("?") > -1) {
        concatChar = "&";
    }
    return assetRef ? `${getEnvVar("API")}das/${assetRef}${concatChar}${params.join("&")}` : "";
};

export const completeVOD_URL = (assetRef: string): string => {
    //@ts-ignore
    let vodDomain = Media.VODDomain || getEnvVar("API");

    if (vodDomain && vodDomain.indexOf("http") === -1) {
        vodDomain = `https://${vodDomain}`;
    }
    return assetRef ? `${vodDomain}/das/stream/${assetRef}-${localStorage.getItem("lang") || "en"}.m3u8` : "";
};

export const vw2px = (width: number): number => {
    const onevw = document.body.offsetWidth / 100;
    return Math.round(width * onevw);
};

export const vh2px = (height: number): number => {
    const onevh = document.body.offsetHeight / 100;
    return Math.round(height * onevh);
};

export const px2vw = (width: number): number => {
    const onevw = document.body.offsetWidth / 100;
    return width / onevw;
};
export const px2vh = (height: number): number => {
    const onevh = document.body.offsetHeight / 100;
    return height / onevh;
};

const FIGAM_WIDTH = 1922;
const FIGAM_HEIGHT = 1088;

export const figmapx2vw = (width: number): number => {
    const onevw = FIGAM_WIDTH / 100;
    return width / onevw;
};

export const figmapx2vh = (height: number): number => {
    const onevh = FIGAM_HEIGHT / 100;
    return height / onevh;
};

export const prepareDeviceKeyMap = (
    deviceKeyMap: { code: number; value: string | number; type?: "arrow" | "numeric" }[],
) => {
    const baseKeyMap = [
        { code: 13, value: KEYS.enter },
        { code: 32, value: KEYS.back },
        { code: 72, value: KEYS.home },
        { code: 76, value: KEYS.lang },

        { code: 37, value: KEYS.left, type: "arrow" },
        { code: 38, value: KEYS.up, type: "arrow" },
        { code: 39, value: KEYS.right, type: "arrow" },
        { code: 40, value: KEYS.down, type: "arrow" },

        { code: 48, value: 0, type: "numeric" },
        { code: 49, value: 1, type: "numeric" },
        { code: 50, value: 2, type: "numeric" },
        { code: 51, value: 3, type: "numeric" },
        { code: 52, value: 4, type: "numeric" },
        { code: 53, value: 5, type: "numeric" },
        { code: 54, value: 6, type: "numeric" },
        { code: 55, value: 7, type: "numeric" },
        { code: 56, value: 8, type: "numeric" },
        { code: 57, value: 9, type: "numeric" },

        { code: 77, value: KEYS.messages },
    ] as { code: number; value: string | number; type?: "arrow" | "numeric" }[];

    let tempList: { code: number; value: string | number; type?: "arrow" | "numeric" }[] = [];
    //remove from baseKeyMap keys present in deviceKeyMap
    baseKeyMap.forEach((bKey) =>
        deviceKeyMap.filter((dKey) => dKey.value === bKey.value) &&
        deviceKeyMap.filter((dKey) => dKey.value === bKey.value).length > 0
            ? null
            : tempList.push(bKey),
    );

    tempList = tempList.concat(deviceKeyMap);

    return tempList;
};

export const printCenteredMessage = (
    text: string,
    style: string,
    bgColor: string = "white",
    fgColor: string = "black",
) => {
    switch (style) {
        case MESSAGES_STYLES.OVERLAY:
            //@ts-ignore
            bgColor = "radial-gradient(rgba(55, 61, 66,0.8), transparent)";
            fgColor = "white";
            break;

        default:
            break;
    }
    return (
        <div
            className="absolute w-full h-full table text-3xl"
            style={{
                background: bgColor,
                color: fgColor,
                zIndex: "499",
            }}
        >
            <div className="table-cell vertical-middle text-center">{text}</div>
        </div>
    );
};

export const getRamdonMAC = (): string => {
    let hexDigits = "0123456789ABCDEF";
    let macAddress = "";
    for (let i = 0; i < 6; i++) {
        macAddress += hexDigits.charAt(Math.round(Math.random() * 15));
        macAddress += hexDigits.charAt(Math.round(Math.random() * 15));
        if (i != 5) macAddress += ":";
    }

    return macAddress;
};

export const getLangName = (iso: string): string => {
    switch (iso) {
        case "es":
        case "spa":
            return "Español";
        case "en":
        case "eng":
            return "English";
        default:
            return iso;
    }
};

export const getTVLang = () => {
    return localStorage.getItem("lang");
};

export const saveChannelToRestore = (chID: string, rememberLastTvChannel: boolean) => {
    //@ts-ignore
    if (rememberLastTvChannel && !SESSION.isTVOnCheckout()) {
        localStorage.setItem("channel_to_restore", chID);
    }
};

export const removeChannelToRestore = () => {
    localStorage.removeItem("channel_to_restore");
};

export const isWelcomed = (sessionData?: {
    stayguest?: { stayGuestRooms?: { roomID: string; welcomed: boolean }[] };
    room: { id: string };
}): boolean => {
    return (sessionData?.stayguest?.stayGuestRooms?.filter(
        (room) => parseInt(room.roomID) == parseInt(sessionData.room.id),
    ) &&
        sessionData.stayguest.stayGuestRooms.filter(
            (room) => parseInt(room.roomID) == parseInt(sessionData.room.id),
        )[0] &&
        sessionData.stayguest.stayGuestRooms.find((room) => parseInt(room.roomID) == parseInt(sessionData.room.id))!
            .welcomed) as boolean;
};

export const inPreview = (): boolean => {
    return sessionStorage.getItem("inPreview") === "true";
};

export const removeArrayItem = <T extends []>(array: T, item: T[number]) => {
    const index = array.indexOf(item);
    if (index > -1) {
        array.splice(index, 1);
    }
};

export const getBorderOffset = (elem: HTMLElement): number | undefined => {
    if (!elem) return;
    let offset = 0;
    offset = offset + parseFloat(window.getComputedStyle(elem).getPropertyValue("margin-top").replace("px", ""));
    offset = offset + parseFloat(window.getComputedStyle(elem).getPropertyValue("margin-bottom").replace("px", ""));
    offset = offset + parseFloat(window.getComputedStyle(elem).getPropertyValue("border-top-width").replace("px", ""));
    offset =
        offset + parseFloat(window.getComputedStyle(elem).getPropertyValue("border-bottom-width").replace("px", ""));

    return offset;
};

export const includeLibraries = (onLoad?: () => void, onError?: () => void): void => {
    const wrPath = `${getEnvVar("DRIVE")}render/widgetsRender-1.0.0.min.js`;
    if (document.getElementsByTagName("HEAD")[0] && document.getElementsByTagName("HEAD")[0]!.children) {
        const headElements = [];

        const headItems = document.getElementsByTagName("HEAD")[0]!.children;
        for (let i = 0; i < headItems.length; i++) {
            headElements.push(headItems[i]);
        }

        let element_found = null;

        headElements.forEach((element) =>
            element!.getAttribute("href") && element!.getAttribute("href") === wrPath
                ? (element_found = element)
                : null,
        );
        if (!element_found) {
            let link = document.createElement("script");
            link.src = wrPath + "?v=77" + localStorage.getItem("appVersion");
            link.onload = function () {
                if (onLoad) onLoad();
            };
            link.onerror = function () {
                if (onError) onError();
            };

            // link.src = "http://10.11.124.212:5501/public/render/widgetsRender-1.0.0.min.js?rand=" + Math.random();
            // link.src = "http://10.11.50.9:5501/public/render/widgetsRender-1.0.0.js?rand=" + Math.random();
            //   link.src = "http://10.11.50.9/widgetsRender.js?rand=" + Math.random();
            document.getElementsByTagName("HEAD")[0]!.appendChild(link);
        }
    }
};

export const limitDecimals = (num: number, decimals: number = 2): string => {
    return num.toFixed(decimals);
};

export const getVendureTranslation = (translations: { name: string; languageCode: string }[]): string => {
    return (
        translations?.find((t) => t.languageCode === localStorage.getItem("lang") && t.name)?.name ??
        translations?.find((t) => t.languageCode === "en" && t.name !== undefined)?.name ??
        (translations ?? []).filter((item) => item.name)[0]?.name ??
        "undefined"
    );
};

export const createObject = (origin: Record<string, any>) => {
    let _newObject = {};
    for (const property of Object.keys(origin)) {
        let val = origin[property];
        if (["utils", "_render"].indexOf(property) > -1) {
            val = createObject(origin[property]);
        }
        Object.defineProperty(_newObject, property, {
            value: val,
            writable: true,
        });
    }
    return _newObject;
};

export const isMenuItem = (id: string): boolean => {
    let elementFocused = document.getElementById(id);
    if (elementFocused && elementFocused.getAttribute("menuItem")) {
        return true;
    }
    return false;
};

export const getEnvVar = (key: "VENDURE" | "API" | "DRIVE" | string): string => {
    let path = key;
    switch (key) {
        case "API":
            path = window?.ZAFIRO_APIS?.API_URL ?? process.env.PREACT_APP_API_URL;
            break;
        case "DRIVE":
            path = window?.ZAFIRO_APIS?.DRIVE_URL ?? process.env.PREACT_APP_DRIVE_URL;
            break;
        case "VENDURE":
            path = window?.ZAFIRO_APIS?.VENDURE_URL ?? process.env.PREACT_APP_VENDURE_URL;
            break;
        default:
            break;
    }
    return composeLegacyPath(path);
};

const composeLegacyPath = (path: string): string => {
    if (window.location.origin.indexOf("legacy") > -1) {
        return path
            .replace("tv-app.", "tv-app.legacy.")
            .replace("cast.", "cast.legacy.")
            .replace("vendure.", "vendure.legacy.")
            .replace("drive.", "drive.legacy.");
    }
    return path;
};
export const setVideoOnBackground = () => {
    //@ts-ignore
    if (STB.videoOnBack) {
        Media.showVideoOnBack();
        document.body.style.visibility = "hidden";
        document.body.style.backgroundColor = "transparent";
    }
};

export const setVideoOnForeground = () => {
    //@ts-ignore
    if (STB.videoOnBack) {
        Media.showVideoOnFront();
        document.body.style.visibility = "";
        document.body.style.backgroundColor = "transparent";
    }
};

export const hideInterface = () => {
    document.body.style.display = "none";
    document.body.style.backgroundColor = "transparent";
};

export const composeLocaleCode = <T extends string>(code: T): `${Lowercase<T>}-${Uppercase<T>}` => {
    return `${code.toLowerCase() as Lowercase<T>}-${code.toUpperCase() as Uppercase<T>}`;
};

export const printPrice = (
    price: number,
    currencyCode: string,
    decimals: number = 2,
    locale: string = localStorage.getItem("lang") ?? "en",
) => {
    const formatting_options = {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: decimals,
    };
    // @ts-ignore
    const priceFormat = new Intl.NumberFormat(locale, formatting_options);
    return priceFormat.format(price);
};

export const composeNavigationPath = (action: any): string => {
    const _layout = `/${action.layout}`;
    const _id = action.id ? `/${action.id}` : "";
    let __navigateTo = _layout + _id;

    //exceptions
    switch (action.layout) {
        case "roomshop":
            return PATHS.CATEGORIES?.replace(":roomshop", action.id);
        default:
            break;
    }

    return __navigateTo;
};
