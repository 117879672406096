/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useRef, useState } from "preact/compat";
import { KEYS } from "../../utils/keys";
import { Theme } from "../../utils/theme";
import { padZero } from "../../utils/utils";
import { eventHandler } from "../../utils/eventHandler";

const NumericButton = ({ id, value, setValue, minValue = 0, maxValue, width, insideModal }) => {
    let pressNumTimeout = useRef();

    const [inEdition, setInEdition] = useState(false);
    const [number, setNumber] = useState(null);

    let borderColor = null;
    if (insideModal) {
        borderColor = Theme.light ? Theme.colors.default.gray["300"] : Theme.colors.default.gray["600"];
    }

    const updateNumber = (keyData) => {
        if (keyData.type === "numeric") {
            console.log(`${number || 0}${keyData.value}`);
            setNumber(`${number || 0}${keyData.value}`);
            if (pressNumTimeout.current) {
                clearTimeout(pressNumTimeout.current);
            }
            pressNumTimeout.current = setTimeout(() => {
                setNumber(null);
            }, 1500);
            return;
        } else if (number) {
            setNumber(null);
        }

        if (keyData.value === KEYS.enter || keyData.value === KEYS.back) {
            setInEdition(false);
        } else if (keyData.type === "arrow" && keyData.value === KEYS.up) {
            if (maxValue && Number(value) < Number(maxValue)) {
                setValue(Number(value) + 1);
            } else if (maxValue && Number(value) === Number(maxValue)) {
                setValue(minValue || 0);
            }
        } else if (keyData.type === "arrow" && keyData.value === KEYS.down) {
            if (Number(value) > Number(minValue)) {
                setValue(Number(value) - 1);
            } else if (typeof minValue !== "undefined" && maxValue && Number(value) === Number(minValue)) {
                setValue(maxValue);
            }
        }
    };

    useEffect(() => {
        if (number !== null && Number(number) >= Number(minValue) && Number(number) <= Number(maxValue)) {
            setValue(Number(number));
        }
    }, [number]);

    const cWidth = "3vw";

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;
        if (keyData?.value === KEYS.back && inEdition) {
            setInEdition(false);
            preventDefault = true;
        } else if (keyData?.type === "arrow" && inEdition) {
            updateNumber(keyData);
            preventDefault = true;
        }
        if (preventDefault) {
            e.stopPropagation();
        }
    };

    return (
        <div
            className="relative"
            onKeyDown={(e) => {
                onkeydown(e);
            }}
        >
            <div
                id={id}
                style={{
                    width: width || cWidth,
                    height: cWidth,
                    lineHeight: cWidth,
                    borderColor,
                }}
                className={`navigable table rounded border`}
                onClick={() => setInEdition(!inEdition)}
            >
                {padZero(value)}
            </div>
            {inEdition ? (
                <div
                    className="absolute"
                    style={{ marginLeft: `calc((${width || cWidth} - ${Theme.paragraph.size}vw) / 2)` }}
                >
                    <div className="icon icon-chevron-up absolute" style={{ top: "-4.8vw" }} />
                    <div className="icon icon-chevron absolute" style={{ top: "-0.1vw" }} />
                </div>
            ) : null}
        </div>
    );
};

export default memo(NumericButton);
