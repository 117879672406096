import { writeLog } from "./utils";

export const Logger = {
    init() {
        //Enable log in development
        if (process.env.PREACT_APP_DEVELOPMENT && typeof window !== "undefined") {
            window.onerror = function (message, source, lineno, colno) {
                writeLog(`ERROR: ${source} (line: ${lineno}, col: ${colno}) -   ${message}`, true);
            };
        }
    },
};
