import { useEffect, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { setWelcomed } from "../actions/statusActions";
import Query from "../hooks/query";
import { Texts } from "../utils/texts";
import InfoLabel from "./common/infoLabel";
import Screen from "./screen";
import { setMediaEvent } from "../actions/eventsActions";
import { setTexts } from "../actions/uiActions";
import { Media } from "../hooks/apis/media";
import localeCode from "locale-code";
import { composeLocaleCode, inPreview } from "../utils/utils";
import { KEYS } from "../utils/keys";
import { eventHandler } from "../utils/eventHandler";
import focus from "../utils/focus";
import Limbo from "./screen/limbo";

const Welcome = () => {
    const dispatch = useDispatch();

    //Store data
    const sessionData = useSelector((state) => state.status.sessionData);
    const locationData = useSelector((state) => state.status.locationData);
    const texts = useSelector((state) => state.ui.texts);
    const mediaEvent = useSelector((state) => state.events.mediaEvent);

    //States
    const [pos, setPos] = useState(null);
    const [infoLabel, showInfoLabel] = useState(false);
    const [welcomes, setWelcomes] = useState(null);
    const [contentStyle, setContentStyle] = useState(null);

    //If token change, request welcomes again to get proper texts
    useEffect(() => {
        welcomesRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionStorage.getItem("token")]);

    useEffect(() => {
        let langs = [];
        focus.value.replace(`welcome`);

        if (locationData?.projectLangs) {
            locationData.projectLangs.map((pLang) => {
                langs.push({
                    id: pLang.languageRef,
                    name: Texts.capitalize(localeCode.getLanguageNativeName(composeLocaleCode(pLang.languageRef))),
                    isDefault: pLang.isDefault,
                });
            });
            // eslint-disable-next-line no-undef
            Render.langs = langs;
        }
        return () => {
            Media.stop();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (mediaEvent?.code === Media.MEDIA_STATUS_END_OF_STREAM) {
            if (welcomes[pos].content?.contentWidgets) {
                let videoWidget = null;
                welcomes[pos].content.contentWidgets.map((cw) =>
                    !videoWidget && cw.type === "VIDEO" ? (videoWidget = cw) : null,
                );
                if (videoWidget?.data) {
                    const videoData = JSON.parse(videoWidget.data);
                    if (videoData && !videoData.repeat) {
                        showNext();
                        dispatch(setMediaEvent(null));
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaEvent]);

    //Show infolabel in all screens except language selection
    useEffect(() => {
        let contentStyle = null;
        if (welcomes?.[pos]?.content?.contentStyle) {
            contentStyle = JSON.parse(welcomes[pos].content.contentStyle);
            setContentStyle(contentStyle);
            showInfoLabel(contentStyle.textToContinue);
        }
        if (welcomes?.[pos]?.welcomeType !== "LANGSELECTION") {
            focus.value.replace(`limbo`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pos]);

    //Functions
    const isLastScreen = () => {
        return welcomes?.length - 1 === pos;
    };

    const welcomesRequest = (requestNext) => {
        Query({
            query: `{
                welcomeScreen{
                  welcomeContents{
                    welcomeType
                    content{
                      id
                      name
                      contentStyle    
                      pos
                      devices{
                        grid{
                            cols
                            rows
                        }
                        type
                    }
                      contentWidgets{          
                        ID
                        actions
                        data
                        pos
                        type
                        widgetStyle
                        fonts{
                            externalURL
                            libraryRef
                            name
                        }        
                      }        
                    }      
                  }    
                }
              }`,
            onResponse(res) {
                if (res?.data?.welcomeScreen?.welcomeContents) {
                    //si no hay welcome sequence, mark as welcomed
                    if (res.data.welcomeScreen.welcomeContents.length === 0) {
                        markAsWelcomed();
                    } else {
                        setWelcomes(res.data.welcomeScreen.welcomeContents);
                        if (!welcomes) {
                            setPos(0);
                        } else if (requestNext) {
                            showNext();
                        }
                    }
                } else {
                    markAsWelcomed();
                }
            },
        });
    };

    //Query to set user lang
    const onLangClick = (val) => {
        ///////////////////////////////////////////////
        //QUITAR CUANDO SE TENGA TOKEN IMPERSONANDO Y NO SE EJECUTE CAMBIO DE IDIOMA
        if (inPreview()) {
            localStorage.setItem("lang", val);
            dispatch(setTexts(val));
            welcomesRequest(true);
            return;
        }
        ///////////////////////////////////////////////
        Query({
            query: `
                    mutation{ 
                        changeTVLang (language:"${val}") 
                        {
                            error 
                            {
                                code 
                                msg
                            } 
                            ok
                            token                            
                        }
                    }
              `,
            onResponse(res) {
                localStorage.setItem("lang", val);
                if (res?.data?.changeTVLang?.token) {
                    sessionStorage.setItem("token", res.data.changeTVLang.token);
                    //update texts to selected language
                    dispatch(setTexts(val));
                    if (isLastScreen()) {
                        markAsWelcomed();
                    } else {
                        welcomesRequest(true);
                    }
                }
            },
        });
    };

    const markAsWelcomed = () => {
        if (inPreview()) {
            dispatch(setWelcomed());
            return;
        }

        Query({
            query: `
                    mutation{ 
                        welcomeGuest (roomID:${Number(sessionData.room.id)} , stayguestID:${Number(
                            sessionData.stayguest.id,
                        )}) 
                        {
                            error 
                            {
                                code 
                                msg
                            }
                            ok
                        }
                    }
              `,
            onResponse() {
                focus.value.reset();
                dispatch(setWelcomed());
            },
        });
    };

    const showNext = () => {
        Media.stop();
        if (isLastScreen()) {
            markAsWelcomed();
        } else {
            setPos(pos + 1);
        }
    };

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        if (!keyData) {
            return;
        }
        if (keyData.value === KEYS.enter) {
            if (eventHandler?.focusedBtn?.id.indexOf("btn-") > -1) {
                onLangClick(eventHandler?.focusedBtn?.id.replace("btn-", ""));
            } else {
                showNext();
            }
        }
        if (keyData.type === "arrow") {
            return;
        }
        if ([KEYS.home].indexOf(keyData.value) === -1) {
            e.stopPropagation();
        }
    };

    return (
        <div
            className="absolute top-0 w-full h-full"
            style={{ zIndex: "100" }}
            onKeyDown={(e) => {
                onkeydown(e);
            }}
        >
            <Limbo />
            {welcomes ? (
                <Screen
                    data={welcomes[pos].content}
                    preventFocus={welcomes[pos].welcomeType !== "LANGSELECTION"}
                    mediaEvent={mediaEvent}
                    propagateKeys={true}
                    autoplay={true}
                />
            ) : null}

            {infoLabel ? (
                <InfoLabel
                    text={Texts.capitalize(texts["press to continue"].replace("{{key}}", "OK"))}
                    vAlign={contentStyle?.textToContinueVerticalAlign ? contentStyle.textToContinueVerticalAlign : null}
                    hAlign={
                        contentStyle?.textToContinueHorizontalAlign ? contentStyle.textToContinueHorizontalAlign : null
                    }
                />
            ) : null}
        </div>
    );
};

export default Welcome;
