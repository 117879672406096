/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useLayoutEffect, useRef } from "preact/hooks";
import { useSelector } from "react-redux";
import Button from "./button";
import { cleanWhiteChars, vw2px } from "../../utils/utils";
import { Texts } from "../../utils/texts";
import focus from "../../utils/focus";
import { memo } from "preact/compat";
import ChannelSelectorItem from "./list/channelSelectorItem";
import DeliveryItem from "./list/deliveryItem";
import { eventHandler } from "../../utils/eventHandler";
import { KEYS } from "../../utils/keys";
import { isElementVisible } from "../../utils/movement";

const HorizontalList = ({ data, insideModal }) => {
    let {
        id,
        list,
        itemType,
        itemCustomClass,
        itemCustomStyle,
        itemRounded,
        outlineOnfFocus,
        onClick,
        customClass,
        title,
        noDataText,
        logoPath,
        paginated,
        itemsPerPage,
        selected,
        noFocusOnLoad,
        timeSlots,
    } = data;

    //Store data
    const texts = useSelector((state) => state.ui.texts);

    //States
    const [cPage, setCurrentPage] = useState();
    const [totalPages, setTotalPages] = useState(null);
    const movingLeft = useRef(false);

    const [itemsShown, setItemShown] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    /////////////////////
    //Listeners
    //On load, focus list first element
    useEffect(() => {
        if (!noFocusOnLoad && list?.[0]) {
            focus.value.replace(cleanWhiteChars(`btn-${itemType ? `${itemType}-` : ""}${list[0].id}`));
        }
        if (paginated && list) {
            if (!selected) {
                setCurrentPage(1);
                return;
            }
            const itemId = selected.split("-")[1];
            let itemPos = null;
            list.map((item, index) => (String(item.id) === String(itemId) ? (itemPos = index + 1) : null));
            if (itemPos) {
                const pageToset = Number(itemPos / itemsPerPage);
                const mod = itemPos % itemsPerPage;
                setCurrentPage(Math.floor(pageToset ? pageToset + (mod !== 0 ? 1 : 0) : 1));
            }
        }
    }, []);

    useEffect(() => {
        let items = [];
        if (!paginated) {
            items = list;
        } else if (list) {
            list.map((item, index) => {
                if (index < cPage * itemsPerPage && index >= (cPage - 1) * itemsPerPage) {
                    items.push(item);
                }
            });
        }

        setItemShown(items);
        if (!totalPages) {
            setTotalPages(paginated && itemsPerPage ? Number(list.length / itemsPerPage) : null);
        }
    }, [cPage]);

    useEffect(() => {
        if (focus.value.current?.indexOf("horizontalList_") > -1 && focus.value.current.indexOf(id) > -1) {
            if (focus.value.current.indexOf("move_right") > -1) {
                if (cPage < totalPages) {
                    setCurrentPage(cPage + 1);
                } else {
                    ensureFocusOnPageChange();
                }
            } else if (focus.value.current.indexOf("move_left") > -1) {
                if (cPage > 1) {
                    setCurrentPage(cPage - 1);
                } else {
                    ensureFocusOnPageChange();
                }
            }
        }
    }, [focus.value.current]);

    useEffect(() => {
        if (dataLoaded) {
            ensureFocusOnPageChange();
        } else if (itemsShown?.length > 0) {
            if (!noFocusOnLoad) {
                ensureFocusOnPageChange();
            }
            setDataLoaded(true);
        }
    }, [itemsShown]);

    useLayoutEffect(() => {
        itemsShown.forEach((element) => {
            const slot = document.getElementById(`btn-${itemType ? `${itemType}-` : ""}${element.id}`);
            const label = document.getElementById(`btn-${itemType ? `${itemType}-` : ""}${element.id}-label`);
            if (slot && label) {
                let labelWidth = slot.clientWidth;
                let labelMarginR = -0.1;
                if (element.id === list[list.length - 1].id) {
                    labelMarginR = 1;
                } else {
                    labelWidth += vw2px(1);
                }
                label.setAttribute(
                    "style",
                    `width:${labelWidth}px; height: 2rem; margin-right: ${labelMarginR}vw; border-collapse: collapse; padding-left:8px; `,
                );
            }
        });
    }, [itemsShown]);

    const ensureFocusOnPageChange = () => {
        if (itemsShown && itemsShown.length > 0 && movingLeft.current) {
            movingLeft.current = false;
            focus.value.replace(
                cleanWhiteChars(`btn-${itemType ? `${itemType}-` : ""}${itemsShown[itemsShown.length - 1].id}`),
            );
        } else if (itemsShown && itemsShown[0]) {
            focus.value.replace(cleanWhiteChars(`btn-${itemType ? `${itemType}-` : ""}${itemsShown[0].id}`));
        }
    };

    const onClickAction = (value) => {
        onClick(value);
    };

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;

        if (keyData?.type === "arrow" && (keyData.value === KEYS.left || keyData.value === KEYS.right)) {
            const cItem = e.target;
            if (keyData.value === KEYS.left) {
                if (cItem.previousSibling && isElementVisible(cItem.previousSibling)) {
                    focus.value.replace(cItem.previousSibling.id);
                } else if (cPage > 1) {
                    movingLeft.current = true;
                    setCurrentPage(cPage - 1);
                }
            }
            if (keyData.value === KEYS.right) {
                if (cItem.nextSibling && isElementVisible(cItem.nextSibling)) {
                    focus.value.replace(cItem.nextSibling.id);
                } else if (cPage < totalPages) {
                    setCurrentPage(cPage + 1);
                }
            }
            preventDefault = true;
        }
        if (preventDefault) {
            e.stopPropagation();
        }
    };
    let tomorrow = false;
    let countertmorrowSlots = 0;
    return (
        <>
            <div
                id={id}
                onKeyDown={(e) => {
                    onkeydown(e);
                }}
                className={`${customClass ? customClass : ""} overflow-hidden ${
                    !itemsPerPage ? "horizontalList block" : "horizontalListPaginated mx-auto table"
                }`}
                style={{ width: itemsPerPage ? "auto" : "10000%" }}
            >
                {itemsShown.map((item, index) => {
                    return (
                        <Button
                            key={index}
                            insideModal={insideModal}
                            data={{
                                id: `${itemType ? `${itemType}-` : ""}${item.id}`,
                                name: item.name,
                                body: !itemType ? item.body : null,
                                innerHTML: item.innerHTML,
                                rounded: itemRounded,
                                border: true,
                                outline: outlineOnfFocus,
                                active: selected === `${itemType ? `${itemType}-` : ""}${item.id}`,
                                //prettier-ignore
                                customClass: `float-left ${item.customClass || itemCustomClass} `,
                                customStyle: {
                                    ...itemCustomStyle,
                                    marginRight: "1vw",
                                },
                                value: item.id,
                                onClick: onClickAction,
                            }}
                            component={
                                itemType === "channelSelector"
                                    ? ChannelSelectorItem(item, index, logoPath)
                                    : itemType === "deliveryTime"
                                    ? DeliveryItem(item, index)
                                    : null
                            }
                        />
                    );
                })}
                {!list || list.length === 0 ? (
                    <div className="py-4">
                        {Texts.capitalize(texts[noDataText || "no data available"]).replace(
                            "{{data}}",
                            texts[title] || texts["data"],
                        )}
                    </div>
                ) : null}
            </div>
            {timeSlots && (
                <div className={"table w-auto mx-auto"}>
                    {itemsShown.map((item, index) => {
                        const slotTime = new Date(JSON.parse(item.id)[0]);
                        const today = new Date();

                        if (slotTime.getDay() > today.getDay()) {
                            tomorrow = true;
                            countertmorrowSlots++;
                        }
                        if (index % 5 === 0 && tomorrow) {
                            countertmorrowSlots = 1;
                        }
                        return (
                            <div
                                id={`btn-${itemType ? `${itemType}-` : ""}${item.id}-label`}
                                className={`float-left text-2xl ${item.customClass || itemCustomClass} ${
                                    tomorrow && "bg-gray-300 text-gray-900"
                                } ${tomorrow && countertmorrowSlots === 1 && "rounded-l"}`}
                            >
                                {tomorrow && countertmorrowSlots === 1 && "Tomorrow"}
                            </div>
                        );
                    })}
                </div>
            )}
            {itemsShown && itemsShown.length > 0 && paginated && cPage > 1 ? (
                <div className="icon icon-chevron-left absolute" style={{ fontSize: "3vw", top: "30%" }} />
            ) : null}
            {itemsShown && itemsShown.length > 0 && paginated && cPage < totalPages ? (
                <div className="icon icon-chevron-right absolute" style={{ fontSize: "3vw", top: "30%", right: "0" }} />
            ) : null}
        </>
    );
};

export default memo(HorizontalList);
