import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import Button from "../common/button";
import Modal from "../common/modal";
import { Texts } from "../../utils/texts";
import storage from "../../utils/storage";
import { displayErrorPopUp, setPopUp } from "../../actions/uiActions";
import { promisedVendureQuery, resetVendureCache } from "../../hooks/query";
import { inPreview } from "../../utils/utils";

const OrderCancel = () => {
    const dispatch = useDispatch();
    const texts = useSelector((state) => state.ui.texts);
    const [cancelSuccess, setCancelSuccess] = useState(false);
    const [cancelError, setCancelError] = useState(false);

    const closePopUpOrderCancel = () => {
        resetVendureCache();
        dispatch(setPopUp(null));
        if (cancelSuccess) {
            history.back();
        }
    };

    const btnClose = {
        id: "close",
        name: Texts.capitalize(Texts.translate(texts, "close")),
        customClass: "text-center py-2 w-full text-2xl",
        border: true,
        onClick: closePopUpOrderCancel,
    };

    const mutationCancelOrder = (channelToken, orderId) => {
        if (inPreview()) {
            dispatch(displayErrorPopUp({ text: texts["preview-msg"], timeout: 3000 }));
            return;
        }
        return promisedVendureQuery({
            body: `
                mutation {
                    cancelOrder(
                        input: {orderId: ${orderId}}
                    ){
                        __typename
                        ...on Order {
                            id
                        }
                    }
                }`,
            apiType: "admin",
            headers: { Authorization: `Bearer ${sessionStorage.getItem("stayToken")}`, "vendure-token": channelToken },
        }).then((res) => {
            console.log(res);
            if (res?.errors?.length > 0) {
                setCancelError(true);
            } else {
                setCancelSuccess(true);
            }
        });
    };

    return cancelSuccess ? (
        <Modal
            title={Texts.translate(texts, "order-cancelled")}
            height={"36vh"}
            width={"37vw"}
            firstFocus={"btn-close"}
            backBtn={false}
            body={
                <>
                    <div style={{ height: "30vh" }}>
                        <div className="pt-8">{ReactHtmlParser(Texts.translate(texts, "order-cancelled-success"))}</div>
                        <div className="py-2 mt-8">
                            <Button insideModal={true} data={btnClose} />
                        </div>
                    </div>
                </>
            }
        />
    ) : cancelError ? (
        <Modal
            title={Texts.translate(texts, "order-not-cancelled")}
            height={"42vh"}
            width={"37vw"}
            firstFocus={"btn-close"}
            backBtn={false}
            body={
                <>
                    <div style={{ height: "30vh" }}>
                        <div className="pt-4">{ReactHtmlParser(Texts.translate(texts, "order-cancelled-error"))}</div>
                        <div className="py-2 mt-8">
                            <Button insideModal={true} data={btnClose} />
                        </div>
                    </div>
                </>
            }
        />
    ) : (
        <Modal
            title={Texts.translate(texts, "order-cancellation")}
            height={"52vh"}
            width={"40vw"}
            firstFocus={"btn-cancel"}
            backBtn={true}
            body={
                <>
                    <div style={{ height: "30vh" }}>
                        <div className="pt-4">
                            {ReactHtmlParser(Texts.translate(texts, "order-cancellation-text1"))}
                        </div>
                        <div className="pt-4 mt-4">
                            {ReactHtmlParser(Texts.translate(texts, "order-cancellation-text2"))}
                        </div>
                        <div className="py-2 mt-8">
                            <Button
                                insideModal={true}
                                data={{
                                    id: "cancel",
                                    name: Texts.capitalize(Texts.translate(texts, "cancel-order")),
                                    customClass: "text-center py-2 w-full text-2xl",
                                    border: true,
                                    onClick: () => {
                                        mutationCancelOrder(storage.get("orderToken"), location.pathname.split("/")[2]);
                                    },
                                }}
                            />
                        </div>
                    </div>
                </>
            }
        />
    );
};

export default OrderCancel;
