import { useEffect, useState } from "preact/hooks";
import { useSelector } from "react-redux";
import { Media } from "../../hooks/apis/media";
import { Texts } from "../../utils/texts";
import { eventHandler } from "../../utils/eventHandler";
import focus from "../../utils/focus";
import { figmapx2vh, figmapx2vw, getLanguageNameFromIso } from "../../utils/utils";
import { KEYS } from "../../utils/keys";
import { memo, useMemo, useRef } from "react";

const ChannelLangSelector = ({ playingChannel }) => {
    //States
    const [langs, setLangs] = useState(null);
    const [currentLang, setCurrentLang] = useState(0);
    const [selector, showSelector] = useState(false);

    const currentLangRef = useRef();
    currentLangRef.current = currentLang;

    const langsRef = useRef();
    langsRef.current = langs;

    const SELECTOR_ID = "langSelector";

    //Store data
    const texts = useSelector((state) => state.ui.texts);

    useEffect(() => {
        if (selector) {
            showLanguageList();
        }
    }, [selector]);

    useEffect(() => {
        setCurrentLang(0);
        setLangs(null);
    }, [playingChannel]);

    const showLanguageList = () => {
        const onAudioList = (list) => {
            if (!list) {
                list = [];
            }

            list?.length > 0 && setLangs(list);
            setTimeout(function () {
                focus.value.replace(SELECTOR_ID);
            }, 50);
        };
        Media.getAudioList(onAudioList);
    };

    const isFocused = (id) => {
        return focus.value.current === id;
    };

    const hideSelector = () => {
        showSelector(false);
        setTimeout(function () {
            focus.value.replace("btn-audio");
        }, 20);
    };
    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        if (keyData) {
            switch (keyData.value) {
                case KEYS.left:
                    e.stopPropagation();
                    break;

                default:
                    break;
            }
        }
    };

    const onSelectorkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        if (keyData) {
            switch (keyData.value) {
                case KEYS.up:
                    setCurrentLang(langs?.[currentLang + 1] ? currentLang + 1 : 0);
                    break;
                case KEYS.down:
                    setCurrentLang(langs?.[currentLang - 1] ? currentLang - 1 : langs?.length - 1);
                    break;
                case KEYS.enter:
                    hideSelector();
                    Media.changeAudio(langsRef.current?.[currentLangRef.current].id);
                    break;
                case KEYS.back:
                    hideSelector();
                    break;
                default:
                    break;
            }
        }
        e.stopPropagation();
    };

    return useMemo(() => {
        return (
            <>
                <div
                    id="btn-audio"
                    onClick={() => showSelector(true)}
                    onKeyDown={(e) => {
                        onkeydown(e);
                    }}
                    tabIndex={0}
                    focusClass="channelList-focus"
                    className={`icon icon-audio relative navigable ${selector ? "iconActive" : ""}`}
                    style={{ marginRight: `${figmapx2vw(32)}vw`, lineHeight: `${figmapx2vh(80)}vh` }}
                >
                    <div
                        className={`lato ${
                            isFocused("btn-audio") || selector || focus.value.current === SELECTOR_ID
                                ? "iconNameFocused"
                                : "hidden"
                        }`}
                    >
                        {Texts.capitalize(texts["audio"])}
                    </div>
                    {selector ? (
                        <>
                            <div
                                onKeyDown={(e) => {
                                    onSelectorkeydown(e);
                                }}
                                id={`${SELECTOR_ID}`}
                                focusClass="channelList-focus"
                                tabIndex={0}
                                className={"selector w-full table navigable absolute channelList-focus text-center"}
                                style={{
                                    fontSize: `${figmapx2vw(30)}vw`,
                                    fontWeight: 400,
                                    height: !langs || langs?.length === 1 ? `${figmapx2vh(104)}vh` : null,
                                }}
                            >
                                <div className={"table-cell lato vertical-middle"} style={{ lineHeight: 1 }}>
                                    {getLanguageNameFromIso(langs?.[currentLang].name || playingChannel.languageCode) ||
                                        texts["unavailable"]}

                                    {langs?.length > 1 ? (
                                        <div>
                                            <div className="icon icon-chevron-up absolute" />
                                            <div className="icon icon-chevron absolute" />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            </>
        );
    });
};

export default memo(ChannelLangSelector);
