import { makeKeyboardEvent } from "../../../utils/events";
import { FC, useEffect } from "react";
import { useRef } from "preact/hooks";
import { useSignal } from "@preact/signals";
import focus from "../../../utils/focus";


export default (({ id, style = {}, className, signal, disabled, min, max }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const isFocused = useSignal(false);
    //Cuando se pulsa enter quitamos el primer handler y colocamos el segundo
    const beforeLock = makeKeyboardEvent(['enter', 'up'], (key) => {
        if (!inputRef?.current)
            return;
        if (key === "enter") {
            isFocused.value = true;
            inputRef.current.removeEventListener('keydown', beforeLock);
            inputRef.current.addEventListener('keydown', onInput);
            return { stopPropagation: true };
        } else if (key === "up") {
            const allergens = document.getElementById("allergens");
            const description = document.getElementById("description");
            if (allergens?.classList?.contains("navigable")) {
                focus.value.replace("allergens");
                return { stopPropagation: true, preventDefault: true };
            } else if (description?.classList?.contains("navigable")) {
                focus.value.replace("description");
                return { stopPropagation: true, preventDefault: true };
            }
        }
    });

    //Este handler se ejecuta hasta que se pulse back, momento en el que se elimina y se vuelve a colocar el primero
    const onInput = makeKeyboardEvent(['up', "down", "left", "right", "back", "enter", 0, 1, 2, 3, 4, 5, 6, 7, 8, 9], (key) => {
        if (typeof key === "number")
            signal.value = Number(signal.value.toString(10) + key);
        if (key === 'up' && signal.peek() !== max) {
            signal.value++;
        }
        else if (key === "down" && signal.peek() !== min)
            signal.value--;
        else if (key === 'back' || key === 'enter') {
            isFocused.value = false;
            inputRef.current?.removeEventListener('keydown', onInput);
            inputRef.current?.addEventListener('keydown', beforeLock);
        }
        return { preventDefault: true, stopPropagation: key === 'up' || key === 'down' };
    });

    useEffect(() => {
        inputRef.current?.addEventListener('keydown', beforeLock);
        return () => {
            inputRef?.current?.removeEventListener('keydown', beforeLock);
            inputRef?.current?.removeEventListener('keydown', onInput);
        }
    }, []);

    return (
        <div className={className} tabIndex={0} style={{ position: 'relative', display: 'inline-block', ...style }}>
            <input className={'navigable'} disabled={disabled} readOnly id={id} value={signal.value} ref={inputRef} defaultValue={signal.peek().toString(10)} style={{
                top: 0,
                left: 0,
                textAlign: 'center',
                border: 0,
                position: 'absolute',
                width: '100%',
                height: '100%',
                fontSize: style?.fontSize || 'unset',
                color: style?.color || 'unset',
                borderRadius: style.borderRadius
            }} />
            {isFocused.value ? (
                <>
                    <div className="icon-chevron-up" style={{ position: 'absolute', fontSize: style.fontSize || 'inherit', top: `-1.2em`, left: 'calc(50% - 0.5em)' }}></div>
                    <div className="icon-chevron" style={{ position: 'absolute', fontSize: style.fontSize || 'inherit', bottom: `-1.2em`, left: 'calc(50% - 0.5em)' }}></div>
                </>
            ) : null}
        </div>
    );
}) as FC<{ signal: React.Signal<number>, className?: string, style?: React.CSSProperties, id?: string, min?: number, max?: number, disabled?: boolean }>;

