/* eslint-disable no-undef */
import { HOME, PATHS, TV_MODELS } from "../../../../utils/constants";
import { EVENTS } from "../../../../utils/eventsConst";
import { prepareDeviceKeyMap } from "../../../../utils/utils";
import { Media } from "../../media";
import { keyMap } from "./keyMap";

export const STB = {
    model: TV_MODELS.TIZEN,
    //videoOnBack: true,
    ref: ``,
    isTizen: true,
    needCanvas: true,
    replayChannelOnAlarm: true,
    hideSubtitlesOnMenu: true,
    tvName: ``,
    init() {
        const communicationiFrame = document.createElement("iframe");
        communicationiFrame.id = "tizenCommunication";
        communicationiFrame.style.visibility = "hidden";
        communicationiFrame.src = "file:///tizenHAcomm.html";
        document.body.appendChild(communicationiFrame);

        this.keyMap = prepareDeviceKeyMap(keyMap);
        samsung.init();

        this.ip = webapis.network.getIp();
        //get model and Firmware
        localStorage.setItem("tvModel", samsung.getModel());
        localStorage.setItem("firmwareVersion", samsung.getFirmware());

        if (!samsung?.TEP) {
            // Replay for [Tizen - H.Browser]
            try {
                b2bapis.b2bpower.setPowerStateChangeListener(this.onPowerChange);
            } catch (e) {
                console.debug(`ERROR setting power state listener ${e}`);
                b2bapis.b2bpower.unsetPowerStateChangeListener();
                b2bapis.b2bpower.setPowerStateChangeListener(this.onPowerChange);
            }
        }
        samsung.hideSource();
        samsung.launchSource("TV", 1, function () {});

        document.addEventListener("visibilitychange", function () {
            if (document.hidden) {
                console.log("INTERFACE VISIBILITY CHANGE TO HIDDEN");
            } else {
                console.log("INTERFACE VISIBILITY CHANGE TO VISIBLE");
                if (sessionStorage.getItem("onCCView")) {
                    sessionStorage.removeItem("onCCView");
                    let e = new CustomEvent(EVENTS.type.NAVIGATION, {
                        detail: { layout: HOME },
                    });
                    document.dispatchEvent(e);
                }
                if (samsung.TEP) {
                    // Replay for [Tizen - TEP]
                    this.onPowerChange();
                }
            }
        });
    },

    onPowerChange(data) {
        console.log(`[PowerStateChangedCallback] progress :${JSON.stringify(data)} changed`);
        if (data && data.data == "Standby") {
        } else {
            //Media.ensureVideoSize();
            Media.replayCurrentVideo();
        }
    },
    getMac(onSuccess) {
        this.mac = samsung.getMacAddress();
        this.ref = `${this.model}-${this.mac}`;
        if (onSuccess) {
            onSuccess();
        }
    },
    powerOn() {
        samsung.setPowerOn();
    },
    powerOff() {
        samsung.setPowerOff();
    },
    updatePowerState() {
        let status = samsung.getPowerState();
        localStorage.setItem("powerState", status == "Normal" ? "online" : "standby");
    },
    gotoOfflineMode() {
        document.location.href = "file:///js/ha/ha.html";
    },
    setVolume(volume) {
        samsung.setVolume(volume);
    },
    changeToInput(input) {
        Media.setVideoSize(0, 0, 100, 100);
        var successCB = function (source, type) {
            tizen.application.launch("org.tizen.tv-viewer", null); // launch HDMI window forcefully
            //            sessionStorage.setItem("outOfInterface", true);
        };
        samsung.launchSource("HDMI", input, successCB);
    },
    backToInterface() {
        samsung.hideSource();
        sessionStorage.setItem("outOfInterface", false);
        samsung.launchSource("TV", 1, () => {});
        document.body.style.visibility = "hidden";
        this.reload();
    },
    reload() {
        document.location.href = PATHS.TIZEN_ROOT;
    },
    saveDataForHA(localKey, dataToStore) {
        //send message to iframe
        var win = document.getElementById("tizenCommunication").contentWindow;
        if (win) {
            win.postMessage(JSON.stringify({ key: localKey, data: dataToStore }), "*");
        }
    },
    changeTVName(name) {
        this.tvName = "Samsung " + name;
        webapis.network.setTVName(this.tvName);
    },
};
