import { useSelector } from "react-redux";
import { InitializePC } from "./pc/initializePC";
import { InitializeProcentric } from "./procentric/initializeProcentric";
import { InitializeTizen } from "./samsung/tizen/initializeTizen";
import { InitializeOrsay } from "./samsung/orsay/initializeOrsay";
import { InitializePhilips } from "./philips/initializePhilips";
import { InitializeSTB } from "./stb/initializeSTB";
import { InitializeGoogleTV } from "./googleTV/initializeGoogleTV";

const InitializeAPI = () => {
    console.log("inicializo API");
    const model = useSelector((state) => state.device.model);

    switch (model) {
        case "Samsung-Tizen":
            InitializeTizen();
            break;
        case "Samsung-Orsay":
            InitializeOrsay();
            break;
        case "LG":
            InitializeProcentric();
            break;
        case "Philips":
            InitializePhilips();
            break;
        case "STB":
            InitializeSTB();
            break;
        case "GoogleTV":
            InitializeGoogleTV();
            break;
        default:
            InitializePC();
            break;
    }
};

export default InitializeAPI;
