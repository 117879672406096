import { memo, useEffect } from "preact/compat";
import { printCenteredMessage, setFocusOnGlobalLimbo } from "../utils/utils";
import "../assets/styles/ccinstructions";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { MENU_TYPES, MESSAGES_STYLES } from "../utils/constants";
import { setMenuTemporaryType } from "../actions/uiActions";

const Page404 = () => {
    const texts = useSelector((state) => state.ui.texts);
    const dispatch = useDispatch();

    useEffect(() => {
        setFocusOnGlobalLimbo();
        dispatch(setMenuTemporaryType(MENU_TYPES.HIDDEN));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{ fontFamily: "Lato", fontWeight: "bold" }}>
            {printCenteredMessage(
                ReactHtmlParser(
                    "<span style='font-size:5vw;'> 404</span><br><br><span style='font-size:2vw;'>Page not found</span>",
                ),
                MESSAGES_STYLES.OVERLAY,
            )}
        </div>
    );
};

export default memo(Page404);
