import { Details } from "sales-layout-library/tv";
import { Signal, useSignal } from "@preact/signals";
import { carrito } from "../signals/carrito";
import { Checkbox, Number } from "../../reusable/inputs/index";
import { DEFAULT_STYLEHSEET } from "../../../utils/stylesheet";
import api from "../api";
import { figmapx2vh, figmapx2vw } from "../../../utils/utils";
import { Navigable } from "../../reusable/navigable";
import { Link } from "wouter/preact";
import focus from "../../../utils/focus";
import { Autoscrollable } from "../../reusable/autoscrollable";
import { useDispatch } from "react-redux";
import { displayErrorPopUp, setPopUp } from "../../../actions/uiActions";
import { SHA1 } from "../../../utils/hash";
import { navigate } from "wouter/use-location";
import storage from "../../../utils/storage";
import ExtraConfigurator from "../extraConfigurator";
import Modal from "../../common/modal";
import VerticalList from "../../reusable/verticalList";
import { useEffect } from "react";
import { eventHandler } from "../../../utils/eventHandler";
import { KEYS } from "../../../utils/keys";


type FormType = {
    quantity: Signal<number>
    extraConfig: Signal<Record<number, Sales.Extras.Config>>
}

type DetailsProps = Parameters<typeof Details>[0];

export default ({ channel, variant, extras, templating, inEdition }: { channel: Awaited<ReturnType<typeof api.shopByToken>>, extras: Record<string, ReturnType<typeof api.extra>> | [], variant: Awaited<ReturnType<typeof api.variant>>, templating: Parameters<typeof Details>[0]["templating"] , inEdition: CartItem}) => {
    const dispatch = useDispatch();
    const configurator = new ExtraConfigurator({});

    const form: FormType = {
        quantity: useSignal<number>(inEdition?.quantity || 1),
        extraConfig: useSignal<Record<number, Sales.Extras.Config>>({})
    }

    const allergensOnClick = () => {
        dispatch(setPopUp("allergens"));
    }

    const onAddToCart = () => {
        let quantity = form.quantity.peek();
        // si se usa addToCart no se está guardando los datos en el localstorage
        //        addToCart({variant: variant.id,token: channel.token, config: form.extraConfig.peek(),quantity});
        const timestamp = SHA1( variant.id + ';' + JSON.stringify(form.extraConfig.peek()));

        let addEntry = true;

        const checkMaxProductCount =()=>{
            //check PRODUCT count in order
            // @ts-ignore
            if(variant.maxUnits !== 0 && (carrito.value?.[channel.token]?.reduce((acc, item) => acc + (item.variant === variant.id && !inEdition?item.quantity:0), 0) || 0) + quantity > variant.maxUnits){            
                dispatch(displayErrorPopUp({text: templating.texts["max-product-in-order"], timeout:3000}));
                return true;
            }
            return false
        }
        const checkMaxProductsCount =()=>{
            //check PRODUCTS count in order
            // @ts-ignore
            if(channel?.customFields?.is_max_product_per_order_active && channel?.customFields?.max_products_per_order !== 0 && (carrito.value?.[channel.token]?.reduce((acc, item) => acc + (item.variant === variant.id && inEdition?0:item.quantity), 0) || 0) + quantity > channel?.customFields?.max_products_per_order){            
                dispatch(displayErrorPopUp({text: templating.texts["max-products-in-order"], timeout:3000}));
                return true;
            }
        return false
    }
    const incompleteExtras =()=>{
        //check extra required
        let incompleExtras = false;
        //@ts-ignore
        Object.values(extras).forEach( extra => extra.options?.min && (!form.extraConfig.value?.[extra.id] || (form?.extraConfig?.value?.[extra.id].length < extra.options?.min)) ? incompleExtras=true: null);
        if(incompleExtras){
            dispatch(displayErrorPopUp({text: templating.texts["extra-required"], timeout:3000}));
            return true;
        }
        return incompleExtras;

    }
    if(checkMaxProductCount() || checkMaxProductsCount() || incompleteExtras()){
        return;
    }

        if(inEdition){
            //if product config doesn't change => just update quantity
            if(inEdition.timestamp === timestamp){
                addEntry=false;
                if(checkMaxProductCount() || checkMaxProductsCount() || incompleteExtras()){
                    return;
                }
                carrito.value?.[channel.token]?.filter(item => item.variant === variant.id && item.timestamp === timestamp && (item.quantity = quantity));
            }else{
                //delete entry from cart and check if exists same product config in cart to update its quantity. Otherwise , add a new cart entry
                let posToDelete = 0;
                carrito.value?.[channel.token]?.forEach((item,idx) => {item.timestamp === inEdition.timestamp && (posToDelete = idx) });                 
                carrito.value?.[channel.token]?.splice(posToDelete,1);
            }
        }

        if(addEntry){
            //if same product is already in cart => update quantity
            const sameProductInCart = carrito.value?.[channel.token]?.filter(item => item.variant === variant.id && item.timestamp === timestamp && (item.quantity = item.quantity+ quantity));
            if(!sameProductInCart || sameProductInCart.length<1){
                //add item to cart
                carrito.value = { ...carrito.value, [channel.token]: [...(carrito.value[channel.token] ?? []), { variant: variant.id, config: form.extraConfig.peek(), quantity, timestamp, productId: variant.productId }] };
            }        
        }

        //force carrito update
        carrito.value = {...carrito.value};
        
        //Reset form
        form.quantity.value = 1;
        form.extraConfig.value = {};
        sessionStorage.setItem("resetExtras", new Date().getTime().toString());
        if (document.getElementById("cart-button")) {
            //@ts-ignore
            document.getElementById("cart-button").style.animation = 'heartBeat 0.8s';
        }
        setTimeout(function () {
            if (document.getElementById("cart-button")) {
                //@ts-ignore
                document.getElementById("cart-button").style.animation = '';
            }
        }, 800);

        //if editing => navigate to cart
        if(inEdition){
            eventHandler.skipNextPath(); //this should be added if replace is trye in navigate options
            navigate(`/roomshops/${storage.get("orderToken",'0')}/cart`, { replace: true });
        }
    }
    const props: DetailsProps = {
        data: {
            shop: {
                name: channel.name,
                token: channel.token,
                pricesIncludeTax: channel.pricesIncludeTax
            },
            product: {
                id: variant.productId,
                assets: variant.assets,
                allergens: variant.allergens,
                tags: variant.tags,
                extras: variant.extras,
                variant: variant.id,
                name: variant.name,
                translations: variant.translations,
                description: variant.description,
                featuredAsset: variant.featuredAsset ?? variant.assets[0],
                price: variant.price.withTax,
                priceWithoutTax: variant.price.withoutTax,
                currencyCode: variant.price.currencyCode
            } as any,
            signals: {
                cart: carrito,
                amount: form.quantity,
            },
            extras: extras as any
        },
        signals: {
            focus,
            amount: form.quantity,
            config: form.extraConfig,
            cart: carrito as Signal<Record<string, {variant: number; config: Record<number, any>; quantity: number;}[]>>,
        },
        form,
        templating,
        inEdition,
        injected: {
            Autoscrollable,
            configurator,
            NumberInput: <Number id={'quantity'} signal={form.quantity} min={1} max={variant.maxUnits} className="sales-buttons" style={{
                height: `${figmapx2vh(56)}vh`,
                width: `${figmapx2vw(60)}vw`,
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: `${figmapx2vh(24)}vh`,
                marginBottom: `${figmapx2vh(20)}vh`,
                fontSize: `${figmapx2vw(24)}vw`,
                borderStyle: 'solid',
                borderWidth: '1px',
                caretColor: 'transparent',
                borderRadius: `${figmapx2vw(4)}vw`,
                ...DEFAULT_STYLEHSEET.productDetails.default('buttons')
            }} />,
            VerticalList: VerticalList as any,
            Modal: Modal as any,
            NumberInputComponent: Number,
            CheckboxInputComponent: Checkbox,
            onAddToCart,
            allergensOnClick,
            Navigable,
            Link
        },
    };

    useEffect(() => {    
      return () => {
        sessionStorage.removeItem("resetExtras");
      }
    }, []);
    
    const onkeydown = (e:any) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;
        if (keyData?.value === KEYS.back && inEdition) {
            eventHandler.skipNextPath(); //this should be added if replace is trye in navigate options
            navigate(`/roomshops/${storage.get("orderToken",'0')}/cart`, { replace: true });
            preventDefault=true;
        }
        if (preventDefault) {
            e.stopPropagation();
        }
    };
    
    return (
        <div
            onKeyDown={(e) => {
                onkeydown(e);
            }}
        >
        <Details {...props} />
        </div>
    );
}