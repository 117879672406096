/* eslint-disable no-undef */
import { PATHS } from "../../../utils/constants";
import { getLangName, vh2px, vw2px, writeLog } from "../../../utils/utils";
import { philips } from "./philips";

export const Media = {
    init() {
        this.stop();
    },
    _stop() {
        philips.stopChannel();
        philips.removeVideoPane();
    },

    setVideoSize(x, y, width, height) {
        x = vw2px(x);
        y = vh2px(y);
        width = vw2px(width);
        height = vh2px(height);

        philips.setVideoPaneSize(x, y, width, height);
    },
    _playChannel(ip, port) {
        this._stop();
        philips.changeChannelIp(ip, port);
    },
    removeVideoPane() {
        philips.removeVideoPane();
    },
    getAudioList(onSuccess) {
        const onListAction = (list) => {
            if (!list) {
                onSuccess(null);
                return;
            }
            let audioList = [];
            const audios = list.AudioLanguageList;
            audios.map((audio, index) => audioList.push({ id: index, name: getLangName(audio.Language) }));
            onSuccess(audioList);
        };

        philips.getAudioTracks(onListAction);
    },
    getSubtitleList(onSuccess) {
        const onListAction = (list) => {
            if (!list) {
                onSuccess(null);
                return;
            }
            let subtitleList = [];
            const subtitles = list.SubtitleLanguageList;
            subtitles.map((subtitle, index) =>
                subtitleList.push({
                    id: index,
                    name: getLangName(subtitle.Language),
                }),
            );

            onSuccess(subtitleList);
        };

        philips.getSubtitles(onListAction);
    },
    changeAudio(ind) {
        philips.setAudioTrack(Number(ind));
    },
    changeSubtitle(ind) {
        philips.setSubtitles("On", Number(ind));
    },
    disableSubtitles() {
        philips.setSubtitles("Off", 0);
    },

    _playHLSVideo(url) {
        //url = "https://channel01.akamaized.net/hls/live/2022749/event01/index.m3u8";
        //url = "https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8";
        //url = "https://zafiro.cloud/das/stream/video-ec7531be-4b2e-45aa-961a-cc343576e5cc-636861696e2d303030302d303030302d303030302d30303039-en_hd.m3u8";

        writeLog(`Would play HLS: ${url}`);
        try {
            if (philips.VIDEOPANE) {
                philips.removeVideoPane();
            }

            const videoSize = {
                x: window.location.pathname === PATHS.TV ? window.screen.width : 1,
                y: window.location.pathname === PATHS.TV ? window.screen.height : 1,
            };

            philips.createVideoPane("application/vnd.apple.mpegurl", 0, 0, videoSize.x, videoSize.y, 100);
            philips.VIDEOPANE.src = url;
            philips.showVideoPane();
            philips.VIDEOPANE.play();

            philips.VIDEOPANE.addEventListener("ended", (resp) => {
                let e = new Events.Custom("media", Media.MEDIA_STATUS_END_OF_STREAM, "");
                Events.dispatchEvent(e);
            });

            philips.VIDEOPANE.addEventListener("playing", () => {
                //set movie language
                writeLog("Playing video ----" + philips.VIDEOPANE.style.width);
            });
        } catch (e) {
            console.error(`Error playing VoD: ${e.message}`, e);
        }
    },
    _playDVBTChannel: function (ch) {
        console.debug("DVBT channel");
        philips.changeChannelRf(ch.frequency, ch.isc);
    },
};
