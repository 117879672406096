// widgets
export const MENU_CONTAINER = "TVMENU" as const;
//widgets
export const CONTAINER_WIDGET = "CONTAINER" as const;
export const MENUNAV_WIDGET = "TVMENUNAV" as const;
export const ALARM_WIDGET = "ALARM" as const;
export const MESSAGES_WIDGET = "MESSAGES" as const;
export const MENU_TYPES = { NONE: "none", FIXED: "fixed-menu", HIDDEN: "hidden-menu" } as const;
export const NO_RENDERED_WIDGETS = ["TOPBAR", "CHATBUTTON", "ROOMS", MENU_CONTAINER] as const;
export const EXTRAINFO_WIDGETS = ["VIDEO", "ALARM", "CLOCK", "MESSAGES", "BUTTON"] as const;
export const FOCUS_WIDGET_WITH_NO_ACTION = ["ALARM", "MESSAGES", "TVMENUNAV", "VIDEO", "LANGSELECTION"] as const;
// sceens
export const HOME = "HOME" as const;
export const SCREEN = "screen" as const;

export const ANIMATION_TIMEOUT = 400; //ms

export const NO_MENU_SCREENS = [
    "tv",
    "ccinstruction-screen",
    "checkoutsuccess",
    "chromecast",
    "library",
    "tvchannel",
    "roomshops",
] as const;
export const MESSAGES_STYLES = { OVERLAY: "overlay" } as const;

export const POP_UP = {
    MESSAGES: "messages",
    ALARM: "alarm",
    BILL: "bill",
    CHECKOUT: "checkout",
    LANGSELECTION: "languageSelection",
    PARENTALCODE: "parentalcode",
    INPUTS: "inputs",
    //@ts-ignore
    list() {
        let _valuesList = [];
        for (const element of Object.keys(this)) {
            const iterator = element as keyof typeof POP_UP;
            _valuesList.push(this[iterator] as string);
        }
        return _valuesList;
    },
} as const;

export const ZAFIRO_MODES = {
    FULL_INTERFACE: "FULL_INTERFACE",
    TV: "TV",
};

export const WIDGETS = {
    VIDEO: "VIDEO",
};
export const PATHS = {
    NOLANDING: "/noLanding",
    TV: "/tv",
    TVCHANNEL: "/tvchannel/:channelId",
    CCINSTRUCTIONS: "/ccinstruction-screen",
    CHECKOUTSUCCESS: "/checkoutsuccess",
    LIBRARY: "/library",
    ROOMSHOPS: "/roomshops",
    ROOMSHOP: "/roomshop/:roomshop",
    CATEGORIES: "/roomshops/:roomshop/categories",
    SUBCATEGORIES: "/roomshops/:roomshop/categories/:category",
    SUBCATEGORY: "/roomshops/:roomshop/categories/:category/subcategory/:subcategory",
    PRODUCT: "/roomshops/:roomshop/products/:productId/variant/:variantId/:timestamp?",
    CART: "/roomshops/:roomshop/cart",
    SCREEN: "/screen/:rest*",
    ROOT: "/",
    HOME: "/",
    TIZEN_ROOT: "/index.html",
    SCREENS: "/screen/",
    MYORDERS: "/myorders",
    MYORDERDETAIL: "/myorders/:order",
} as const;

export const PATHS_WITH_NO_MENU = {
    list: [PATHS.TV, PATHS.ROOMSHOPS, PATHS.LIBRARY, PATHS.CCINSTRUCTIONS, PATHS.CHECKOUTSUCCESS, PATHS.MYORDERS],
    pathIncludes: (path: string) => {
        if (!path || typeof path === "undefined") {
            return false;
        }
        let _result = false;
        for (const element of PATHS_WITH_NO_MENU.list) {
            const iterator = element as keyof typeof PATHS_WITH_NO_MENU;
            if (iterator.indexOf(path) > -1) {
                _result = true;
            }
        }
        return _result;
    },
} as const;

export const CHECK_ONLINE_PATH = "https://drive.zafiro.cloud/favicon.ico";

export const DEFAULT_ACTIVE_MENU_HISTORY = [{ menuFocus: null, path: "/" }];

export const TIME = {
    ONE_HOUR_IN_MS: 3_600_000,
    ONE_MIN_IN_MS: 60000,
    ONE_DAY_IN_MS: 86400000,
} as const;

export const CHANNELS = {
    FILTERS: {
        ALL_CHANNELS: "all_channels",
        ALL: "all",
        MY_LIST: "my_list",
        COUNTRY: "country",
        LANGUAGE: "language",
    },
    TV_STATES: {
        LIMBO: "limbo",
        CHANNEL_LIST: "channel_list",
        CHANNEL_INFO: "channel_info",
        CHANNEL_DISPLAY: "channel_display",
    },
};
export const TV_MODELS = {
    TIZEN: "Samsung-Tizen",
    ORSAY: "Samsung-Orsay",
    LG: "LG",
    PHILIPS: "Philips",
    PHILIPS_NO_CC: "Philips-NoCC",
    GOOGLE_TV: "GoogleTV",
    STB: "STB",
    PC: "PC",
};
export const PMS = {};

// 25-04-2024
// Available list of permissions coming from back: [
//     highAvailability,
//     newChannels
// ]
export const PERMISSION = {
    HIGH_AVAILABILITY: "highAvailability",
    NEW_CHANNELS: "newChannels",
    APPS: "apps",
};

export const STATE = {
    ONLINE: "online",
    OFFLINE: "offline",
};
