/* eslint-disable radix */
import { useEffect, useRef, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import Screen from "./screen";
import Page404 from "./404";
import {
    displayMenu,
    setBackground,
    setLandingBackground,
    setMenuTemporaryType,
    setPopUp,
    setVideoPos,
} from "../actions/uiActions";
import { Navigable } from "./reusable/navigable";
import { CSSTransition } from "react-transition-group";
import CcInstructions from "./ccInstructions";
import { SCREEN, MENU_TYPES, ANIMATION_TIMEOUT, PATHS, PATHS_WITH_NO_MENU, MESSAGES_STYLES } from "../utils/constants";
import LibraryContent from "./libraryContent";
import { Roomshops, Categories, Subcategories, Subcategory, MyOrders, MyOrderDetail } from "sales-layout-library/tv";
import { Route, Switch, Link, useLocation } from "wouter/preact";
import { navigate } from "wouter/use-location";
import HorizontalList from "./reusable/horizontalList";
import Asyncable from "./reusable/asyncable";
import { Autoscrollable } from "./reusable/autoscrollable";
import api from "./sales/api";
import focus from "../utils/focus";
import getScreen from "../hooks/GraphqlCalls/getScreen";
import getCCInstructions from "../hooks/GraphqlCalls/getCCInstructions";
import DetailsWrapper from "./sales/details/DetailsWrapper";
import CartWrapper from "./sales/Cart/CartWrapper";
import { carrito } from "./sales/signals/carrito";
import dbStylesToStylesheet from "../utils/dbStylesToStylesheet";
import { Redirect } from "wouter";
import NoLanding from "../layouts/noLanding";
import { salesContentEmpty, screenUtils } from "../utils/screenUtils";
import Loading from "./loading";
import { resetVendureCache, vendureCacheCleanup } from "../hooks/query";
import BackgroundImage from "./reusable/backgroundImage";
import { inHomePage, printCenteredMessage } from "../utils/utils";
import storage from "../utils/storage";
import ReactHtmlParser from "react-html-parser";
import { eventHandler } from "../utils/eventHandler";
import Checkoutsuccess from "../layouts/checkoutsuccess";
import Tv from "../layouts/tv/tv";
import VerticalList from "./reusable/verticalList";
import ConnectionError from "./connectionError";
import NoContent from "./NoContent";

const Content = () => {
    const dispatch = useDispatch();
    const [location] = useLocation();
    //Store
    const landingPage = useSelector((state) => state.ui.landingPage);
    const menu = useSelector((state) => state.ui.menu);
    const texts = useSelector((state) => state.ui.texts);
    //States
    const [screenData, setScreenData] = useState();
    const [error, setError] = useState();
    const [loadingContent, showLoadingContent] = useState();
    const tags = useSelector((state) => state.status.locationData.tags);
    const projectLanguageCode = useSelector(
        (state) => state.status.locationData.projectLangs.find((x) => x.isDefault === true).languageRef,
    );
    const loadingStyle = {
        color: "white",
        fontSize: "1.87305vw",
        textShadow: "1px 1px black",
        zIndex: 100,
    };
    const preventFocusOnHome = useRef(false);
    let loadingTimeout = useRef();
    const MAX_LOADING_TIME = 20000;

    useEffect(() => {
        //cache to sessionStorage current shops
        Promise.all([api.shops()]).then(([shops]) => {
            sessionStorage.setItem("shops", JSON.stringify(shops));
            const allShopsTokens = shops.map((item) => {
                return item.token;
            });
            sessionStorage.setItem("allShopsTokens", JSON.stringify(allShopsTokens));
            showLoadingContent(false);
        });
    }, []);

    //Listeners
    //if not current Layout request landing page

    const hideMenuInLayout = () => {
        if (menu.temporaryType !== MENU_TYPES.HIDDEN) {
            dispatch(setMenuTemporaryType(MENU_TYPES.HIDDEN));
            dispatch(displayMenu(false));
        }
    };
    const showMenuInLayout = () => {
        dispatch(setMenuTemporaryType(null));
        if (menu?.type === MENU_TYPES.FIXED) {
            dispatch(displayMenu(true));
        }
    };
    // SET screenData + menuTemporaryType by location
    useEffect(() => {
        eventHandler.locationStack(location);
        setError(null);
        if (location?.indexOf("/roomshop") > -1 || location?.indexOf("/screen") > -1) {
            if (location?.indexOf("/roomshop") > -1) {
                if (
                    location.indexOf("categories/") > -1 &&
                    eventHandler.previousPath?.indexOf("categories/") > -1 /* ||
                    eventHandler.previousPath?.indexOf("/cart") > -1 */
                ) {
                    //DO Nothing
                } else if (sessionStorage.getItem("unstackOnLoad")) {
                    sessionStorage.removeItem("unstackOnLoad");
                    focus.value.unstack();
                } else if (eventHandler.previousPath?.indexOf("/cart") === -1) {
                    focus.value.stack();
                }
            }
            showLoadingContent(true);
        }
        preventFocusOnHome.current =
            (!eventHandler.previousPath || eventHandler.previousPath === PATHS.TIZEN_ROOT) &&
            menu?.type === MENU_TYPES.FIXED &&
            inHomePage();

        setScreenData(null);
        const hideMenuByPath =
            location.split("/")?.[1] && location.split("/")[1] !== ""
                ? PATHS_WITH_NO_MENU.pathIncludes("/" + location.split("/")[1])
                : null;

        if (hideMenuByPath) {
            hideMenuInLayout();
        }
        if (location === PATHS.ROOT || location === PATHS.TIZEN_ROOT) {
            //This timeout ensure that previous content is removed. Otherwise I get text from previous screen
            setScreenData({ ...landingPage, renderType: SCREEN });
            showMenuInLayout();
        } else if (location === PATHS.CCINSTRUCTIONS) {
            showLoadingContent(true);
            getCCInstructions((data) => {
                setScreenData(data);
            });
        } else if (location.indexOf(PATHS.SCREENS) > -1) {
            const screenID = location.replace(PATHS.SCREENS, "");
            getScreen(screenID, landingPage.designID, (data) => {
                setScreenData(data);
                const contentStyle = JSON.parse(data.contentStyle);
                if (menu && menu.type === MENU_TYPES.FIXED && !contentStyle.showHomeMenu) {
                    hideMenuInLayout();
                } else {
                    showMenuInLayout();
                }
            });
        } else if (
            (location?.indexOf("roomshops") > -1 || location?.indexOf("myorders") > -1) &&
            sessionStorage.getItem("salesBg")
        ) {
            const salesBg = JSON.parse(sessionStorage.getItem("salesBg"));
            dispatch(setBackground(salesBg));
        } else {
            dispatch(setLandingBackground());
        }
        eventHandler.setPreviousPath(location);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        clearTimeout(loadingTimeout.current);
        if (loadingContent) {
            loadingTimeout.current = setTimeout(() => {
                showLoadingContent(false);
                setError(
                    "<div>There is no content for this screen</div><div class='text-xl mt-4'>Press BACK or HOME  to continue</div>",
                );
                focus.value.replace("noFocus");
            }, MAX_LOADING_TIME);
        }
    }, [loadingContent]);

    useEffect(() => {
        screenData && showLoadingContent(false);
    }, [screenData]);

    const onLayoutExit = () => {
        dispatch(setVideoPos(null));
    };

    const onPromiseVendureError = () => {
        vendureCacheCleanup();
    };

    const allShopsTokens = sessionStorage.getItem("allShopsTokens")
        ? JSON.parse(sessionStorage.getItem("allShopsTokens")).join(",")
        : "";

    const salesNoContentComponent = (style, keytext) => {
        return (
            <NoContent
                text={texts[keytext]}
                style={{
                    color: style?.section3?.colors?.fgColor,
                    backgroundColor: style?.section3?.colors?.bgColor,
                }}
            />
        );
    };

    return (
        <>
            {error ? (
                <div style={{ fontFamily: "Lato", fontWeight: "bold" }}>
                    {printCenteredMessage(ReactHtmlParser(error), MESSAGES_STYLES.OVERLAY)}
                </div>
            ) : null}
            {loadingContent ? (
                <div className="fixed w-full h-full z-100">
                    <Loading style={loadingStyle} />
                </div>
            ) : null}
            <ConnectionError />
            <Switch>
                <Route path={PATHS.NOLANDING}>
                    <NoLanding />
                </Route>
                <Route path={PATHS.TIZEN_ROOT}>{(params) => <Redirect to={`/`} replace={true}></Redirect>}</Route>
                <Route path={PATHS.ROOT}>
                    <>
                        <CSSTransition
                            in={!!screenData}
                            timeout={ANIMATION_TIMEOUT}
                            classNames="fadeInAnimation" // fadeInAnimation , topAnimation, leftAnimation
                            unmountOnExit
                            onExit={onLayoutExit}
                        >
                            <Screen data={screenData} preventFocus={preventFocusOnHome.current} />
                        </CSSTransition>
                    </>
                </Route>
                <Route path={PATHS.ROOMSHOPS}>
                    {() => (
                        <Asyncable>
                            {Promise.all([api.shops(showLoadingContent), api.design(landingPage.designID)])
                                .then(([shops, design]) => {
                                    showLoadingContent(false);
                                    if (shops.length === 0) {
                                        return salesNoContentComponent(design?.style, "shop not availabe");
                                    }
                                    if (shops?.length === 1) {
                                        eventHandler.skipNextPath(); //this should be added if replace is trye in navigate options
                                        navigate(`/roomshops/${shops[0].token}/categories`, { replace: true });
                                        return;
                                    }
                                    return (
                                        <div id="sales">
                                            <Roomshops
                                                data={shops}
                                                templating={{
                                                    css: dbStylesToStylesheet(design?.style),
                                                    strings: {
                                                        welcomeMessage: screenUtils.replaceSpecialTags(
                                                            design?.title?.text ?? "",
                                                            tags,
                                                        ),
                                                        showMyOrders: design?.ordersButton,
                                                        welcomeTextAlignment: design?.textAlignment,
                                                    },
                                                    texts,
                                                    languageCode: storage.get("lang", "en"),
                                                    projectLanguageCode,
                                                }}
                                                signals={{ focus, cart: carrito.value ?? [] }}
                                                injected={{
                                                    Link,
                                                    List: HorizontalList,
                                                    BackgroundImage,
                                                }}
                                            />
                                        </div>
                                    );
                                })
                                .catch((e) => {
                                    onPromiseVendureError();
                                })}
                        </Asyncable>
                    )}
                </Route>
                <Route path={PATHS.CATEGORIES}>
                    {(params) => {
                        return (
                            <Asyncable>
                                {Promise.all([
                                    api.categories(params.roomshop),
                                    api.shopByToken(decodeURIComponent(params.roomshop)),
                                    api.design(landingPage.designID),
                                ])
                                    .then(([categories, channel, design]) => {
                                        showLoadingContent(false);
                                        if (salesContentEmpty(channel, categories, null)) {
                                            return salesNoContentComponent(design?.style, "shop not availabe");
                                        }
                                        sessionStorage.setItem("roomshopId", channel?.id);
                                        if (categories?.length === 1) {
                                            eventHandler.skipNextPath(); //this should be added if replace is trye in navigate options
                                            navigate(`/roomshops/${params.roomshop}/categories/${categories[0].id}`, {
                                                replace: true,
                                            });
                                            return;
                                        }
                                        return (
                                            <div id="sales">
                                                <Categories
                                                    data={{
                                                        channel,
                                                        categories,
                                                        cart: carrito.value[params.roomshop] ?? [],
                                                    }}
                                                    templating={{
                                                        css: dbStylesToStylesheet(design?.style),
                                                        texts,
                                                        languageCode: storage.get("lang", "en"),
                                                        projectLanguageCode,
                                                    }}
                                                    signals={{ focus }}
                                                    injected={{
                                                        Link,
                                                        List: HorizontalList,
                                                        BackgroundImage,
                                                    }}
                                                />
                                            </div>
                                        );
                                    })
                                    .catch((e) => {
                                        onPromiseVendureError();
                                    })}
                            </Asyncable>
                        );
                    }}
                </Route>
                <Route path={PATHS.SUBCATEGORIES}>
                    {(params) => {
                        return (
                            <Asyncable>
                                {Promise.all([
                                    api.shopByToken(params.roomshop),
                                    api.categories(decodeURIComponent(params.roomshop)),
                                    api.subcategories({
                                        channel: params.roomshop,
                                        parent_id: params.category,
                                    }),
                                    api.design(landingPage.designID),
                                ])
                                    .then(([channel, categories, subcategories, design]) => {
                                        showLoadingContent(false);
                                        if (salesContentEmpty(channel, categories, subcategories)) {
                                            return salesNoContentComponent(design?.style, "category not available");
                                        }
                                        if (subcategories.length === 1) {
                                            return (
                                                <div id="sales">
                                                    <Subcategory
                                                        data={{
                                                            subcategory: subcategories[0],
                                                            channel,
                                                            categories,
                                                            cart: carrito.value[params.roomshop] ?? [],
                                                            activeCategory: params.category,
                                                            subcategories,
                                                        }}
                                                        signals={{
                                                            focus,
                                                        }}
                                                        templating={{
                                                            css: dbStylesToStylesheet(design?.style),
                                                            texts,
                                                            languageCode: storage.get("lang", "en"),
                                                            projectLanguageCode,
                                                        }}
                                                        injected={{
                                                            Navigable,
                                                            Link,
                                                            navigate,
                                                            List: HorizontalList,
                                                            BackgroundImage,
                                                        }}
                                                    />
                                                </div>
                                            );
                                        }
                                        return (
                                            <div id="sales">
                                                <Subcategories
                                                    data={{
                                                        channel,
                                                        categories,
                                                        cart: carrito.value[params.roomshop] ?? [],
                                                        activeCategory: params.category,
                                                        subcategories,
                                                    }}
                                                    signals={{
                                                        focus,
                                                    }}
                                                    templating={{
                                                        css: dbStylesToStylesheet(design?.style),
                                                        texts,
                                                        languageCode: storage.get("lang", "en"),
                                                        projectLanguageCode,
                                                    }}
                                                    injected={{
                                                        Navigable,
                                                        Link,
                                                        navigate,
                                                        List: HorizontalList,
                                                        BackgroundImage,
                                                    }}
                                                />
                                            </div>
                                        );
                                    })
                                    .catch((e) => {
                                        onPromiseVendureError();
                                    })}
                            </Asyncable>
                        );
                    }}
                </Route>
                <Route path={PATHS.SUBCATEGORY}>
                    {(params) => {
                        return (
                            <Asyncable>
                                {Promise.all([
                                    api.shopByToken(params.roomshop),
                                    api.categories(decodeURIComponent(params.roomshop)),
                                    api.subcategories({
                                        channel: params.roomshop,
                                        parent_id: params.category,
                                    }),
                                    api.design(landingPage.designID),
                                ])
                                    .then(([channel, categories, subcategories, design]) => {
                                        showLoadingContent(false);
                                        return (
                                            <div id="sales">
                                                <Subcategory
                                                    data={{
                                                        subcategory: subcategories.find(
                                                            (x) => x.id === params.subcategory,
                                                        ),
                                                        channel,
                                                        categories,
                                                        cart: carrito.value[params.roomshop] ?? [],
                                                        activeCategory: params.category,
                                                        subcategories,
                                                    }}
                                                    signals={{
                                                        focus,
                                                    }}
                                                    templating={{
                                                        css: dbStylesToStylesheet(design?.style),
                                                        texts,
                                                        languageCode: storage.get("lang", "en"),
                                                        projectLanguageCode,
                                                    }}
                                                    injected={{
                                                        Navigable,
                                                        Link,
                                                        navigate,
                                                        List: HorizontalList,
                                                        BackgroundImage,
                                                    }}
                                                />
                                            </div>
                                        );
                                    })
                                    .catch((e) => {
                                        onPromiseVendureError();
                                    })}
                            </Asyncable>
                        );
                    }}
                </Route>
                <Route path={PATHS.PRODUCT}>
                    {(params) => {
                        let inEdition = null;
                        //recuperar datos a editar
                        if (params.timestamp) {
                            inEdition = carrito.value[storage.get("orderToken")].find(
                                (item) =>
                                    item.variant?.toString() == params.variantId && item.timestamp === params.timestamp,
                            );
                        }
                        return (
                            <Asyncable onLoading={<Loading style={loadingStyle} />}>
                                {Promise.all([
                                    api.shopByToken(params.roomshop),
                                    api.variant(params.variantId, params.roomshop),
                                    api.design(landingPage.designID),
                                ])
                                    .then(async ([channel, variant, design]) => {
                                        showLoadingContent(false);
                                        const extras = (
                                            await Promise.all(
                                                (variant.extras || []).map((x) => api.extra(x.id, params.roomshop)),
                                            )
                                        ).reduce((acc, cur) => {
                                            acc[cur.id] = cur;
                                            return acc;
                                        }, {});
                                        return (
                                            <div id="sales" style={{ display: "block" }}>
                                                <DetailsWrapper
                                                    channel={channel}
                                                    templating={{
                                                        css: dbStylesToStylesheet(design?.style),
                                                        texts,
                                                        languageCode: storage.get("lang", "en"),
                                                        projectLanguageCode,
                                                    }}
                                                    variant={variant}
                                                    extras={extras}
                                                    inEdition={inEdition}
                                                    texts={texts}
                                                />
                                            </div>
                                        );
                                    })
                                    .catch((e) => {
                                        onPromiseVendureError();
                                    })}
                            </Asyncable>
                        );
                    }}
                </Route>
                <Route path={PATHS.CART}>
                    {(params) => {
                        showLoadingContent(false);
                        return (
                            <Asyncable>
                                {Promise.all([
                                    api.shopByToken(params.roomshop),
                                    Promise.all(
                                        (carrito.value[params.roomshop] ?? []).map((x) => {
                                            const cItem = carrito.value[params.roomshop].find(
                                                (item) => item.variant === x.variant && item.timestamp === x.timestamp,
                                            );
                                            return api.variant(
                                                x.variant,
                                                params.roomshop,
                                                cItem.quantity,
                                                cItem.timestamp,
                                            );
                                        }),
                                    ),
                                    api.design(landingPage.designID),
                                    Promise.all(
                                        (carrito.value[params.roomshop] || []).flatMap((x) =>
                                            Object.keys(x.config).map((x) => api.extra(x, params.roomshop)),
                                        ),
                                    ),
                                ])
                                    .then(([channel, variants, design, extras]) => {
                                        showLoadingContent(false);
                                        const variantRecord = variants.reduce((acc, cur) => {
                                            acc[cur.id] = cur;
                                            return acc;
                                        }, {});
                                        const translatedExtras = extras
                                            .map((x) => ({
                                                ...x,
                                                name:
                                                    x.translations.find(
                                                        (x) => x.languageCode === storage.get("lang", "en"),
                                                    )?.name || x.translations[0].name,
                                            }))
                                            .reduce((acc, cur) => {
                                                acc[cur.id] = cur;
                                                return acc;
                                            }, {});
                                        return (
                                            <div id="sales">
                                                <CartWrapper
                                                    channel={channel}
                                                    templating={{
                                                        css: dbStylesToStylesheet(design?.style),
                                                        texts,
                                                        languageCode: storage.get("lang", "en"),
                                                        projectLanguageCode,
                                                    }}
                                                    variants={variantRecord}
                                                    texts={texts}
                                                    extras={translatedExtras}
                                                />
                                            </div>
                                        );
                                    })
                                    .catch((e) => {
                                        console.error(e);
                                        onPromiseVendureError();
                                    })}
                            </Asyncable>
                        );
                    }}
                </Route>
                <Route path={PATHS.MYORDERS}>
                    {(params) => {
                        return (
                            <Asyncable onLoading={<Loading style={loadingStyle} />}>
                                {Promise.all([api.myOrders(allShopsTokens), api.design(landingPage.designID)])
                                    .then(([myOrders, design]) => {
                                        return Promise.resolve(
                                            api.orderInfo(
                                                allShopsTokens,
                                                myOrders.map((item) => item.id),
                                            ),
                                        ).then((orderInfo) => {
                                            const ordersInfo = orderInfo?.data?.orderInfo;
                                            myOrders.forEach((order) => {
                                                const _orderInfoData = ordersInfo.find(
                                                    (item) => parseInt(item.id) === parseInt(order.id),
                                                );
                                                if (_orderInfoData?.customFields?.pickUp?.location) {
                                                    order.channels[0].customFields.pickup_location = `[{"languageCode":"en","name":"${_orderInfoData.customFields.pickUp.location}"}]`;
                                                }
                                            });
                                            showLoadingContent(false);
                                            return (
                                                <div id="sales">
                                                    <MyOrders
                                                        data={myOrders}
                                                        templating={{
                                                            css: dbStylesToStylesheet(design?.style),
                                                            texts,
                                                            languageCode: storage.get("lang", "en"),
                                                            projectLanguageCode,
                                                        }}
                                                        signals={{ focus }}
                                                        injected={{
                                                            Link,
                                                            List: VerticalList,
                                                            cleanCache: resetVendureCache,
                                                        }}
                                                    />
                                                </div>
                                            );
                                        });
                                    })
                                    .catch((e) => {
                                        onPromiseVendureError();
                                    })}
                            </Asyncable>
                        );
                    }}
                </Route>
                <Route path={PATHS.MYORDERDETAIL}>
                    {(params) => {
                        return (
                            <Asyncable onLoading={<Loading style={loadingStyle} />}>
                                {Promise.all([
                                    api.shops(),
                                    api.orderById(allShopsTokens, [params.order]),
                                    api.orderInfo(allShopsTokens, [params.order]),
                                    api.design(landingPage.designID),
                                ])
                                    .then(([shops, ordersById, orderInfo, design]) => {
                                        showLoadingContent(false);
                                        const orderDetail = ordersById?.data?.orders?.items?.[0] || null;
                                        let shopData = shops.find(
                                            (_shop) => _shop.token === orderDetail.channels[0].token,
                                        );
                                        const orderInfoData = orderInfo?.data?.orderInfo;
                                        const orderExtraDetail = orderInfoData.find(
                                            (order) => parseInt(order.id) === parseInt(params.order),
                                        );
                                        orderDetail.productsData = orderExtraDetail.customFields.items;
                                        if (orderExtraDetail?.customFields?.pickUp?.location) {
                                            shopData.customFields.pickup_location = [
                                                {
                                                    languageCode: "en",
                                                    name: orderExtraDetail.customFields.pickUp.location,
                                                },
                                            ];
                                        }
                                        return (
                                            <div id="sales">
                                                <MyOrderDetail
                                                    data={{ shop: shopData, order: orderDetail }}
                                                    templating={{
                                                        css: dbStylesToStylesheet(design?.style),
                                                        texts,
                                                        languageCode: storage.get("lang", "en"),
                                                        projectLanguageCode,
                                                    }}
                                                    signals={{ focus }}
                                                    injected={{
                                                        Autoscrollable,
                                                        Link,
                                                        cancelOrder: () => {
                                                            storage.set("orderToken", shopData.token);
                                                            dispatch(setPopUp("cancelorder"));
                                                        },
                                                    }}
                                                />
                                            </div>
                                        );
                                    })
                                    .catch((e) => {
                                        onPromiseVendureError();
                                    })}
                            </Asyncable>
                        );
                    }}
                </Route>

                {/* <Route path={PATHS.TVCHANNEL}>{(params) => <Player channelId={params.channelId} />}</Route> */}

                <Route path={PATHS.TV}>{() => <Tv />}</Route>
                <Route path={PATHS.CHECKOUTSUCCESS}>
                    {() => (
                        <div class={"section"}>
                            <Checkoutsuccess />
                        </div>
                    )}
                </Route>
                <Route path={PATHS.CCINSTRUCTIONS}>
                    {() => (
                        <CSSTransition
                            in={!!screenData}
                            timeout={ANIMATION_TIMEOUT}
                            classNames="fadeInAnimation" // fadeInAnimation , topAnimation, leftAnimation
                            unmountOnExit
                            onExit={onLayoutExit}
                        >
                            <CcInstructions ccInstructionsData={screenData} />
                        </CSSTransition>
                    )}
                </Route>
                <Route path={`${PATHS.LIBRARY}/:ref`}>
                    {(path) => (
                        <div class={"section"}>
                            <LibraryContent asset={path.ref} />
                        </div>
                    )}
                </Route>
                <Route path={PATHS.SCREEN}>
                    {() => (
                        <>
                            <CSSTransition
                                in={!!screenData}
                                timeout={ANIMATION_TIMEOUT}
                                classNames="fadeInAnimation" // fadeInAnimation , topAnimation, leftAnimation
                                unmountOnExit
                                onExit={onLayoutExit}
                            >
                                <Screen data={screenData} preventFocus={preventFocusOnHome.current} />
                            </CSSTransition>
                        </>
                    )}
                </Route>
                <Route>
                    <Page404 />
                </Route>
            </Switch>
        </>
    );
};

export default Content;
