/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { updateStatus } from "../../actions/statusActions";
import { Texts } from "../../utils/texts";
import { memo } from "preact/compat";
import Button from "../../components/common/button";
import HourPicker from "../../components/common/hourPicker";
import DatePicker from "../../components/common/datePicker";
import { displayErrorPopUp, setPopUp } from "../../actions/uiActions";
import HorizontalList from "../../components/common/horizontalList";
import { getChannelData, inPreview, padZero } from "../../utils/utils";
import mutationSaveAlarm from "../../hooks/GraphqlCalls/saveAlarm";
import { eventHandler } from "../../utils/eventHandler";
import focus from "../../utils/focus";

const Alarm = () => {
    const dispatch = useDispatch();

    //Store data
    const guestCountry = useSelector((state) => state.status.locationData.guestInfo.country);
    const texts = useSelector((state) => state.ui.texts);
    const alarm = useSelector((state) => state.status.sessionData.alarm);
    const sessionData = useSelector((state) => state.status.sessionData);
    const locationData = useSelector((state) => state.status.locationData);

    //States
    const alarmDate = alarm ? new Date(alarm.startTime) : null;
    const [hours, setHours] = useState(alarmDate ? alarmDate.getHours() : null);
    const [minutes, setMinutes] = useState(alarmDate ? alarmDate.getMinutes() : null);
    const [day, setDay] = useState(alarmDate ? alarmDate.getDate() : null);
    const [month, setMonth] = useState(alarmDate ? alarmDate.getMonth() + 1 : null);
    const [year, setYear] = useState(alarmDate ? alarmDate.getFullYear() : null);

    const [alarmType, setAlarmType] = useState(alarm ? alarm.type : "BEEP");
    const [chSelected, setChannelSelected] = useState(
        alarm && alarm.type === "TVCHANNEL" ? `channelSelector-${alarm.tvChannelId}` : ``,
    );
    const [channelList, setChannelList] = useState([]);
    const [logoPath, setLogoPath] = useState();
    const [error, setError] = useState();

    //listeners
    useEffect(() => {
        focus.value.replace("btn-hours");
        const onSuccess = (channels) => {
            if (channels) {
                parseChannelsResponse(channels);
            }
        };
        getChannelData(onSuccess, { guestCountry, texts });

        return () => {};
    }, []);

    const updateChannelSelected = () => {
        const fItem = document.querySelector(`#${focus.value.current}`);
        if (fItem?.getAttribute("itemId")) {
            const itemId = fItem.getAttribute("itemId");
            setChannelSelected(itemId);
        }
    };
    const parseChannelsResponse = (data) => {
        let channels = JSON.parse(data.channels);
        if (channels.categories[0].items) {
            channels.categories[0].items.map((ch, index) => (ch.pos = ch.num));
        }
        setChannelList(channels.categories[0].items);
        setLogoPath({
            cloudURL: data.cloudURL,
            pRef: data.projectRef,
        });
    };

    const validateDate = (alarmTime) => {
        if (alarmTime.getTime() < new Date().getTime()) {
            setError(texts["alarm-no-valid"]);
            return false;
        } else if (alarmType === "TVCHANNEL" && !chSelected) {
            setError(texts["select-tv-channel"]);
            return false;
        } else {
            setError(null);
        }
        return true;
    };

    const saveAlarm = () => {
        const alarmTime = new Date(`${year}/${padZero(month)}/${padZero(day)} ${padZero(hours)}:${padZero(minutes)}`);

        if (!validateDate(alarmTime)) {
            return;
        }
        if (inPreview()) {
            dispatch(displayErrorPopUp({ text: texts["preview-msg"], timeout: 3000 }));
            return;
        }
        mutationSaveAlarm({
            alarmType,
            alarmTime,
            chSelected,
            sessionData,
            locationData,
            onResponse: (res) => {
                if (res?.data?.createAlarm?.ok) {
                    dispatch(updateStatus());
                    dispatch(setPopUp(null));
                }
            },
        });
    };

    const onArrowKey = (direction) => {
        if (
            (direction === "down" &&
                eventHandler.focusedBtn.id &&
                (eventHandler.focusedBtn.id === "btn-hours" || eventHandler.focusedBtn.id === "btn-minutes")) ||
            (direction === "up" &&
                eventHandler.focusedBtn.id &&
                (eventHandler.focusedBtn.id === "btn-beep" || eventHandler.focusedBtn.id === "btn-tvchannel"))
        ) {
            focus.value.replace(`btn-day`);
            return true;
        } else if (direction === "up" && focus.value.current.indexOf("btn-channelSelector") > -1) {
            focus.value.replace(`btn-beep`);
            return true;
        } else if (direction === "up" && focus.value.current.indexOf("btn-save") > -1) {
            focus.value.replace(`btn-tvchannel`);
            return true;
        } else if (direction === "down" && eventHandler.focusedBtn.id === "btn-exit") {
            focus.value.replace(`btn-hours`);
            return true;
        }
        return false;
    };

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;
        if (keyData?.type === "arrow") {
            preventDefault = onArrowKey(keyData.value);
        }
        if (preventDefault) {
            e.stopPropagation();
        }
    };

    return (
        <div
            className="text-center"
            onKeyDown={(e) => {
                onkeydown(e);
            }}
        >
            <div style={{ marginTop: "1vh" }}>
                <HourPicker
                    hours={hours}
                    minutes={minutes}
                    setHours={setHours}
                    setMinutes={setMinutes}
                    insideModal={true}
                />
            </div>
            <div style={{ marginTop: "3vh" }}>
                <DatePicker
                    day={day}
                    month={month}
                    tomorrow={!day}
                    year={year}
                    setDay={setDay}
                    setMonth={setMonth}
                    setYear={setYear}
                    insideModal={true}
                />
            </div>
            <div>
                <div className="py-7 text-xl">
                    <div>{Texts.capitalize(texts["select alarm type"])}</div>
                </div>
                <div className="table mx-auto pb-4">
                    <Button
                        insideModal={true}
                        data={{
                            id: "beep",
                            name: Texts.capitalize(texts["beep"]),
                            border: true,
                            active: alarmType === "BEEP",
                            customClass: "py-2 text-lg text-center rounded float-left",
                            customStyle: {
                                width: "11vw",
                                marginRight: "1vw",
                            },
                            onClick() {
                                setAlarmType("BEEP");
                            },
                        }}
                    />
                    <Button
                        insideModal={true}
                        data={{
                            id: "tvchannel",
                            name: Texts.capitalize(texts["TV Channel"]),
                            border: true,
                            active: alarmType === "TVCHANNEL",
                            customClass: "py-2 text-lg text-center rounded float-left",
                            customStyle: {
                                width: "11vw",
                            },
                            onClick() {
                                setAlarmType("TVCHANNEL");
                            },
                        }}
                    />
                </div>
            </div>

            <div className="table mx-auto">
                <div className="py-3 mb-8 text-lg" style={{ height: "21vh" }}>
                    {alarmType === "TVCHANNEL" && channelList?.length > 0 ? (
                        <>
                            <div className="">{Texts.capitalize(texts["select tv channel"])}</div>
                            <div
                                className="block mx-auto relative"
                                style={{
                                    height: "19vh",
                                    width: "80vw",
                                    overflow: "hidden",
                                }}
                            >
                                <HorizontalList
                                    insideModal={true}
                                    noFocusOnLoad={true}
                                    data={{
                                        id: "channel_HL",
                                        list: channelList,
                                        paginated: true,
                                        itemsPerPage: 6,
                                        customClass: "p-4 h-full",
                                        outlineOnfFocus: true,
                                        itemType: "channelSelector",
                                        itemCustomStyle: {
                                            marginBottom: "0.5vh",
                                            padding: "o.5vw",
                                            backgroundColor: "transparent",
                                        },
                                        selected: chSelected,
                                        itemRounded: true,
                                        logoPath,
                                        onClick: updateChannelSelected,
                                    }}
                                />
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
            <div className="table mt-8 mx-auto">
                <div className="text-lg">{error ? <div className="p-4">{error}</div> : null}</div>
                {!alarm ? (
                    <>
                        <Button
                            insideModal={true}
                            data={{
                                name: Texts.capitalize(texts["set alarm"]),
                                border: true,
                                customClass: "py-2 w-full text-lg text-center rounded",
                                customStyle: {
                                    minWidth: "30vw",
                                },
                                onClick() {
                                    saveAlarm();
                                },
                            }}
                        />
                    </>
                ) : (
                    <>
                        <div className="table">
                            <Button
                                insideModal={true}
                                data={{
                                    name: Texts.capitalize(texts["delete alarm"]),
                                    border: true,
                                    customClass: "py-2 text-lg text-center rounded float-left",
                                    customStyle: {
                                        width: "23vw",
                                        marginRight: "1vw",
                                    },
                                    onClick() {
                                        dispatch(setPopUp("alarmDelete"));
                                    },
                                }}
                            />
                            <Button
                                insideModal={true}
                                data={{
                                    id: "save",
                                    name: Texts.capitalize(texts["save"]),
                                    border: true,
                                    customClass: "py-2 w-full text-lg text-center rounded float-left",
                                    customStyle: {
                                        width: "23vw",
                                    },
                                    onClick() {
                                        saveAlarm();
                                    },
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default memo(Alarm);
