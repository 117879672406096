import { vendureQuery } from "../../../hooks/query";
import storage from "../../../utils/storage";

type QueryResult = {
    data: {
        orders: {
            items: {
                id: string;
                state: string;
                total: number;
                totalWithTax: number;
                totalQuantity: number;
                currencyCode: string;
                updatedAt: string;
                channels: {
                    token: string;
                    customFields: {
                        pickup_location: { languageCode: string; name: string }[];
                        is_auto_accept_enabled: boolean;
                    };
                }[];
                shippingLines: { shippingMethod: { code: string } }[];
                customFields: { scheduleStart: string; scheduleEnd: string; deliveryLocation: string };
            }[];
        };
    };
};
type ReturnResult = {
    id: string;
    state: string;
    total: number;
    shop_name: string;
    pricesIncludeTax: boolean;
    totalWithTax: number;
    totalQuantity: number;
    currencyCode: string;
    updatedAt: string;
    channels: {
        token: string;
        customFields: {
            pickup_location: { languageCode: string; name: string }[];
            is_auto_accept_enabled: boolean;
        };
    }[];
    shippingLines: { shippingMethod: { code: string } }[];
    customFields: { scheduleStart: string; scheduleEnd: string; deliveryLocation: string };
}[];

export default (channels: string): Promise<ReturnResult> =>
    new Promise((resolve, reject) =>
        vendureQuery({
            apiType: "admin",
            headers: { Authorization: `Bearer ${sessionStorage.getItem("stayToken")}`, "vendure-token": channels },
            cache: true,
            body: `{
        orders(options:{filter:{roomNumber:{eq:${storage.get("roomNumber", 0)}}, guestId: {eq: ${storage.get(
            "lastStayGuestId",
            0,
        )}}}}){
          items{
            id
            state
            total
            totalWithTax
            currencyCode
            totalQuantity
            updatedAt
            customFields{
              scheduleStart
              scheduleEnd
              deliveryLocation
            }
            shippingLines{
                shippingMethod{
                    code
                }
            }
            channels{
              token
              customFields{
                pickup_location
                is_auto_accept_enabled
              }
            }
          }
        }
      }`,
            onResponse: (data: QueryResult) => {
                const _shops = JSON.parse(sessionStorage.getItem("shops") || "{}");
                let resultReturn = <ReturnResult>[];
                data?.data?.orders?.items.forEach((order) => {
                    const _orderShop = _shops.find((item: any) => item.token === order.channels[0]?.token);
                    const orderParsed = {
                        ...order,
                        shop_name: _orderShop?.name as string,
                        pricesIncludeTax: _orderShop?.pricesIncludeTax as boolean,
                    };
                    resultReturn.push(orderParsed);
                });
                resolve(resultReturn);
            },
            onError: (e) => reject(e),
        }),
    );
