import { getRamdonMAC, prepareDeviceKeyMap } from "../../../utils/utils";
import { keyMap } from "./keyMap";

export const STB = {
    model: "PC",
    ref: ``,
    tvName: "PC-",
    init() {
        this.keyMap = prepareDeviceKeyMap(keyMap);
    },
    getMac(onSuccess) {
        let cMac = localStorage.getItem("mac");
        if (!cMac) {
            cMac = getRamdonMAC();
            localStorage.setItem("mac", cMac);
        }

        this.mac = cMac;
        this.ref = `${this.model}-${this.mac}`;
        if (onSuccess) {
            onSuccess();
        }
    },
    updatePowerState() {
        localStorage.setItem("powerState", "online");
    },
    changeTVName(name) {
        this.tvName = `${this.model}  ${name}`;
    },
};
