import { Theme } from "../../../utils/theme";

const DeliveryItem = (channel, index, logoPath) => {
    return (
        <div className="float-left" style={{ padding: "1.2vw" }}>
            <div className={`mx-auto nowrap text-ellipsis overflow-hidden text-2xl text-center pt-2 `}>
                {channel.name}
            </div>
        </div>
    );
};
export default DeliveryItem;
