import { EVENTS } from "../../utils/eventsConst";
import { writeLog, getEnvVar } from "../../utils/utils";

export const Media = {
    MEDIA_PLAY_SUCCESS: 1,
    MEDIA_STATUS_END_OF_STREAM: 2,
    MEDIA_STOPPED: 3,
    MEDIA_REPLAY: 4,
    MEDIA_PLAY_ERROR: 20,
    ALARM_SNOOZE_TIMEOUT: 600000, //10 minutes
    ALARM_STOP_TIMEOUT: 900000, //15 minutes

    init() {
        this.kill();
    },

    showVideoOnBack() {
        console.log("show video on back");
    },

    showVideoOnFront() {
        console.log("show video on front");
    },
    playHTMLVideo(videoId) {
        const videoItem = document.getElementById(videoId);
        if (videoItem) {
            videoItem.play();
            videoItem.onended = function () {
                let e = new CustomEvent(EVENTS.type.MEDIA, {
                    detail: Media.MEDIA_STATUS_END_OF_STREAM,
                });
                document.dispatchEvent(e);
            };
            Media.playingMedia = true;
            this.cVideoData = { type: "HTML", videoId };
        }
    },
    playHLSVideo(url, loop) {
        Media.playingMedia = true;
        this.cVideoURL = url;
        this.cVideoOptions = { loop };
        this._playHLSVideo(url, !!loop);
    },
    // eslint-disable-next-line no-unused-vars
    stop() {
        Media.playingMedia = false;
        Media.playingChannel = false;
        this.cVideoURL = null;
        this.cChannel = null;
        if (this.cVideoData?.type === "HTML") {
            const videoItem = document.getElementById(this.cVideoData?.videoId);
            if (videoItem) {
                videoItem.pause();
                videoItem.currentTime = 0;
            }
        } else {
            this._stop();
        }
    },
    exitLayout() {
        this.showVideoOnBack();
        this.stop();
    },
    playChannel: function (channel) {
        Media.playingChannel = true;
        this.cChannel = channel;
        switch (channel.type) {
            case "HLS":
                this._playHLSVideo(channel.url || channel.hlsurl);
                break;
            case "DVBT":
                this._playDVBTChannel(channel);
                break;
            case "DVBC":
                this._playDVBCChannel(channel);
                break;
            case "DVBS":
                this._playDVBSChannel(channel);
                break;
            case "ATSC":
                this._playATSCChannel(channel);
                break;
            default:
                this._playChannel(channel.ip, channel.port);
                break;
        }
    },

    playAlarm() {
        this.playingAlarm = true;
        const audioAlarm = document.getElementById("audioAlarm");
        if (!audioAlarm) {
            const audio = document.createElement("audio");
            audio.id = "audioAlarm";
            audio.loop = true;
            const source = document.createElement("source");
            source.type = "audio/mpeg";
            source.src = `${getEnvVar("DRIVE")}/tvs/alarm.mp3`;
            audio.appendChild(source);
            document.body.insertBefore(audio, document.body.firstChild);
            writeLog("play");
            audio.play();
        } else {
            audioAlarm.play();
        }
    },
    stopAlarm() {
        this.playingAlarm = false;
        console.log("stopAlarm");
        clearTimeout(Media.alarmTimeout);
        const audioAlarm = document.getElementById("audioAlarm");
        if (audioAlarm) {
            audioAlarm.pause();
        }
    },
    snoozeAlarm() {
        clearTimeout(Media.alarmTimeout);
        const audioAlarm = document.getElementById("audioAlarm");
        if (audioAlarm) {
            audioAlarm.pause();
        }
        Media.alarmTimeout = setTimeout(() => {
            Media.playAlarm();
        }, Media.ALARM_SNOOZE_TIMEOUT);
    },

    _playChannel(ip, port) {
        console.log(`Play channel IP: ${ip} PORT: ${port}`);
    },

    _playHLSVideo(url) {
        console.log(`Play HLS video ${url}`);
    },
    // eslint-disable-next-line no-unused-vars
    _replayHLSVideo(url, loop) {
        console.log("RePlay HLS video");
    },
    _playDVBTChannel: function (channel) {
        console.log("Play DVB-T channel");
    },
    _playDVBCChannel: function (channel) {
        console.log("Play DVB-C channel");
    },
    _playDVBSChannel: function (channel) {
        console.log("Play DVB-S channel");
    },
    _playATSCChannel: function (channel) {
        console.log("Play ATS-C channel");
    },

    replayCurrentVideo: function () {
        if (this.cChannel) {
            this.playChannel(this.cChannel);
        } else if (this.cVideoData?.type === "HTML") {
            this.playHTMLVideo(this.cVideoData?.videoId);
        } else if (this.cVideoURL) {
            this._replayHLSVideo(this.cVideoURL, this.cVideoOptions.loop);
        }
    },
    _stop() {
        console.log("Die template");
    },
    //topx and width in vw
    //topy and height in vh
    // eslint-disable-next-line no-unused-vars
    setVideoSize(x, y, width, height) {
        console.log("Die template");
    },
    ensureVideoSize() {
        console.log("Die template");
    },

    getAudioList() {
        console.log("Die template");
    },
    // eslint-disable-next-line no-unused-vars
    getSubtitleList(onSuccess) {
        console.log("Die template");
    },
    disableSubtitles() {
        console.log("Die template");
    },
    kill() {
        console.log("Die template");
    },
    changeAudio() {
        console.log("Die template");
    },
    changeSubtitle() {
        console.log("Die template");
    },
};
