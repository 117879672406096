/* eslint-disable no-undef */
import { PATHS } from "../../../utils/constants";
import { EVENTS } from "../../../utils/eventsConst";
import { serverRequest, writeLog } from "../../../utils/utils";
import { Media } from "../media.js";
import { STB } from "../stb";
import { wixp } from "./wixp.js";

/**
 * Philips common control interface.
 * To be used by stb.js, media.js and devtools.js
 * Requires wixp.js and sixp.js
 */

export const philips = {
    refreshIter: 0,
    maxVolume: 60,
    refreshInterval: null,
    status: null,

    ///////////////////////////////////////////////////////////////////////
    ///		INITIALIZE UTILS  											///
    ///////////////////////////////////////////////////////////////////////
    ///																	///
    /// That function initializes things when the page is loaded.		///
    ///////////////////////////////////////////////////////////////////////

    /**
     * Initializes wixp, and registers all keys.
     */
    init() {
        wixp.init();
        this.unregisterSomeKeys();

        philips.enableCustomDashboard();
        philips.changeToNone();
        philips.deActivateAll();
    },

    ///////////////////////////////////////////////////////////////////////
    ///		SETTINGS UTILS  											///
    ///////////////////////////////////////////////////////////////////////
    ///																	///
    /// These functions help with ProfessionalSettings requests.		///
    ///////////////////////////////////////////////////////////////////////

    /**
     * Request to report the current configuration for the switch on
     * channel.
     */
    SETTING_SWITCH_ON_CHANNEL: "_WIXP_PROFESSIONAL_PARAMETER_SwitchOnChannel",

    /**
     * Request to report TV’s model number.
     */
    SETTING_MODEL: "_WIXP_PROFESSIONAL_PARAMETER_ModelNumber",

    /**
     * Request to report TV’s serial number.
     */
    SETTING_SERIAL: "_WIXP_PROFESSIONAL_PARAMETER_SerialNumber",

    /**
     * Request to report TV’s Vsecure ID for the Vsecure encryption system.
     */
    SETTING_VSECURE_ID: "_WIXP_PROFESSIONAL_PARAMETER_VSecuresTVID",

    /**
     * Request to report TV’s the room ID configured in the professional
     * menu or via the corresponding IxP command.
     */
    SETTING_ROOM: "_WIXP_PROFESSIONAL_PARAMETER_RoomID",

    /**
     * Request to report if the RawWIxP TCP client is enabled or disabled.
     * The configuration of this parameter is done via professional menu.
     */
    SETTING_RAW_WIXP: "_WIXP_PROFESSIONAL_PARAMETER_RawWIXP",

    /**
     * Request to report the current value of the secure simply share pin.
     */
    SETTING_SHARE_PIN: "_WIXP_PROFESSIONAL_PARAMETER_SimplySharePin",

    /**
     * Request to report the current network type, wireless/wire and
     * DHCP/static IP.
     */
    SETTING_NETWORK_DETAILS: "_WIXP_PROFESSIONAL_PARAMETER_TVNetworkDetails",

    /**
     * Request to report the current network status, including MAC address,
     * IP address, netmask, etc.
     */
    SETTING_NETWORK_STATUS: "_WIXP_PROFESSIONAL_PARAMETER_TVNetworkStatus",

    playingAlarm: false,
    previousMediaType: null,
    teletextOn: false,
    currentTime: null,
    mute: null,
    cVolume: null,
    volumeDisplay: null,
    volumeContainer: null,
    muteContainer: null,
    volumeDisplayTimeout: null,

    activateDashboard() {
        let CommandDetails = {
            ApplicationDetails: {
                ApplicationName: "SystemUI",
            },
            ApplicationState: "Activate",
        };

        wixp.change(
            "ApplicationControl",
            () => {
                console.debug("Activate Dashboard");
            },
            CommandDetails,
        );
    },

    getssidpwd(enable) {
        let CommandDetails = { ApplicationName: "Directshare" };
        console.debug("GET AP data");

        wixp.request(
            "ApplicationControl",
            (resp) => {
                console.debug(`Respuesta del directshare${JSON.stringify(resp)}`);
                STB.apSSID = resp.ApplicationDetails.ApplicationAttributes.WiFiDirectAttributes.SSID;
                STB.apKey = resp.ApplicationDetails.ApplicationAttributes.WiFiDirectAttributes.Password;
                console.debug(`SSID : ${resp.ApplicationDetails.ApplicationAttributes.WiFiDirectAttributes.SSID}`);
                console.debug(
                    `Password : ${resp.ApplicationDetails.ApplicationAttributes.WiFiDirectAttributes.Password}`,
                );

                console.debug(
                    `connection: ${JSON.stringify(
                        resp.ApplicationDetails.ApplicationSubState.WifiDirectStatus.Connection,
                    )}`,
                );

                if (enable) {
                    philips.ensureHotspot();
                }
            },
            CommandDetails,
        );
    },

    getFullModel() {
        let _fullInfo = window.navigator.userAgent.split("(");
        let _model = null;
        let _fullModel = { model: "", firmware: "" };

        for (let i = 0; i < _fullInfo.length; i++) {
            if (_fullInfo[i].indexOf("Philips") != -1 || _fullInfo[i].indexOf("PHILIPSTV") != -1) {
                _model = _fullInfo[i].split(";");
                break;
            }
        }

        if (_model) {
            for (let k = 0; k < _model.length; k++) {
                if (_model[k].indexOf("HFL") != -1) {
                    _fullModel.model = _model[k];
                }
                if (_model[k].indexOf("TPM") != -1) {
                    _fullModel.firmware = _model[k];
                }
            }
        }

        return _fullModel;
    },

    ensureHotspot() {
        let CommandDetails = { ApplicationName: "Miracast" };

        wixp.request(
            "ApplicationControl",
            (resp) => {
                if (
                    resp.ApplicationDetails &&
                    resp.ApplicationDetails.ApplicationSubState &&
                    resp.ApplicationDetails.ApplicationSubState.WifiDirectStatus &&
                    resp.ApplicationDetails.ApplicationSubState.WifiDirectStatus.Connection == "Connected"
                ) {
                    STB.apEnabled = true;
                    console.debug("NO activate hotspot, ya esta lanzado");
                    return;
                }
                STB.apEnabled = true;
                philips.activateHotspot();
            },
            CommandDetails,
        );
    },

    activateHotspot() {
        console.debug("Activate hotspot");
        let CommandDetails = {
            ApplicationDetails: {
                ApplicationName: "Directshare",
                ApplicationSubAction: {
                    WiFiDirectAction: {
                        Action: "StartBroadcastSSID",
                    },
                },
            },
            ApplicationState: "Activate",
        };

        wixp.change(
            "ApplicationControl",
            (resp) => {
                console.debug(`Activate hotspot ${JSON.stringify(resp)}`);
            },
            CommandDetails,
        );
    },

    disableDirectShare() {
        console.debug("Desactivar directshare");
        let CommandDetails = {
            ApplicationDetails: {
                ApplicationName: "Directshare",
            },
            ApplicationState: "DeActivate",
        };

        wixp.change(
            "ApplicationControl",
            () => {
                console.debug("Response Desactivar directshare");
                philips.enableCustomDashboard();
            },
            CommandDetails,
        );
    },

    startMiracast() {
        console.debug("voy a lanzar miracast");

        let CommandDetails = {
            ApplicationDetails: {
                ApplicationName: "Miracast",
                ApplicationSubAction: {
                    WiFiDirectAction: {
                        Action: "StartBroadcastSSID",
                    },
                },
            },
            ApplicationState: "Activate",
        };

        wixp.change(
            "ApplicationControl",
            (resp) => {
                console.debug(`Start Miracast ${JSON.stringify(resp)}`);

                if (
                    resp.ActiveApplications &&
                    resp.ActiveApplications.length == 1 &&
                    resp.ActiveApplications[0].ApplicationName == "CustomDashboard"
                ) {
                    STB.activeApp = null;
                }
            },
            CommandDetails,
        );
    },

    activateMiracastOSD() {
        Media.kill();
        STB.miracastActive = true;
        console.debug("voy a activar miracast");
        setTimeout(() => {
            philips.disableCustomDashboard();
        }, 1000);

        let CommandDetails = {
            ApplicationDetails: {
                ApplicationName: "Miracast",
                ApplicationSubAction: {
                    WiFiDirectAction: {
                        Action: "AllowDeviceConnection",
                    },
                },
            },
            ApplicationState: "Activate",
        };

        wixp.change(
            "ApplicationControl",
            (resp) => {
                console.debug(`Activate Miracast ${JSON.stringify(resp)}`);
            },
            CommandDetails,
        );
    },

    stopMiracast() {
        STB.miracastActive = false;
        console.debug("Desactivo miracast");
        let CommandDetails = {
            ApplicationDetails: {
                ApplicationName: "Miracast",
                ApplicationSubAction: {
                    WiFiDirectAction: {
                        Action: "StopBroadcastSSID",
                    },
                },
            },
            ApplicationState: "DeActivate",
        };

        wixp.change(
            "ApplicationControl",
            () => {
                console.debug("Stop Miracast");
            },
            CommandDetails,
        );
    },

    disableMiracast() {
        console.debug("Desactivar directshare osd");
        let CommandDetails = {
            ApplicationDetails: {
                ApplicationName: "Miracast",
            },
            ApplicationState: "DeActivate",
        };

        wixp.change(
            "ApplicationControl",
            () => {
                console.debug("Response Desactivar directshare osd");
            },
            CommandDetails,
        );
    },

    activateSmartTv() {
        this.disableCustomDashboard();
        console.debug("Activar SmartTV");
        let CommandDetails = {
            ApplicationDetails: {
                ApplicationName: "SmartTV",
            },
            ApplicationState: "Activate",
        };

        wixp.change(
            "ApplicationControl",
            (resp) => {
                console.debug(JSON.stringify(resp));
            },
            CommandDetails,
        );
    },

    activateApplication(appName, attributes) {
        console.debug(`Activar APP: ${appName}`);
        let CommandDetails = {
            ApplicationDetails: {
                ApplicationName: appName,
                ApplicationAttributes: attributes,
            },
            ApplicationState: "Activate",
        };

        wixp.change(
            "ApplicationControl",
            (resp) => {
                //philips.unregisterAllKeys();
                sessionStorage.setItem("outOfInterface", true);
            },
            CommandDetails,
        );
    },

    deActivateApplication(appName) {
        //this.disableCustomDashboard();
        console.debug(`Desactivar APP: ${appName}`);
        let CommandDetails = {
            ApplicationDetails: {
                ApplicationName: appName,
            },
            ApplicationState: "Deactivate",
        };

        wixp.change(
            "ApplicationControl",
            (resp) => {
                console.debug(JSON.stringify(resp));
                philips.unregisterSomeKeys();
                STB.activeApp = null;
            },
            CommandDetails,
        );
    },

    deActivateAll() {
        console.debug("Desactivar all apps");
        let CommandDetails = {};
        wixp.request(
            "ApplicationControl",
            (resp) => {
                STB.activeApp = null;
                console.debug(JSON.stringify(resp));
                philips.unregisterSomeKeys();

                for (let i = resp.ActiveApplications.length - 1; i >= 0; i--) {
                    let app = resp.ActiveApplications[i];
                    if (app.ApplicationName == "Chromecast built-in") {
                        philips.deActivateApplication("Chromecast built-in");
                        setTimeout(() => {
                            philips.deActivateApplication("Googlecast");
                        }, 1000);
                    }
                    if (app.ApplicationName == "Chromecast Android Shell") {
                        philips.deActivateApplication("Chromecast Android Shell");
                        setTimeout(() => {
                            philips.deActivateApplication("Googlecast");
                        }, 1000);
                    }
                }
                for (let i = resp.ActiveApplications.length - 1; i >= 0; i--) {
                    let app = resp.ActiveApplications[i];
                    if (app.ApplicationName == "Googlecast") {
                        philips.deActivateApplication("Googlecast");
                    } else if (app.ApplicationName == "AutonomousApps") {
                        philips.deActivateApplication("AutonomousApps");
                    }
                }

                for (let i = resp.ActiveApplications.length - 1; i >= 0; i--) {
                    let app = resp.ActiveApplications[i];
                    if (app.ApplicationName == "CustomDashboard") {
                        continue;
                    }
                    console.debug(`Cerrando ${app.ApplicationName}`);

                    philips.deActivateApplication(app.ApplicationName);
                    return;
                }
            },
            CommandDetails,
        );
    },

    showCC() {
        console.debug("Desactivar all apps");
        let CommandDetails = {};
        wixp.request(
            "ApplicationControl",
            (resp) => {
                for (let i = resp.ActiveApplications.length - 1; i >= 0; i--) {
                    let app = resp.ActiveApplications[i];
                    if (app.ApplicationName == "AutonomousApps") {
                        STB.lastApp = app.ApplicationName;
                        sessionStorage.setItem("outOfInterface", true);
                        philips.disableCustomDashboard();
                        philips.activateApplication("AutonomousApps");
                    }
                }
            },
            CommandDetails,
        );
    },
    cleanUnusedApplications() {
        let CommandDetails = {};
        wixp.request(
            "ApplicationControl",
            (resp) => {
                console.debug(JSON.stringify(resp));
                if (!philips.outOfInterfaceFullReload && !philips.outOfInterface && !STB.activeApp) {
                    for (let i = resp.ActiveApplications.length - 1; i >= 0; i--) {
                        let app = resp.ActiveApplications[i];
                        if (app.ApplicationName == "CustomDashboard") {
                            continue;
                        }
                        console.debug(`Cerrando ${app.ApplicationName}`);

                        philips.deActivateApplication(app.ApplicationName);
                    }
                }
            },
            CommandDetails,
        );
    },

    enableCustomDashboard() {
        let CommandDetails = {
            ApplicationDetails: {
                ApplicationName: "CustomDashboard",
            },
            ApplicationState: "Activate",
        };

        wixp.change(
            "ApplicationControl",
            () => {
                console.debug("Activar interface");
                sessionStorage.removeItem("outOfInterface");
            },
            CommandDetails,
        );
    },

    goToInterface() {
        wixp.request(
            "ApplicationControl",
            (resp) => {
                const foregroundAppName = resp.CommandDetails?.ActiveApplications?.[0]?.ApplicationName;
                if (
                    foregroundAppName &&
                    !(foregroundAppName === "CustomDashboard" || foregroundAppName === "VolumeControl")
                ) {
                    STB.backToInterface();
                    return;
                } else {
                    philips.enableCustomDashboard();
                }
            },
            {},
        );
    },

    enableLocalCustomDashboard() {
        let CommandDetails = {
            ApplicationDetails: {
                ApplicationName: "LocalCustomDashboard",
            },
            ApplicationState: "Activate",
        };
        wixp.change(
            "ApplicationControl",
            (resp) => {
                console.log(`${JSON.stringify(resp)}`);
            },
            CommandDetails,
        );
    },

    disableCustomDashboard() {
        let CommandDetails = {
            ApplicationDetails: {
                ApplicationName: "CustomDashboard",
            },
            ApplicationState: "Deactivate",
        };

        wixp.change(
            "ApplicationControl",
            () => {
                sessionStorage.setItem("outOfInterface", true);
            },
            CommandDetails,
        );
    },

    disableDefaultDashboard() {
        let CommandDetails = {
            ApplicationDetails: {
                ApplicationName: "DefaultDashboard",
            },
            ApplicationState: "Deactivate",
        };

        wixp.change(
            "ApplicationControl",
            () => {
                console.log("Desactivar interface");
            },
            CommandDetails,
        );
    },

    listApplications() {
        let CommandDetails = {
            RequestListOfAvailableApplications: {
                Filter: ["Native", "NonNative"],
            },
        };

        wixp.request(
            "ApplicationControl",
            (resp) => {
                console.debug("List of applications: ");
                console.log(JSON.stringify(resp));
            },
            CommandDetails,
        );
    },

    listActiveApplications() {
        let CommandDetails = {};

        wixp.request(
            "ApplicationControl",
            (resp) => {
                console.debug(JSON.stringify(resp));
            },
            CommandDetails,
        );
    },

    getPowerState() {
        wixp.request("PowerState", (resp) => {
            philips.status = resp.CurrentPowerState;
            localStorage.setItem("powerState", resp.CurrentPowerState == "On" ? "online" : "standby");
        });
    },
    setPowerState(state) {
        philips.status = state;
        let CommandDetails = {
            ToPowerState: state,
        };
        wixp.change(
            "PowerState",
            () => {
                console.debug(`Power state set to ${state}`);
                if (state == "On") {
                }
            },
            CommandDetails,
        );
    },
    reboot() {
        let CommandDetails = {
            PowerAction: "Reboot",
        };
        wixp.change(
            "PowerService",
            () => {
                console.debug("REBOOT");
            },
            CommandDetails,
        );
    },
    getVolumeFromPercentaje(percen) {
        return Number((percen * this.maxVolume) / 100);
    },
    getVolumePercentaje(volume) {
        return Number((volume * 100) / this.maxVolume);
    },

    setVolume(volume, mute) {
        philips.cVolume = volume;
        console.log(`Mute set to : ${mute}`);
        console.log(`volume set to : ${volume}`);
        let CommandDetails = {
            Volume: philips.getVolumePercentaje(volume),
            AudioMute: mute,
            SmartSound: "Standard",
        };
        wixp.change(
            "AudioControl",
            () => {
                //aqui no entra por lo que pongo un timeout fuera
                /*console.debug("Mute set to : "+ resp.AudioMute);
				console.debug("Volume set to : "+ resp.Volume);
				if(resp.AudioMute=="On") philips.mute = "On";
				else philips.mute = "Off";

				philips.cVolume= resp.Volume;*/
            },
            CommandDetails,
        );

        /////////////////////////////////
        //show and hide volume display
        /*   clearTimeout(philips.volumeDisplayTimeout);
        if (mute == "Off") {
            philips.mute = "Off";
            philips.volumeContainer.style.display = "block";
            philips.muteContainer.style.display = "none";
            philips.volumeDisplayTimeout = setTimeout(() => {
                philips.volumeContainer.style.display = "none";
                philips.muteContainer.style.display = "none";
            }, 3000);
        } else {
            philips.mute = "On";
            philips.volumeContainer.style.display = "none";
            philips.muteContainer.style.display = "block";
        }
*/
        /////////////////////////////////
    },

    getVolume() {
        let CommandDetails = {
            AudioControlParameters: ["Volume", "AudioMute"],
        };

        wixp.request(
            "AudioControl",
            (resp) => {
                philips.cVolume = philips.getVolumeFromPercentaje(resp.Volume);
                philips.mute = resp.AudioMute;
                if (philips.mute == "Off") {
                    philips.muteContainer.style.display = "none";
                }
                console.debug(`Current volume data : ${philips.cVolume}, ${philips.mute}`);
            },
            CommandDetails,
        );
    },
    loadInitialPower() {
        wixp.request("PowerState", (resp) => {
            let newState = null;
            if (resp.CurrentPowerState == "On") {
                newState = "Standby";
                //this reload is done, because when video is playing in full screen, and the TV is powered off,
                //next time the TV is on, the screen is black due to the full screen mode without video
                //if(Ingesuite.getCurrentLayout() && Ingesuite.getCurrentLayout()['__type']!='tv')
                setTimeout(() => {
                    STB.reload();
                }, 1000);
            } else {
                newState = "On";
                Media.replayCurrentVideo();
                /*
                clearInterval(philips.refreshInterval);
                philips.refreshInterval = setInterval(function () {
                    if (philips.refreshIter > 20)
                        clearInterval(philips.refreshInterval);
                    if (philips.refreshIter % 2 == 0)
                        body().style.backgroundColor = "rgba(0,0,0,0.01)";
                    else body().style.backgroundColor = "rgba(0,0,0,0)";
                    philips.refreshIter = philips.refreshIter + 1;
                }, 500);
                */
            }
            philips.setPowerState(newState);
            //Focus.onLimbo = false;
        });
    },

    unregisterAllKeys() {
        let CommandDetails = {
            VirtualKeyForwardMode: "DontForwardAnyVirtualKey",
        };
        wixp.change(
            "UserInputControl",
            () => {
                console.debug("WIXP unregisterAllKeys");
            },
            CommandDetails,
        );
    },

    registerAllKeys() {
        let CommandDetails = {
            VirtualKeyForwardMode: "AllVirtualKeyForward",
        };
        wixp.change(
            "UserInputControl",
            () => {
                console.debug("WIXP registerAllKeys");
            },
            CommandDetails,
        );
    },

    sendBackKey() {
        let CommandDetails = {
            SendRCKeyCode: {
                RCProtocol: "RC6",
                RCCode: 10,
                RCSystem: 0,
            },
        };
        wixp.change(
            "UserInputControl",
            () => {
                writeLog("BACK-sent");
            },
            CommandDetails,
        );
    },

    unregisterAppKeys() {
        let vkBase = [
            { vkkey: "HBBTV_VK_POWER" },
            { vkkey: "HBBTV_VK_MENU" },
            { vkkey: "HBBTV_VK_TV" },
            { vkkey: "HBBTV_VK_FORMAT" },
            { vkkey: "HBBTV_VK_GUIDE" },
            { vkkey: "HBBTV_VK_INFO" },
            { vkkey: "HBBTV_VK_TELETEXT" },
            { vkkey: "HBBTV_VK_YELLOW" },
            { vkkey: "HBBTV_VK_BLUE" },
            { vkkey: "HBBTV_VK_RED" },
            { vkkey: "HBBTV_VK_ADJUST" },
            { vkkey: "HBBTV_VK_CAST" },
            { vkkey: "HBBTV_VK_SETTINGS" },
        ];

        let CommandDetails = {
            VirtualKeyForwardMode: "SelectiveVirtualKeyForward",
            VirtualKeyToBeForwarded: vkBase,
        };
        wixp.change(
            "UserInputControl",
            () => {
                writeLog("WIXP unregister some keys");
            },
            CommandDetails,
        );
    },

    unregisterSomeKeys() {
        let vkBase = [
            //            { "vkkey" : "HBBTV_VK_POWER" }, // not existing
            /*
					//            { "vkkey" : "HBBTV_VK_HOME" }, // not existing
					{ "vkkey" : "HBBTV_VK_PLAY_PAUSE" }, // previously was VK_OSRC
								//            { "vkkey" : "HBBTV_VK_UP" }, // not existing*/
            { vkkey: "HBBTV_VK_POWER" },
            { vkkey: "HBBTV_VK_MYCHOICE" },
            { vkkey: "HBBTV_VK_CLOCK" },
            { vkkey: "HBBTV_VK_SMARTTV" },
            { vkkey: "HBBTV_VK_CHANNELGRID" },
            { vkkey: "HBBTV_VK_ALARM" },
            { vkkey: "HBBTV_VK_SMARTINFO" },
            { vkkey: "HBBTV_VK_SOURCE" },
            { vkkey: "HBBTV_VK_TV" },
            { vkkey: "HBBTV_VK_FORMAT" },
            { vkkey: "HBBTV_VK_GUIDE" },
            { vkkey: "HBBTV_VK_INFO" },
            { vkkey: "HBBTV_VK_TELETEXT" },
            { vkkey: "HBBTV_VK_YELLOW" },
            { vkkey: "HBBTV_VK_BLUE" },
            { vkkey: "HBBTV_VK_RED" },
            { vkkey: "HBBTV_VK_ADJUST" },
            { vkkey: "HBBTV_VK_SETTINGS" },
            { vkkey: "HBBTV_VK_1" },
            { vkkey: "HBBTV_VK_2" },
            { vkkey: "HBBTV_VK_3" },
            { vkkey: "HBBTV_VK_4" },
            { vkkey: "HBBTV_VK_5" },
            { vkkey: "HBBTV_VK_6" },
            { vkkey: "HBBTV_VK_7" },
            { vkkey: "HBBTV_VK_8" },
            { vkkey: "HBBTV_VK_9" },
            { vkkey: "HBBTV_VK_0" },
            { vkkey: "HBBTV_VK_BACK" },
            { vkkey: "HBBTV_VK_CHANNEL_UP" },
            { vkkey: "HBBTV_VK_CHANNEL_DOWN" },
            { vkkey: "HBBTV_VK_MENU" },
            { vkkey: "HBBTV_VK_CAST" },
            { vkkey: "HBBTV_VK_YOUTUBE" },
            { vkkey: "HBBTV_VK_NETFLIX" },
            { vkkey: "HBBTV_VK_SUBTITLE" },
            // options button
            //            { "vkkey" : "HBBTV_VK_LEFT" }, // not existing
            //            { "vkkey" : "HBBTV_VK_OK" }, // not existing
            //            { "vkkey" : "HBBTV_VK_RIGHT" }, // not existing
            /*{ "vkkey" : "HBBTV_VK_ADJUST" },
					//            { "vkkey" : "HBBTV_VK_DOWN" }, // not existing
					{ "vkkey" : "HBBTV_VK_MENU" }, // options button
					//            { "vkkey" : "HBBTV_VK_BACK_SPACE" }, // not existing
					{ "vkkey" : "HBBTV_VK_CHANNEL_UP" },
					{ "vkkey" : "HBBTV_VK_CHANNEL_DOWN" },
					{ "vkkey" : "HBBTV_VK_VOLUME_DOWN" },
					{ "vkkey" : "HBBTV_VK_VOLUME_UP" },
					{ "vkkey" : "HBBTV_VK_MUTE" },
					{ "vkkey" : "HBBTV_VK_RED" },
					{ "vkkey" : "HBBTV_VK_GREEN" },
					{ "vkkey" : "HBBTV_VK_BLUE" },
					{ "vkkey" : "HBBTV_VK_YELLOW" },
					{ "vkkey" : "HBBTV_VK_SUBTITLE" },
					//            { "vkkey" : "HBBTV_VK_TEXT" }, // not existing*/
            /*{ "vkkey" : "HBBTV_VK_1" },
					{ "vkkey" : "HBBTV_VK_2" },
					{ "vkkey" : "HBBTV_VK_3" },
					{ "vkkey" : "HBBTV_VK_4" },
					{ "vkkey" : "HBBTV_VK_5" },
					{ "vkkey" : "HBBTV_VK_6" },
					{ "vkkey" : "HBBTV_VK_7" },
					{ "vkkey" : "HBBTV_VK_8" },
					{ "vkkey" : "HBBTV_VK_9" },
					{ "vkkey" : "HBBTV_VK_0" }*/
        ];

        let CommandDetails = {
            VirtualKeyForwardMode: "SelectiveVirtualKeyForward",
            VirtualKeyToBeForwarded: vkBase,
        };
        wixp.change(
            "UserInputControl",
            () => {
                writeLog("WIXP unregister some keys");
            },
            CommandDetails,
        );
    },

    /**
     * Gets a professional setting, and returns the response as callback
     * parameter.
     */
    getProfessionalSetting(setting, callback) {
        let rd = {
            ProfessionalSettingsParameters: [setting],
        };

        wixp.request(
            "ProfessionalSettingsControl",
            (resp) => {
                callback(resp[setting]);
            },
            rd,
        );
    },

    setRoomId(roomNumber) {
        let CommandDetails = {
            IdentificationSettings: {
                RoomID: roomNumber,
            },
        };

        wixp.change(
            "ProfessionalSettingsControl",
            () => {
                console.debug(`RoomID set to ${roomNumber}`);
            },
            CommandDetails,
        );
    },

    getTvStatus() {
        wixp.request("TvStatus", (resp) => {
            /*
				console.debug("TV status current source: ",resp.CurrentSource);
				console.debug("TV power status: ",resp.PowerStatus);
				console.debug("TV status application status: ",resp.ApplicationStatus);
				*/

            //play background music when TV comes from standby mode
            if (resp.PowerStatus == "StandbyToOn" && Media.playingMusic == "") {
                if (Media.playingBgMusic && Media.bgMusicIp != "" && Media.bgMusicPort != "") {
                    Media.playingMusic = "loading";
                    //This timeout is because the status response is received several times
                    setTimeout(() => {
                        Media.playingMusic = "";
                    }, 5000);
                    Media.Audio._playBackgroundMusic(Media.bgMusicIp, Media.bgMusicPort);
                }
            }
        });
    },
    startChannelStatusInterval() {
        if (window.location.pathname === PATHS.TV) {
            clearInterval(philips.channelStatusInterval);
            philips.channelStatusInterval = setInterval(() => {
                philips.getChannelStatus();
            }, 5000);
        }
    },

    getChannelStatus() {
        wixp.request("ChannelSelection", (resp) => {
            writeLog("CHANNEL_STATUS" + resp.ChannelPlayingStatus);
            if (resp.ChannelPlayingStatus == "Playing") {
                clearTimeout(philips.channelStatusTimeout);
                let e = new CustomEvent(EVENTS.type.MEDIA, {
                    detail: Media.MEDIA_PLAY_SUCCESS,
                });
                document.dispatchEvent(e);
            } else {
                let e = new CustomEvent(EVENTS.type.MEDIA, {
                    detail: Media.MEDIA_PLAY_ERROR,
                });
                document.dispatchEvent(e);
            }
        });
    },

    ensureMainTuner() {
        wixp.request("Source", (resp) => {
            if (resp && resp.TunedSource != "MainTuner") {
                philips.changeToMainTuner();
            }
            console.debug("Response: ", JSON.stringify(resp));
        });
    },

    changeToMainTuner() {
        let CommandDetails = {
            TuneToSource: "MainTuner",
        };

        wixp.change(
            "Source",
            (resp) => {
                console.debug("Source changed to: ", JSON.stringify(resp));
            },
            CommandDetails,
        );
    },

    changeToNone() {
        console.debug("CHANGE TO NONE");
        let CommandDetails = {
            TuneToSource: "SideAV",
        };

        wixp.change(
            "Source",
            (resp) => {
                console.debug("Source changed to: ", JSON.stringify(resp));
            },
            CommandDetails,
        );
    },

    getCurrentInput() {
        wixp.request("Source", (resp) => {
            console.debug("Response: ", JSON.stringify(resp));
        });
    },

    changeToInput(source) {
        writeLog(`RESPONSE Set source : ${source}`);
        philips.lastSource = source;
        if (source == "None") {
            //se fija este source porque sino se sigue escuchando el input anterior
            source = "MainTuner";
            philips.outOfInterface = null;
            setTimeout(() => {
                philips.enableCustomDashboard();
            }, 1000);
        } else {
            philips.outOfInterface = true;
            sessionStorage.setItem("outOfInterface", true);
        }

        let CommandDetails = {
            TuneToSource: source,
        };

        wixp.change(
            "Source",
            (resp) => {
                if (typeof resp != "object") {
                    console.error(`RESPONSE error${resp}`);
                }
                // ! commented 22-02-2024 / right now when changing from HDMI to CD tv is turn off and turn on, commented this avoid to jump again to HDMI
                // if (philips.lastSource && resp.TunedSource != philips.lastSource ) {
                //     philips.changeToInput(philips.lastSource);
                // }
            },
            CommandDetails,
        );
    },

    changeToUSB() {
        let CommandDetails = {
            ApplicationDetails: {
                ApplicationName: "Media",
            },
            ApplicationState: "Activate",
        };

        wixp.change(
            "ApplicationControl",
            () => {
                console.debug("Activar Media");
                sessionStorage.setItem("outOfInterface", true);
            },
            CommandDetails,
        );
    },

    /**
     * Gets the MAC Address of the TV and saves it to STB.mac
     */
    getSerialNumber(cb) {
        philips.getProfessionalSetting("SerialNumber", (resp) => {
            if (typeof cb == "function") {
                cb(resp);
            }
        });
    },

    /**
     * Gets the MAC Address of the TV and saves it to STB.mac
     */
    getMACAddress(cb) {
        philips.getProfessionalSetting("NetworkStatus", (resp) => {
            if (typeof resp != "object") {
                console.error("Did not receive response from wixp!!!");
            } else if (typeof cb == "function") {
                STB.ip = resp["IPAddress"];
                cb(resp["EthernetMACAddress"]);
            }
        });
    },

    /**
     * Gets the MAC Address of the TV and saves it to STB.mac
     */
    getNetworkStatus(cb) {
        philips.getProfessionalSetting("NetworkStatus", (resp) => {
            serverRequest("PHILIPS_PhysicalNetworkStatus_");
            if (typeof resp != "object") {
                console.error("Did not receive response from wixp!!!");
            } else if (typeof cb == "function") {
                serverRequest("PHILIPS_PhysicalNetworkStatus_" + resp["PhysicalNetworkStatus"]);
                cb(resp["PhysicalNetworkStatus"]);
            }
        });
    },

    ///////////////////////////////////////////////////////////////////////
    ///		CHANNEL UTILS  												///
    ///////////////////////////////////////////////////////////////////////
    ///																	///
    /// These functions help in changing the channels.					///
    ///////////////////////////////////////////////////////////////////////

    getCurrentTrackInfo() {
        philips.getAudioTracks();
        philips.getSubtitlesState();
        philips.getSubtitles();
    },

    changeChannelIp(ip, port, videopid) {
        if (port == undefined) {
            port = 1234;
        }
        if (videopid == undefined) {
            videopid = 0;
        }

        if (typeof port == "string") {
            port = Number(port, 10);
        }

        let url = `multicast://${ip}:${port}/${videopid}/0/0`;
        writeLog(`Will play: ${url}`);

        let rd = {
            ChannelTuningDetails: {
                URL: url,
            },
        };
        philips.catInfo = {};
        wixp.change("ChannelSelection", () => {}, rd);

        clearTimeout(philips.channelStatusTimeout);
        philips.channelStatusTimeout = setTimeout(() => {
            philips.ensureMainTuner();
            philips.bindVideoPane();
            philips.getChannelStatus();
        }, 5000);
    },
    stopChannel() {
        let rd = {
            ChannelTuningDetails: {
                URL: "multicast://239.200.0.3:1234/0/0/0",
            },
            TrickMode: "Stop",
        };
        wixp.change("ChannelSelection", (resp) => {}, rd);
    },
    changeChannelUrl(url) {
        console.debug(`Will play: ${url}`);

        let rd = {
            URL: url,
        };
        philips.catInfo = {};
        wixp.change(
            "ChannelSelection",
            (resp) => {
                console.debug(`ChannelPlayingStatus Status: ${resp.ChannelPlayingStatus}`);
                if (typeof resp.ChannelSelectionStatus != "undefined") {
                    console.debug(`ChannelSelection Status: ${resp.ChannelSelectionStatus}`);
                }
                if (typeof resp.ChannelPlayingStatus != "undefined") {
                    console.debug(`ChannelPlaying Status: ${resp.ChannelPlayingStatus}`);
                    if (resp.ChannelPlayingStatus == "Playing") {
                        if (!Media.playingBgMusic) {
                            //This timeout is to preload channel information just after play it
                            setTimeout(() => {
                                philips.getAudioTracks();
                                philips.getSubtitlesState();
                                philips.getSubtitles();
                            }, 3000);
                        }
                    }
                } else {
                    console.debug("ChannelSelection Response: ", resp);
                }
            },
            rd,
        );
    },

    initAudioPane() {
        AUDIOPANE = document.createElement("audio");
        AUDIOPANE.id = "AUDIOPANE";
        AUDIOPANE.type = "audio/mpeg";
        AUDIOPANE.style.position = "absolute";
        //VIDEOPANE.style.zIndex 		= 16;
        AUDIOPANE.style.visibility = "hidden";

        document.body.insertBefore(AUDIOPANE, document.body.firstChild);
    },

    playAudioUrl(url) {
        console.debug(`Playing audio: ${url}`);
        // if audio pane doesn't exist, create it
        if (document.getElementById("AUDIOPANE") == null) {
            console.debug("inicio Audio Pnae");
            philips.initAudioPane();
        }
        AUDIOPANE.src = url;
        AUDIOPANE.play();

        AUDIOPANE.addEventListener("ended", () => {
            console.debug("Audio ended");
            let e = new Events.Custom("media", Media.MEDIA_STATUS_END_OF_STREAM, "");
            Events.dispatchEvent(e);
        });

        AUDIOPANE.addEventListener("stalled", () => {
            console.debug("Play stalled");
            let e = new Events.Custom("media", Media.MEDIA_CONNECTION_FAILED, "");
            Events.dispatchEvent(e);
        });
    },

    stopAudio() {
        console.debug("Will stop music");
        // if audio pane doesn't exist, create it
        if (document.getElementById("AUDIOPANE") != null) {
            AUDIOPANE.pause(); //
            //OK, this is ugly, but it's needed. This hides the UI and
            //shows it again.
            //sixp.setOSD(9,0);
            //sixp.setOSD(9,1);
            document.body.removeChild(AUDIOPANE);
            delete window.AUDIOPANE;
            window.AUDIOPANE = null;
        }

        if (philips.playingAlarm == true) {
            console.debug("Parando alarm");
            //			sixp.setOSD(9,0);
            //			sixp.setOSD(9,1);
            philips.playingAlarm = false;
        }
    },

    changeChannelRf(freq, serviceId) {
        philips.catInfo = {};
        let rd = {
            SelectChannelBy: "ChannelTuningDetails",
            ChannelTuningDetails: {
                TuningType: "DVBT", //DVBT DVBC or Analog
                Freq: freq * 1000, //In Hz
                System: "WestEurope", //This is for analog, ignored in DVB
                ServiceID: serviceId,
                ONID: 65535, //Autodetect
                NID: 65535, //Autodetect
                TSID: 65535, //Autodetect
                Modulation: "Auto", //Autodetect
                SymbolRate: 0, //Autodetect
            },
        };
        console.debug("Tuning DVBT channel: ", rd);
        wixp.change(
            "ChannelSelection",
            (resp) => {
                console.debug(`ChannelPlayingStatus Status: ${resp.ChannelPlayingStatus}`);
                if (typeof resp.ChannelSelectionStatus != "undefined" && resp.ChannelSelectionStatus != "") {
                    console.debug(`ChannelSelection Status: ${resp.ChannelSelectionStatus}`);
                }
                if (typeof resp.ChannelPlayingStatus != "undefined" && resp.ChannelPlayingStatus != "") {
                    console.debug(`ChannelPlaying Status: ${resp.ChannelPlayingStatus}`);
                    if (resp.ChannelPlayingStatus == "Playing") {
                    }
                } else {
                    console.debug("ChannelSelection Response: ", resp);
                }
            },
            rd,
        );

        philips.ensureMainTuner();
    },

    ///////////////////////////////////////////////////////////////////////
    ///		AUDIO AND SUBTITLE TRACK UTILS 								///
    ///////////////////////////////////////////////////////////////////////
    ///																	///
    /// These functions help managing audio and subtitle tracks.		///
    ///////////////////////////////////////////////////////////////////////

    getAudioTracks(cb) {
        try {
            wixp.request("AudioLanguage", cb, {});
        } catch (e) {
            console.error(`Error requesting audio tracks: ${e.message}`, e);
        }
    },

    setAudioTrack(langIndex) {
        console.debug(`set audio to ${langIndex}`);
        let cd = {
            AudioLanguageIndex: langIndex,
        };
        try {
            wixp.change("AudioLanguage", () => {}, cd);
        } catch (e) {
            console.error(`Error changing audio track: ${e.message}`, e);
        }
    },

    getSubtitlesState() {
        console.debug("Getting subtitle state...");
        try {
            wixp.request("Subtitles", (resp) => {
                console.debug("Response: ", resp.SubtitleState);
                philips.subtitleState = resp.SubtitleState;
            });
        } catch (e) {
            console.error(`Error getting subtitles state: ${e.message}`, e);
        }
    },
    getSubtitles(cb) {
        try {
            wixp.request("Subtitles", cb, {});
        } catch (e) {
            console.error(`Error requesting subtitles: ${e.message}`, e);
        }
    },

    previousSubtitle: null,
    previousSubtitleInd: null,

    setSubtitles(state, langIndex) {
        // state= 'On', 'Off'
        let cd = {
            SubtitleState: state,
            SubtitleLanguageIndex: langIndex,
        };
        try {
            console.debug(`Set subtitle ${langIndex}`);
            wixp.change(
                "Subtitles",
                (resp) => {
                    console.debug("setSubtitles response: ", resp);
                },
                cd,
            );
        } catch (e) {
            console.error(`Error changing subtitle: ${e.message}`, e);
        }
    },
    setTeletext(state) {
        let req = {
            Svc: "WIXP",
            SvcVer: "0.1",
            CmdType: "Change",
            Fun: "Teletext",
            ChangeDetails: {
                TeletxtState: state,
            },
        };
        try {
            wixp.send(req, () => {
                console.debug(`Teletext state set to ${state}`);
            });
        } catch (e) {
            console.error(`Error setting teletext state: ${e.message}`, e);
        }
    },
    checkIn() {
        let rd = {
            PMSParameters: {
                Action: "CheckIn",
                PMSFeaturesSupported: [
                    "RoomStatus",
                    "RoomStatus:ExpressCheckOut",
                    "RoomStatus:CheckIn",
                    "RoomStatus:CheckOut",
                    "GuestDetails",
                    "GuestPreferences",
                    "GuestPreferences:Language",
                    "GuestPreferences:DoNotDisturb",
                    "GuestMessages",
                    "Bill",
                ],
                GuestDetails: {
                    DisplayName: "Mr./Mrs.",
                    FirstName: "Guest",
                    SurName: "",
                },
                GuestPreferences: {
                    Language: "eng",
                    DonotDisturb: false,
                },
                RoomStatus: {
                    Status: "Occupied",
                    SharingStatus: "Single",
                },
            },
        };
        wixp.change(
            "PMS",
            (resp) => {
                serverRequest("Check in success");
            },
            rd,
        );
    },
    checkOut() {
        let rd = {
            PMSParameters: {
                Action: "CheckOut",
                PMSFeaturesSupported: ["RoomStatus", "RoomStatus:CheckIn"],
                RoomStatus: {
                    Status: "Vacant",
                },
            },
        };
        wixp.change(
            "PMS",
            (resp) => {
                serverRequest("Check out success");
            },
            rd,
        );
    },
    enableExternalClock() {
        try {
            console.debug("Enable external clock");
            //			sixp.enableExternalClock();
        } catch (e) {
            console.error(`Error enabling external clock: ${e.message}`, e);
        }
    },

    ///////////////////////////////////////////////////////////////////////
    ///		VIDEOPANE UTILS  											///
    ///////////////////////////////////////////////////////////////////////
    ///																	///
    /// These functions help managing the video window.					///
    ///////////////////////////////////////////////////////////////////////

    videoPaneId: "VIDEOPANE",

    createVideoPane(type, left, top, width, height, zorder) {
        console.debug("philips.createVideoPane('" + type + "'" + width + "'" + height + "'); Called!");
        try {
            if (VIDEOPANE) {
                throw { message: "Can't create video pane. It's already created!" };
            }

            if (type == "video/broadcast" || type == "application/vnd.apple.mpegurl") {
                //para video broadcast se crea el objeto de este modo porque sino no se
                //puede cambiar la lista de audio

                if (type == "video/broadcast") {
                    VIDEOPANE = document.createElement("object");
                } else {
                    VIDEOPANE = document.createElement("video");
                }
                VIDEOPANE.id = "videoPane";
                VIDEOPANE.type = type;
                VIDEOPANE.style.position = "absolute";
            } else if (type == "video/mpeg4") {
                VIDEOPANE = document.createElement("video");
                /// ... some setup like poster image, size, position etc. goes here...
                /// now, add sources:
                var sourceMP4 = document.createElement("source");
                sourceMP4.id = "videoSource";
                sourceMP4.type = "video/mp4";
                //sourceMP4.src = "http://app.srv.entertainment-solutions.eu/mp4video/local-stream-video_mp4_corto";
                //sourceMP4.src = "http://app.srv.entertainment-solutions.eu/mp4video/movie-id-648-lang-en";
                VIDEOPANE.appendChild(sourceMP4);
            }

            if (
                philips.previousMediaType != null &&
                type == "video/broadcast" &&
                (philips.previousMediaType == "application/vnd.apple.mpegurl" ||
                    philips.previousMediaType == "video/mpeg4")
            ) {
            } else {
                philips.bindVideoPane();
            }
            philips.previousMediaType = type;

            philips.setVideoPaneSize(left, top, width, height, zorder);

            document.body.insertBefore(VIDEOPANE, document.body.firstChild);
        } catch (e) {
            console.error("Error creating video pane: " + e.message, e);
            throw e;
        }
    },

    bindVideoPane() {
        console.debug("philips.bindVideoPane(); Called!");
        try {
            if (this.VIDEOPANE && this.VIDEOPANE.type == "video/broadcast") {
                this.VIDEOPANE.bindToCurrentChannel(); //TODO Does this function exist already?
            }
        } catch (e) {
            console.error(`Error creating video pane: ${e.message}`, e);
            throw e;
        }
    },

    //		getDocumentVideoPane : function(){
    //		return document.getElementById(philips.videoPaneId);
    //		},

    removeVideoPane() {
        console.debug("==== removeVideoPane called");

        if (!this.VIDEOPANE || typeof this.VIDEOPANE == "undefined") {
            return;
        }
        try {
            this.VIDEOPANE.style.visibility = "hidden";
            if (this.VIDEOPANE.type == "video/broadcast") {
                philips.stopChannel();
            } else if (this.VIDEOPANE != null) {
                this.VIDEOPANE.pause(); //
            }
            document.body.removeChild(this.VIDEOPANE);
            delete philips.VIDEOPANE;
            philips.VIDEOPANE = null;
        } catch (e) {
            window.VIDEOPANE = null;
            //throw e;
        }
    },

    showVideoPane() {
        if (!this.VIDEOPANE || typeof this.VIDEOPANE == "undefined") {
            return;
        }
        this.VIDEOPANE.style.visibility = "";
    },

    hideVideoPane() {
        if (!this.VIDEOPANE || typeof this.VIDEOPANE == "undefined") {
            return;
        }
        this.VIDEOPANE.style.visibility = "hidden";
    },

    setVideoPaneSize(left, top, width, height, zorder) {
        console.log(`philips.setVideoPaneSize(${left}, ${top} ${width}x${height}, ${zorder}); Called!`);

        if (!this.VIDEOPANE || typeof this.VIDEOPANE == "undefined") {
            return;
        }
        //this.VIDEOPANE.style.visibility = "visible";

        try {
            this.VIDEOPANE.style.marginLeft = `${left}px`;
            this.VIDEOPANE.style.marginTop = `${top > 0 ? top + 2 : top}px`; // se suman 3 pixeles para ajustar al area del video
            this.VIDEOPANE.style.backgroundColor = "black";
            this.VIDEOPANE.style.position = "absolute";

            if (typeof height != "undefined") {
                this.VIDEOPANE.width = width;
                this.VIDEOPANE.height = height;
                this.VIDEOPANE.style.width = `${width}px`;
                this.VIDEOPANE.style.height = `${height}px`;
            }
            if (typeof zorder != "undefined") {
                this.VIDEOPANE.style.zIndex = zorder;
            }

            writeLog(
                "left-" +
                    this.VIDEOPANE.style.marginLeft +
                    "top-" +
                    this.VIDEOPANE.style.marginTop +
                    "zorder-" +
                    this.VIDEOPANE.style.zIndex +
                    "width-" +
                    this.VIDEOPANE.style.width +
                    "height-" +
                    this.VIDEOPANE.style.height,
            );
        } catch (e) {
            console.error(`Error setting video pane size: ${e.message}`, e);
            throw e;
        }
    },
};

// function createCookie(name, value, days) {
//     let expires = "";
//     if (days) {
//         let date = new Date();
//         date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
//         expires = `; expires=${date.toGMTString()}`;
//     }
//     document.cookie = `${name}=${value}${expires}; path=/`;
// }

// function readCookie(name) {
//     let nameEQ = `${name}=`;
//     let ca = document.cookie.split(";");
//     for (let i = 0; i < ca.length; i++) {
//         let c = ca[i];
//         while (c.charAt(0) == " ") c = c.substring(1, c.length);
//         if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
//     }
//     return null;
// }
