import Modal from "../common/modal";
import { useSelector } from "react-redux";
import { useRoute } from "wouter";
import { useEffect, useState } from "preact/hooks";
import { PATHS } from "../../utils/constants";
import api from "../sales/api";
import Loading from "../loading";
import { figmapx2vh, figmapx2vw } from "../../utils/utils";
import { TableAllergens } from "sales-layout-library/tv";
import ReactHtmlParser from "react-html-parser";
import { Autoscrollable } from "../reusable/autoscrollable";

const Allergens = () => {
    const texts = useSelector((state) => state.ui.texts);
    const [match, params] = useRoute(PATHS.PRODUCT);
    const [dataVariant, setDataVariant] = useState(null);

    useEffect(() => {
        api.variant(params?.variantId, params.roomshop).then((variant) => {
            setDataVariant({ productName: variant.name, allergens: variant.allergens });
        });
    }, []);

    const printAllergens = () => {
        return dataVariant?.allergens.map((allergen) => (
            <div
                key={allergen.code}
                className={"mx-2 rounded "}
                style={{
                    height: `${figmapx2vh(55)}vh`,
                    backgroundColor: "white",
                }}
            >
                <div
                    className={`${TableAllergens[allergen.code].icon} my-1`}
                    style={{
                        textAlign: "center",
                        fontSize: `${figmapx2vw(40)}vw`,
                        float: "left",
                        color: TableAllergens[allergen.code].color,
                    }}
                ></div>
                <div
                    style={{
                        fontSize: `${figmapx2vw(24)}vw`,
                        textAlign: "center",
                        float: "left",
                        paddingTop: "1vh",
                        paddingLeft: "0.5vw",
                    }}
                >
                    {allergen.text}
                </div>
            </div>
        ));
    };

    return (
        <Modal
            title={dataVariant?.productName}
            width={"42vw"}
            titleClass={"pt-6"}
            subtitle={ReactHtmlParser(texts["allergens-warnign"])}
            firstFocus={"btn-exit"}
            backBtn={true}
            body={
                !dataVariant ? (
                    <Loading />
                ) : (
                    <Autoscrollable>
                        <div
                            id="allegeren-scrollable"
                            tabIndex={0}
                            className="navigable scrollable"
                            focusclass="focusOutline"
                            style={{
                                borderSpacing: "1rem",
                                maxHeight: "58vh",
                                marginLeft: "11.5vw",
                                display: "block",
                                overflow: "scroll",
                                width: "max-content",
                                minWidth: "16vw",
                            }}
                        >
                            {printAllergens()}
                        </div>
                    </Autoscrollable>
                )
            }
        />
    );
};

export default Allergens;
