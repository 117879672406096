/* eslint-disable no-undef */
import { EVENTS } from "../../../../utils/eventsConst";
import { getLangName, vh2px, vw2px, writeLog } from "../../../../utils/utils";

export const Media = {
    currentVideoSize: null,
    intendsToStop: 0,
    _stop() {
        let state = webapis.avplay.getState();
        if (state == "PLAYING" || state == "PAUSED") {
            writeLog("paro video");
            webapis.avplay.stop();
            webapis.avplay.close();
        }
        this.ensureStop();
    },

    ensureStop() {
        setTimeout(() => {
            let state = webapis.avplay.getState();
            writeLog(`werr:${state}`);
            if (state !== "PLAYING") {
                this.playingMedia = false;
                writeLog("playing a false");
            } else {
                this.intendsToStop++;
                if (this.intendsToStop < 10) {
                    this.ensureStop();
                } else {
                    this.intendsToStop = 0;
                }
            }
        }, 100);
    },

    showVideoOnBack() {
        return;
        //Make hole in the app to show TV video
        // try {
        //     tizen.tvwindow.show(
        //         (windowRect, type) => {},
        //         null,
        //         [
        //             this.currentVideoSize.x,
        //             this.currentVideoSize.y,
        //             this.currentVideoSize.width,
        //             this.currentVideoSize.height,
        //         ],
        //         "MAIN",
        //         "BEHIND"
        //     );
        // } catch (error) {
        //     console.log(`error: ${error.name}`);
        // }
    },

    showVideoOnFront() {
        return;
        //Make hole in the app to show TV video
        // let xSize,
        //     ySize,
        //     wSize,
        //     hSize = 0;
        // if (this.currentVideoSize) {
        //     xSize = this.currentVideoSize.x;
        //     ySize = this.currentVideoSize.y;
        //     wSize = this.currentVideoSize.width;
        //     hSize = this.currentVideoSize.height;
        // }

        // try {
        //     tizen.tvwindow.show(
        //         (windowRect, type) => {},
        //         null,
        //         [xSize || 0, ySize || 0, wSize || 0, hSize || 0],
        //         "MAIN",
        //         "FRONT"
        //     );
        // } catch (error) {
        //     console.log(`error: ${error.name}`);
        // }
    },
    kill() {
        try {
            let state = webapis.avplay.getState();
            if (state == "PLAYING" || state == "PAUSED") {
                webapis.avplay.stop();
                webapis.avplay.close();
            }
            if (state == "IDLE") {
                webapis.avplay.close();
            }
            tizen.tvwindow.hide(
                () => {},
                () => {},
                "MAIN",
            );
        } catch (error) {
            console.log(`error: ${error.name}`);
        }
    },
    setVideoSize(x, y, width, height) {
        this.currentVideoSize = {
            x,
            y,
            width,
            height,
        };

        x = vw2px(x);
        y = vh2px(y);
        width = vw2px(width);
        height = vh2px(height);

        //set video size
        samsung.video.resize(x, y, width, height);

        //Make hole in the app to show TV video
        this.showVideoOnFront();

        let connectedVideoSources;

        function systemInfoSuccessCB(videoSource) {
            connectedVideoSources = videoSource.connected;
            for (let i = 0; i < connectedVideoSources.length; i++) {
                if (connectedVideoSources[i].type === "TV") {
                    // set TV as input source of TV hole window
                    tizen.tvwindow.setSource(
                        connectedVideoSources[i],
                        function () {
                            console.log("[setSource] TV - success");
                        },
                        function (e) {
                            console.log("[setSource] TV - error " + e.message);
                        },
                    );
                    break;
                }
            }
        }

        //Get available TV sources and set TV  as input source of TV hole window
        try {
            tizen.systeminfo.getPropertyValue("VIDEOSOURCE", systemInfoSuccessCB, () => {});
        } catch (error) {}
    },

    ensureVideoSize() {
        if (this.currentVideoSize) {
            this.setVideoSize(
                this.currentVideoSize.x,
                this.currentVideoSize.y,
                this.currentVideoSize.width,
                this.currentVideoSize.height,
            );
        }
    },

    _playChannel(ip, port) {
        try {
            Media.kill();

            webapis.avplay.open(`udp://${ip}:${port}`);
            webapis.avplay.prepare();
            webapis.avplay.play();
        } catch (e) {
            writeLog(`error: ${e.message}`, e);
        }
    },

    _playHLSVideo(url) {
        try {
            Media.kill();

            webapis.avplay.open(url);
            webapis.avplay.prepare();
            webapis.avplay.play();

            webapis.avplay.setListener({
                onstreamcompleted: function () {
                    var e = new CustomEvent("media_event", {
                        detail: EVENTS.MEDIA.STATUS_END_OF_STREAM,
                    });
                    document.dispatchEvent(e);
                },
            });
        } catch (e) {
            writeLog(`error: ${e.message}`);
        }
    },

    _playDVBTChannel: function (channel) {
        Media.kill();
        console.debug("Would play DVBT: " + JSON.stringify(channel));
        samsung.video.playDVBT(channel.frequency, channel.isc);
    },
    _playDVBCChannel: function (channel) {
        Media.kill();
        console.debug("Would play DVBC: " + JSON.stringify(channel));
        samsung.video.playDVBC(channel.frequency, channel.channeltype, channel.isc, channel.symbol_rate, channel.qam);
    },
    _playDVBSChannel: function (channel) {
        Media.kill();
        console.debug("Would play DVBS: " + JSON.stringify(channel));
        samsung.video.playDVBS(channel.frequency, channel.isc, channel.satelliteId);
    },
    _playATSCChannel: function (channel) {
        Media.kill();
        console.debug("Would play ATSC: " + JSON.stringify(channel));
        samsung.video.playATSC(
            channel.ptc,
            channel.minor,
            channel.modulationtype,
            channel.channeltype,
            channel.tvmode,
            channel.major,
            channel.programnumber,
        );
    },

    getAudioList(onSuccess) {
        try {
            let tracks = samsung.video.getAudioTracks();
            let audioList = [];
            if (!tracks) {
                onSuccess(audioList);
                return;
            }

            for (let i = 0; i < tracks.length; i++) {
                audioList.push({
                    id: i,
                    name: getLangName(tracks[i]["language"]),
                });
            }
            onSuccess(audioList);
        } catch (error) {
            onSuccess([]);
        }
    },

    getSubtitleList(onSuccess) {
        let tracks = samsung.video.getSubtitleTracks();

        try {
            let subtitleList = [];
            if (!tracks) {
                onSuccess(subtitleList);
                return;
            }

            for (let i = 0; i < tracks.length; i++) {
                subtitleList.push({
                    id: i,
                    name: getLangName(
                        typeof tracks[i]["track_lang"] != "undefined" ? tracks[i]["track_lang"] : tracks[i]["language"],
                    ),
                });
            }
            onSuccess(subtitleList);
        } catch (error) {
            onSuccess([]);
        }
    },

    changeAudio(ind) {
        samsung.video.changeAudio(ind);
    },

    changeSubtitle(ind) {
        samsung.video.changeSubtitle(ind);
    },

    disableSubtitles: function () {
        samsung.video.hideSubs();
    },
};
