import { useEffect, useState } from "preact/hooks";
import { useSelector } from "react-redux";
import { STB } from "../hooks/apis/stb";
import { vh2px, vw2px } from "../utils/utils";
import { PATHS } from "../utils/constants";

const Background = () => {
    const screenWidth = document.body.offsetWidth;
    const screenHeight = document.body.offsetHeight;

    //Store data
    const videoPos = useSelector((state) => state.ui.videoPos);
    const background = useSelector((state) => state.ui.background);

    //states
    const [canvas, setCanvas] = useState();
    const [imgObj, setImgObject] = useState();

    useEffect(() => {
        if (!STB.needCanvas) {
            return;
        }
        document.body.style.background = "transparent";
        composeCanvasBackground();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (STB.needCanvas) {
            canvas?.clearRect(0, 0, screenWidth, screenHeight);
            setTimeout(function () {
                composeCanvasBackground();
            }, 1);
        }
        if (document.location.pathname === PATHS.TV && localStorage.getItem("channel_to_restore")) {
            document.getElementById("background")?.classList.add("hidden");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [background]);

    useEffect(() => {
        if (!STB.needCanvas) {
            return;
        }
        if (videoPos && videoPos.width === 100 && videoPos.height === 100) {
            canvas.clearRect(0, 0, screenWidth, screenHeight);
        } else if (videoPos) {
            restoreCanvas();
            canvas.clearRect(vw2px(videoPos.x), vh2px(videoPos.y), vw2px(videoPos.width), vh2px(videoPos.height));
        } else {
            restoreCanvas();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoPos]);

    const composeCanvasBackground = () => {
        if (background?.image) {
            let imageObj = new Image();

            imageObj.onload = function () {
                const canvasObject = document.querySelector("#canvas");
                let ctx = canvasObject.getContext("2d");
                ctx.globalAlpha = this;
                ctx.drawImage(imageObj, 0, 0, screenWidth, screenHeight);
                setCanvas(ctx);
                setImgObject(imageObj);
            }.bind(background ? background.opacity : 1);
            imageObj.src = background.image;
        } else {
            const canvasObject = document.querySelector("#canvas");
            if (canvasObject) {
                let ctx = canvasObject.getContext("2d");
                ctx.rect(0, 0, screenWidth, screenHeight);
                ctx.fillStyle = background && background.bgColor ? background.bgColor : "white";
                ctx.fill();
                setCanvas(ctx);
            }
        }
    };
    const restoreCanvas = () => {
        if (!canvas) {
            return;
        }

        if (background && background.image && imgObj) {
            canvas.drawImage(imgObj, 0, 0, screenWidth, screenHeight);
        } else {
            canvas.rect(0, 0, screenWidth, screenHeight);
            canvas.fillStyle = background && background.bgColor ? background.bgColor : "white";
            canvas.fill();
        }
    };

    return (
        <div
            id="background"
            style={{
                width: `${window.innerWidth || document.body.offsetWidth}px`,
                height: `${window.innerHeight || document.body.offsetHeight}px`,
                backgroundColor: `${STB.needCanvas ? "transparent" : "white"}`,
                display: `${background ? "block" : "none"}`,
                backgroundAttachment: "fixed",
                position: "fixed",
                zIndex: "auto",
            }}
            className=""
        >
            {STB.needCanvas ? (
                <canvas id="canvas" width={screenWidth} height={screenHeight} className="absolute top-0 w-full " />
            ) : background ? (
                <>
                    {background.bgColor ? (
                        <div
                            className={`absolute top-0 w-full h-full`}
                            style={{
                                backgroundColor: background.bgColor,
                            }}
                        />
                    ) : null}

                    {background.image ? (
                        <img
                            id="backgroundImage"
                            className={`absolute top-0 bg-cover w-full h-full bg-no-repeat bg-center`}
                            style={{
                                opacity: background.opacity || 1,
                            }}
                            src={background.image}
                        />
                    ) : null}
                </>
            ) : null}
        </div>
    );
};

export default Background;
